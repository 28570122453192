import BaseService from '../../service/BaseService';

class FilterService extends BaseService {
  prefix = 'filter';

  async getComparativeDate(currentDate) {
    if (currentDate) {
      return this.search({date: currentDate}, `${this.prefix}/comparativeDate`, {uniqueParams: ['date']});
    }
    return this.get('comparativeDate');
  }

  getDREDate() {
    return this.get(null, 'dre/latestDate');
  }

  loadStores() {
    return this.get('stores');
  }

  loadNewStores() {
    return this.get('stores/recent');
  }

  loadStates() {
    return this.get('states');
  }

  loadRegionais() {
    return this.get('regionalManagers');
  }

  loadFlags() {
    return this.get('flags');
  }
  loadDepartments() {
    return this.get('departments');
  }

  loadFamilies() {
    return this.get('families');
  }

  loadCities() {
    return this.get('cities');
  }
}

export default new FilterService();
