const siteMap = {
  path: '/',
  permission: ['SIGA'],
  label: 'Siga',
  selectable: false,
  children: [
    {
      path: 'admin',
      label: 'Área Admnistrativa',
      selectable: true,
      permission: ['ADMIN'],
      children: [
        {
          path: 'importa-dados',
          label: 'Importador',
          selectable: true,
          permission: ['SIGA'],
          groups: [
            {
              filial: {
                path: 'filial',
                label: 'Filiais',
                drillDown: true,
                permission: ['STORES']
              }
            }
          ]
        }
      ]
    }
  ]
};

export default siteMap;
