import {ThemeProvider} from '@bit/grupo_avenida.components.theme';
import DatefnsUtils from '@date-io/date-fns';
import {createGenerateClassName, MuiThemeProvider, StylesProvider} from '@material-ui/core/styles';
import {MuiPickersUtilsProvider} from '@material-ui/pickers';
import {ConnectedRouter} from 'connected-react-router';
import pt from 'date-fns/locale/pt-BR';
import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';

import {init as initDatadog} from './app/datadog';
import ExportContent from './app/export';
import history from './app/history';
import ImportContent from './app/import';
import LanguageProvider from './app/language/LanguageProvider';
import Notifications from './app/notifications/components/NotificationComponent';
import PrintContent from './app/print';
import store from './app/stores';
import App from './component/App';
import SigaTheme from './theme/index';
import OldTheme from './theme/old';
import './styles/App.css';
import './styles/index.css';
import './styles/Print.css';

const generateClassName = createGenerateClassName({disableGlobal: true});

initDatadog();

const rootEl = document.getElementById('app');
const render = () => {
  ReactDOM.render(
    <StylesProvider generateClassName={generateClassName}>
      <Provider store={store}>
        <LanguageProvider>
          <MuiThemeProvider theme={OldTheme}>
            <ThemeProvider theme={SigaTheme}>
              <MuiPickersUtilsProvider utils={DatefnsUtils} locale={pt}>
                <ConnectedRouter history={history}>
                  <React.Fragment>
                    <App />
                    <ExportContent />
                    <ImportContent />
                    <PrintContent />
                    <Notifications />
                  </React.Fragment>
                </ConnectedRouter>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </LanguageProvider>
      </Provider>
    </StylesProvider>,
    rootEl
  );
};
render();

// Hot reloading
if (module.hot) {
  // Reload components
  module.hot.accept('./component/App', render);
}
