import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import LoadingModalComponent from '../../components/LoadingModalComponent';

class ExcelImportComponent extends React.Component {
  static propTypes = {
    loading: PropTypes.bool
  };

  render() {
    const {loading} = this.props;
    if (!loading) {
      return null;
    }

    if (loading) {
      return <LoadingModalComponent />;
    }

    return <div style={{display: 'none'}} />;
  }
}

export default connect(state => ({...state.import}))(ExcelImportComponent);
