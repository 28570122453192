import topStyle, {sideMenuColor, sideBorderColor} from '../../app/menu/SideMenuStyles';

const styles = theme => ({
  backToSigaButton: {
    width: '100%',
    margin: '15px auto 0',
    padding: 0,
    paddingRight: theme.spacing(2.5),
    color: '#646464'
  },
  wrapperHeader: {
    margin: '10px 10px',
    width: '100%'
  },
  admMenuCompanyLogo: {
    height: '30px',
    [theme.breakpoints.up('lg')]: {
      padding: '16px 0',
      height: '30px'
    }
  },
  versionAdm: {
    display: 'flex',
    fontSize: '10px',
    justifyContent: 'flex-start',
    marginLeft: '20px'
  }
});

export default theme => ({
  ...topStyle(theme),
  ...styles(theme)
});

export {sideMenuColor, sideBorderColor};
