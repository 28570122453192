import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core';
import {Dashboard} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

class EmptyPanel extends React.Component {
  render() {
    const {classes, message1, message2} = this.props;

    return (
      <div className={classes.mainContainer}>
        <div className={classes.contentWrapper}>
          <div className={classes.iconWrapper}>
            <Dashboard className={classes.icon} />
          </div>
          <div>
            <Typography family="fontSecondary" color="neutral.level5" size={14} className={classes.message}>
              {message1}
            </Typography>
            <Typography family="fontSecondary" color="neutral.level5" size={14} className={classes.message}>
              {message2}
            </Typography>
          </div>
        </div>
      </div>
    );
  }
}

EmptyPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  message1: PropTypes.string.isRequired,
  message2: PropTypes.string.isRequired
};

export default withStyles(styles)(EmptyPanel);
