import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {format as formatDate, isSameDay, isWithinInterval, startOfWeek, endOfWeek, isAfter, endOfToday} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../component/DatePicker';
import styles from '../../../styles/FilterStyles';

const WeeklyPicker = props => {
  const {
    dateFilter: {date, format, title},
    onChange,
    sideFilter
  } = props;

  const renderWrappedWeek = (dateValue, selectedDate, visible) => {
    const {classes} = props;
    const disabledDay = isAfter(dateValue, endOfToday()) || !visible;
    const weekStart = startOfWeek(selectedDate, {weekStartsOn: 1});
    const weekEnd = endOfWeek(selectedDate, {weekStartsOn: 1});
    const isBetween = isWithinInterval(dateValue, {start: weekStart, end: weekEnd});
    const isFirstDay = isSameDay(dateValue, weekStart);
    const isLastDay = isSameDay(dateValue, weekEnd);
    const isSelectedDay = isSameDay(dateValue, selectedDate);

    let wrapperStyle = classnames({
      [classes.highlight]: isBetween,
      [classes.firstHighlight]: isFirstDay,
      [classes.endHighlight]: isLastDay
    });

    let dayStyle = classnames({
      [classes.selectedDay]: isSelectedDay,
      [classes.dayIconButton]: !isSelectedDay,
      [classes.disabledDay]: disabledDay
    });

    return (
      <div className={wrapperStyle}>
        <IconButton className={dayStyle}>
          <span> {visible && formatDate(dateValue, 'd')} </span>
        </IconButton>
      </div>
    );
  };

  const handleOnChange = value => {
    onChange('weeklyDate')(startOfWeek(value, {weekStartsOn: 1}));
  };

  return <DatePicker variant="button" format={format} onChange={handleOnChange} renderDay={renderWrappedWeek} sideFilter={sideFilter} title={title} value={date} />;
};

WeeklyPicker.propTypes = {
  classes: PropTypes.object,
  dateFilter: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  sideFilter: PropTypes.bool
};

export default withStyles(styles)(WeeklyPicker);
