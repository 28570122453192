import {withStyles} from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {push} from 'connected-react-router';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {connect} from 'react-redux';

import {CustomSelectedSubMenu, SubMenuLink, SubMenuText} from './styles';
import {isCurrentPath} from './util';

const styles = () => ({
  menuItemHover: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)'
  }
});

const MenuLink = props => {
  const {subChildren} = props;
  const goTo = path => () => props.push(path);

  const [isOpen, setIsOpen] = useState(isCurrentPath(props.path, props.pathname));

  const handleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const menuClasses = [props.className, styles.menuItem];

  const handleMenuClick = subChildren ? handleCollapse : goTo(props.path);

  return (
    <>
      <CustomSelectedSubMenu button className={menuClasses.join(' ')} open={isOpen} onClick={handleMenuClick}>
        {props.children}
        {subChildren && (isOpen ? <ExpandLess /> : <ExpandMore />)}
      </CustomSelectedSubMenu>
      {subChildren &&
        isOpen &&
        map(subChildren, item => (
          <SubMenuLink isSelected={isCurrentPath(item.path, props.pathname)} onClick={goTo(item.path)} key={item.key + item.path}>
            <SubMenuText>{item.title}</SubMenuText>
          </SubMenuLink>
        ))}
    </>
  );
};

MenuLink.propTypes = {
  children: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
  path: PropTypes.any,
  pathname: PropTypes.any,
  push: PropTypes.func,
  subChildren: PropTypes.array
};
const mapStateToProps = state => {
  return {...state.router.location};
};
export default connect(mapStateToProps, {push})(withStyles(styles)(MenuLink));
