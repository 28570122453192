import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const family = props => {
  return (
    <SvgIcon {...props}>
      <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style={{enableBackground: 'new 0 0 24 24'}}>
        <path style={{fill: 'none'}} d="M0,0h24v24H0V0z" />
        <path
          d="M21.4,11.6l-9-9C12.1,2.2,11.6,2,11,2H4C2.9,2,2,2.9,2,4v7c0,0.6,0.2,1.1,0.6,1.4l9,9c0.4,0.4,0.9,0.6,1.4,0.6
          s1.1-0.2,1.4-0.6l7-7C21.8,14,22,13.5,22,13S21.8,11.9,21.4,11.6z M5.5,7C4.7,7,4,6.3,4,5.5S4.7,4,5.5,4S7,4.7,7,5.5S6.3,7,5.5,7z"
        />
      </svg>
    </SvgIcon>
  );
};

export default family;
