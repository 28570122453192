import classname from 'classnames';
import {get} from 'lodash';
import React from 'react';

import styles from './formatterStyles';
import {numberFormat} from './util';

const percentage = (value, customOptions) => {
  const {minimumFractionDigits = 2, maximumFractionDigits = 2, useSymbol = true, nullable = false} = customOptions || {};
  if (nullable && value === null) return '-';
  else if (!value) value = 0;
  return numberFormat(value, {minimumFractionDigits, maximumFractionDigits}) + (useSymbol ? ' %' : ' p.p.');
  // TODO: remover opção useSymbol quando refatorar os gráficos
};

export const conditionalPercentage = (currentValue, column) => {
  let className = currentValue <= column.subvalue ? 'up-cell' : 'down-cell';
  return (
    <div>
      <span className={classname(className, 'comparative-goal')} style={styles.value}>
        {percentage(currentValue)}
      </span>
    </div>
  );
};

const comparativeGoalPercentageFormatter = multiple =>
  function renderComparativeGoalPercentageFormatter(currentValue, column, line) {
    if (!column.subvalue) {
      return percentage(currentValue);
    }

    const value = parseFloat(currentValue);

    const subvalue = get(line, column.subvalue, 0);
    let className = value >= subvalue ? 'up-cell' : 'down-cell';

    if (value === 0 && subvalue === 0) {
      className = '';
    }

    return (
      <div>
        <span className={classname(className, 'comparative-goal')} style={styles.value}>
          {percentage(currentValue)}
        </span>
        {!!multiple && <span style={styles.subvalue}>{percentage(subvalue)}</span>}
      </div>
    );
  };

export const multipleValue = (currentValue, column, line) => {
  if (!column.subvalue) {
    return percentage(currentValue);
  }

  const subvalue = get(line, column.subvalue, 0);
  return (
    <div>
      <span style={styles.value}>{percentage(currentValue)}</span>
      <span style={styles.subvalue}>{percentage(subvalue)}</span>
    </div>
  );
};

export const comparativeGoalMultiplePercentege = comparativeGoalPercentageFormatter(true);

export const comparativeGoalPercentege = comparativeGoalPercentageFormatter(false);

export const comparativePercentage = currentValue => {
  const value = parseFloat(currentValue);
  const className = value <= 0 ? 'down-cell' : 'up-cell';

  return <span className={className}>{percentage(currentValue)}</span>;
};

export const renderComparativeValue = (currentValue, positiveValue, negativeValue) => {
  const value = parseFloat(currentValue);
  let className = '';
  if (value <= parseFloat(negativeValue) || value <= 0) {
    className = 'down-cell';
  } else if (value >= parseFloat(positiveValue)) {
    className = 'up-cell';
  }
  return <span className={className}>{percentage(currentValue)}</span>;
};

export default percentage;
