import {SET_PENDING, SET_AS_FINISHED, SET_AS_SEE_LATER, CLEAR} from '../action/types';
import Service from '../service';

const handleException = exception => {
  if (exception && exception.message === 'cancel') return; // ignore cancel
  console.error('Error saving visit.', exception);
};

export const getData = () => {
  return Service.getPending();
};

export const dispatchPending = content => dispatch => {
  dispatch({type: SET_PENDING, payload: content});
};

export const dispatchFinished = visitId => dispatch => {
  Service.saveFinished(visitId).catch(handleException);
  dispatch({type: SET_AS_FINISHED, payload: visitId});
};

export const dispatchSeeLater = visitId => dispatch => {
  dispatch({type: SET_AS_SEE_LATER, payload: visitId});
};

export const clear = () => dispatch => {
  dispatch({type: CLEAR});
};
