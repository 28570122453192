import HolidayAlertService from '../service/HolidayAlertService';

export const loadPendingHolidays = async () => {
  return HolidayAlertService.loadPendingHolidays();
};

export const loadUndefinedStores = async () => {
  return HolidayAlertService.loadUndefinedStores();
};

export const saveData = async formData => {
  return HolidayAlertService.save(formData);
};

export const checkConflicts = async (date, store, isOpen) => {
  return HolidayAlertService.checkConflicts(date, store, isOpen);
};
