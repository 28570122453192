import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const productivity = props => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path data-name="Path 1014" d="M0,19.37H2.52V16.85H0m4.21,2.52H6.73V13.48H4.21m4.2,5.89h2.52V11.06H8.41m4.34,8.31h2.52V8.68H12.75m3.89,10.69h2.52V5.71H16.64Z" />
        <path data-name="Path 1015" d="M2.46,10.89.04,8.47,5.54,2.7H1.26V0h9.67V9.67H8.41V5.4Z" />
      </svg>
    </SvgIcon>
  );
};

export default productivity;
