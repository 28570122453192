export const APPLY_SETTINGS = 'ADMIN/NAVBAR/APPLY';
export const CLEAR = 'ADMIN/NAVBAR/CLEAR';

export const applySettings = (params = {}) => dispatcher => {
  dispatcher({type: APPLY_SETTINGS, payload: params});
};

export const clearSettings = () => dispatcher => {
  dispatcher({type: CLEAR});
};
