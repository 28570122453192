const styles = theme => ({
  autoUpdate: {
    marginTop: '5px',
    marginBottom: '5px',
    marginRight: theme.spacing(2),
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '14px'
  },
  fullScreenButton: {
    color: '#333333',
    fontSize: '14px',
    fontFamily: 'Roboto',
    display: 'flex',
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '10px',
    '& svg': {
      color: '#707070'
    }
  },
  dateButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginLeft: '10px'
  },
  dateContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  panelDateFilter: {
    display: 'flex',
    left: '8px !important',
    top: '42px !important',
    '& ul': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      padding: '16px',
      '& > div:nth-child(1)': {
        padding: '0px 8px',
        marginRight: '4px'
      },
      '& > div:nth-child(2)': {
        padding: '0px 8px'
      },
      '& > div:nth-child(3)': {
        padding: '0px 8px',
        marginLeft: '4px'
      }
    },
    [theme.breakpoints.down('sm')]: {
      top: '0px !important',
      '& ul': {
        alignItems: 'center',
        flexDirection: 'column',
        padding: '0px',
        '& > div:nth-child(1)': {
          padding: '8px 16px !important',
          margin: '0px !important'
        },
        '& > div:nth-child(2)': {
          padding: '0px 16px !important',
          margin: '0px !important'
        },
        '& > div:nth-child(3)': {
          padding: '8px 16px !important',
          margin: '0px !important'
        }
      }
    }
  },
  extraTopbar: {
    backgroundColor: 'rgb(240,240,240)',
    height: 50,
    borderTop: '1px solid #c7c6c6',
    paddingTop: '1px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  icon: {
    margin: '0px 10px'
  },
  mobileAutoUpdate: {
    fontSize: '12px'
  }
});

export default styles;
