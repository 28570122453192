const styles = theme => ({
  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minWidth: 220,
    height: 613,
    borderRadius: 5,
    backgroundColor: theme.colors.neutral.white,
    padding: theme.spacing(3),
    border: '1px solid #EAEAEA'
  },
  inputWrapper: {
    width: '100%'
  },
  selectWrapper: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  dragAndDropWrapper: {
    width: '100%',
    marginTop: theme.spacing(3),
    flexGrow: 1,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 7,
      height: 15
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderRadius: 10
    }
  },
  messageWrapper: {
    width: '100%',
    marginTop: theme.spacing(3)
  },
  actionButtonsWrapper: {
    width: '100%',
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column'
    }
  },
  deleteButtonWrapper: {
    width: 'min-content',
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(3)
    },
    [theme.breakpoints.down('sm')]: {
      width: 'auto'
    }
  },
  saveCancelButtonWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.only('md')]: {
      width: '100%',
      justifyContent: 'space-between',
      marginLeft: 0
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginLeft: 0
    }
  },
  cancelButton: {
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(1)
    }
  },
  saveButton: {
    marginLeft: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0
    }
  }
});

export default styles;
