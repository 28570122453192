import BaseService from '../../service/BaseService';

class ReleaseNotesBaseService extends BaseService {
  prefix = 'releaseNotes';

  getPending() {
    return this.get('pending');
  }

  getReleaseNotes = async page => {
    const parsedParams = {
      page,
      pageSize: 6,
      orderBy: 'id',
      reverseOrder: true
    };
    return await this.search(parsedParams);
  };

  saveFinished = async id => {
    return await this.post(null, `${this.prefix}/done/${id}`);
  };
}

export default new ReleaseNotesBaseService();
