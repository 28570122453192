import {t} from '@lingui/macro';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  endOfMonth,
  fromUnixTime,
  getDaysInMonth,
  getUnixTime,
  isSameDay,
  isSameHour,
  isSameMonth,
  setHours,
  setMonth,
  subDays,
  subHours,
  subMonths,
  subYears,
  startOfMonth,
  startOfWeek
} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';

import {formatDateFns, getDateFns} from '../../../dateFns';
import styles from '../../styles/FilterStyles';
import {BSCDATE_PERIOD, DREDATE, NPSDATE, REALTIME, REALTIME_COMPARATIVE_HOUR, REALTIME_HOUR} from './dateUtil';

class PresetFilter extends React.Component {
  getDateValue = (value, currentDateTime) => {
    switch (value) {
      case 'daysInMonth':
        return subDays(currentDateTime, getDaysInMonth(currentDateTime) - 1);
      case 'lastHour':
        return subHours(currentDateTime, 1);
      case 'lastMonth':
        return endOfMonth(subMonths(currentDateTime, 1));
      case 'currentMonth':
        return startOfMonth(currentDateTime);
      case 'monthBeforeLast':
        return endOfMonth(subMonths(currentDateTime, 2));
      case 'ninetyDays':
        return subDays(currentDateTime, 90);
      case 'sixtyDays':
        return subDays(currentDateTime, 60);
      case 'weekend':
        return subDays(startOfWeek(currentDateTime), 1);
      case 'yesterday':
        return subDays(setHours(currentDateTime, 23), 1);
      default:
        return currentDateTime;
    }
  };

  isSelected = (preset, date, currentDateTime) => {
    const dateToCompare = this.getDateValue(preset, currentDateTime);
    switch (preset) {
      case 'lastHour':
        return isSameHour(date, dateToCompare);
      case 'lastMonth':
      case 'currentMonth':
      case 'monthBeforeLast':
        return isSameMonth(date, dateToCompare);
      case 'weekend':
      case 'yesterday':
      case 'sixtyDays':
      case 'ninetyDays':
      case 'daysInMonth':
        return isSameDay(date, dateToCompare);
      case 'now':
      default:
        return isSameHour(date, currentDateTime);
    }
  };

  handlePreset = (preset, currentDateTime) => () => {
    const {
      dateFilter,
      dateType: {customComparativeDate}
    } = this.props;
    const newDate = this.getDateValue(preset, currentDateTime);
    let newState = {preset, dateFilter, customComparativeDate};
    if (dateFilter === REALTIME) {
      newState.realtimeCurrentDate = getUnixTime(newDate);
    } else if (dateFilter === REALTIME_HOUR || dateFilter === REALTIME_COMPARATIVE_HOUR) {
      newState.hour = parseInt(formatDateFns(newDate, 'HH'));
      newState.realtimeCurrentDate = getUnixTime(newDate);
    } else if (dateFilter === DREDATE) {
      newState.dreDate = getUnixTime(newDate);
    } else if (dateFilter === NPSDATE) {
      newState.dateBegin = getUnixTime(newDate);
      newState.dateEnd = getUnixTime(currentDateTime);
    } else if (dateFilter === BSCDATE_PERIOD) {
      newState.bscDateBegin = getUnixTime(subYears(setMonth(newDate, 9), 1));
      newState.bscDateEnd = getUnixTime(setMonth(newDate, 8));
    } else {
      newState.currentDate = getUnixTime(newDate);
    }
    this.props.setFilters(newState);
    this.props.handleClose();

    ReactGA.event({
      category: 'Filters',
      action: 'Select preset',
      label: `${preset}`
    });
  };

  getTitle = (preset, day) => {
    switch (preset) {
      case 'daysInMonth': {
        const count = getDaysInMonth(day);
        return t`common.filters.presets.${count}DaysInMonth`;
      }
      case 'lastHour':
        return t`common.filters.presets.lastHour`;
      case 'lastMonth':
        return t`common.filters.presets.lastMonth`;
      case 'monthBeforeLast':
        return t`common.filters.presets.monthBeforeLast`;
      case 'currentMonth':
        return t`common.filters.presets.monthCurrent`;
      case 'ninetyDays':
        return t`common.filters.presets.ninetyDays`;
      case 'now':
        return t`common.filters.presets.now`;
      case 'sixtyDays':
        return t`common.filters.presets.sixtyDays`;
      case 'weekend':
        return t`common.filters.presets.weekend`;
      case 'yesterday':
        return t`common.filters.presets.yesterday`;
      case 'pepkorYear':
        return t`common.filters.presets.pepkorYear`;
      default:
        return null;
    }
  };

  getButtonStyle = (disabled, isMobile, classes) => {
    if (disabled) {
      return classnames([classes.disabledButton, isMobile && classes.mobileDisabledButton]);
    }
    return classnames([classes.secondaryButton, isMobile && classes.mobileSecondaryButton]);
  };

  render() {
    const currentDateTime = getDateFns();
    const {
      classes,
      dateType: {date, hour, preset},
      isMobile,
      presetSelected,
      visible
    } = this.props;
    if (!visible || !preset) return null;
    const dateValue = hour ? setHours(fromUnixTime(date), parseInt(hour)) : fromUnixTime(date);
    return (
      <div className={classnames([classes.cardPanel, classes.presetContent, isMobile && classes.mobilePanelDateFilter])}>
        {preset.map(p => {
          const disabled = presetSelected !== 'custom' && this.isSelected(p, dateValue, currentDateTime);
          return (
            <Button fullWidth className={this.getButtonStyle(disabled, isMobile, classes)} disabled={disabled} key={p} onClick={this.handlePreset(p, currentDateTime)} variant="contained">
              {this.getTitle(p, currentDateTime)?.toUpperCase()}
            </Button>
          );
        })}
      </div>
    );
  }
}

PresetFilter.propTypes = {
  classes: PropTypes.object,
  dateFilter: PropTypes.string,
  dateType: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  presetSelected: PropTypes.string,
  setFilters: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
  presetSelected: state.filter.preset
});

export default connect(mapStateToProps)(withStyles(styles)(PresetFilter));
