import {get, isArray, difference, intersection, size, map, filter} from 'lodash';

import store from '../app/stores';

export const userFriendlyPermissions = {
  STORES: 'filial',
  FLAGS: 'bandeira',
  REGIONAL_MANAGERS: 'REGIONAL_MANAGERS', // has access to regional managers
  REGIONAL_MANAGER: 'REGIONAL_MANAGER', // Is regional manager
  CITIES: 'cidade',
  STATES: 'estado',
  DIRECTOR: 'DIRECTOR',
  ADMIN: 'ADMIN',
  ECOMMERCE: 'ECOMMERCE'
};

export const ADMIN_ROLE = 'ADMIN';

export const hasPermission = (role, shouldMatchAll = true) => {
  let roles = role;
  if (!isArray(role)) {
    roles = [role];
  }

  const state = store.getState();
  const userRoles = get(state, 'user.roles');

  if (shouldMatchAll === true) {
    return size(difference(roles, userRoles)) === 0;
  }

  return size(intersection(roles, userRoles)) > 0;
};

export const isAdmin = () => {
  const state = store.getState();
  const userRoles = get(state, 'user.roles', []);

  return !!~userRoles.indexOf(ADMIN_ROLE);
};

export const hasWriteAccess = storeCode => {
  const state = store.getState();
  return get(state, `user.writableStores[${storeCode}]`, null) !== null;
};

export const getAccess = () => {
  const state = store.getState();
  const userRoles = filter(
    map(get(state, 'user.roles'), entry => {
      const label = get(userFriendlyPermissions, entry);
      if (!label) return null;
      return {
        permission: entry,
        label
      };
    })
  );

  return userRoles;
};
