import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';

import ExtraTopBar from '../navbar/mobilebar/ExtraTopbar';
import NavBar from '../navbar/NavBar';
import styles from './TopBarStyles.js';

class TopBar extends React.Component {
  static propTypes = {
    toggleOpen: PropTypes.func.isRequired
  };

  render() {
    const {classes, toggleOpen} = this.props;

    return (
      <AppBar position="static" className={classes.topbar}>
        <div className={classes.grid}>
          <div onClick={toggleOpen} className={classes.group}>
            <IconButton color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
          </div>
          <NavBar variant="topbar" />
        </div>
        <ExtraTopBar />
      </AppBar>
    );
  }
}

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default withStyles(styles, {withTheme: true})(TopBar);
