const urlSettings = {
  url: 'operacional/vendas-dia-a-dia',
  routes: {
    sales: 'venda',
    projection: 'projecoes',
    goal: 'compromisso',
    dailyGoal: 'compromisso-diario'
  }
};

export default urlSettings;
