import Button from '@material-ui/core/Button';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

const link = props => {
  const {path, push, ...otherProps} = props;

  const goTo = () => push(path);

  return (
    <Button onClick={goTo} {...otherProps}>
      {props.children}
    </Button>
  );
};

link.propTypes = {
  children: PropTypes.node.isRequired,
  path: PropTypes.any,
  push: PropTypes.func
};

const mapStateToProps = state => {
  return {...state.router.location};
};

export default connect(mapStateToProps, {push})(link);
