import io from 'socket.io-client';

class Socket {
  constructor(props) {
    const {onLoadedData, onNewNotification, onClear} = props;
    this.onLoadedData = onLoadedData;
    this.onNewNotification = onNewNotification;
    this.onClear = onClear;
    this.socket = io({query: {socketType: 'notifications'}, transports: ['polling'], upgrade: false});
  }

  listen = () => {
    this.socket.on('connect', () => {
      this.socket.emit('authentication', {token: this.token});
    });
    this.socket.on('authenticated', this.loadNotifications);
    this.socket.on('loadNotifications', this.onLoadedData);
    this.socket.on('newNotification', this.onNewNotification);
    this.socket.on('clearNotifications', this.onClear);
  };

  loadNotifications = () => {
    this.socket.emit('loadNotifications');
  };

  checkNotificationStatus = type => {
    this.socket.emit('checkNotificationStatus', type);
  };

  disconnect = () => {
    this.socket.disconnect();
  };

  isConnected = () => {
    return this.socket && this.socket.connected;
  };

  get token() {
    return localStorage.getItem('auth-token');
  }
}

export default Socket;
