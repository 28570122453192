import api from '../../../../../service/api/BaseApi';
import BaseService from '../../../../../service/BaseService';
import {formatDateFns} from '../../../../dateFns';

class HolidayAlertService extends BaseService {
  prefix = 'holiday/undefined';

  loadUndefinedStores() {
    return this.get(null, `${this.prefix}`);
  }

  loadPendingHolidays() {
    return this.get(null, `${this.prefix}/stores`);
  }

  save(formData) {
    const {storeCode, isOpen, openingTime, closingTime, note, date} = formData;
    const body = {
      storeCode: storeCode,
      isOpen,
      openingHour: isOpen ? formatDateFns(openingTime, 'HH') : null,
      openingMinute: isOpen ? formatDateFns(openingTime, 'mm') : null,
      closingHour: isOpen ? formatDateFns(closingTime, 'HH') : null,
      closingMinute: isOpen ? formatDateFns(closingTime, 'mm') : null,
      note: note ? note : '',
      date
    };
    return this.update(body);
  }

  checkConflicts(date, store, isOpen) {
    return this.search(null, `storeActivity/hasConflict/${date}/${store}/${isOpen}`);
  }

  update(entity, prefix = 'storeActivity') {
    return api.put(prefix, entity);
  }
}

export default new HolidayAlertService();
