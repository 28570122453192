import {CircularProgress} from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';

const baseStyle = {
  display: 'flex',
  justifyContent: 'center',
  marginTop: 50,
  position: 'relative',
  width: '100%'
};

class Loading extends React.Component {
  static propTypes = {
    marginTop: PropTypes.number
  };

  static defaultProps = {
    marginTop: 100
  };

  render() {
    const {marginTop} = this.props;

    const loadingStyle = {
      ...baseStyle,
      marginTop: marginTop //TODO: Era isso ou quebrar o Siga inteiro. :facepalm: https://app.clickup.com/t/4t3ffw
    };

    return (
      <div style={loadingStyle}>
        <CircularProgress size={48} />
      </div>
    );
  }
}

export default Loading;
