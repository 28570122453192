import {fromUnixTime, isAfter, startOfMonth} from 'date-fns';

import {getDateFns} from '../../../dateFns';

export const ACC = 'accumulated';
export const BSCDATE_PERIOD = 'bscDate_period';
export const CURRENTDATE = 'currentDate';
export const CURRENTDATE_COMPARATIVE = 'currentDate_comparative';
export const DREDATE = 'dreDate';
export const DREDATE_PERIOD = 'dreDate_period';
export const MONTHLY = 'monthly';
export const NPSDATE = 'npsDate';
export const PERIOD_ACC = 'period_acc';
export const REALTIME = 'realtime';
export const REALTIME_HOUR = 'realtime_hour';
export const REALTIME_COMPARATIVE_HOUR = 'realtime_comparative_hour';
export const WEEKLY = 'weekly';
export const WITHOUT_DATE_FILTER = 'without_date_filter';

const currentDateTime = getDateFns();

export const getDateType = (dateFilter, filters) => {
  const {
    bscDateBegin,
    bscDateEnd,
    comparativeDate,
    currentDate,
    customComparativeDate,
    dreDate,
    dreDateBegin,
    dreDateEnd,
    hour,
    dateBegin,
    dateEnd,
    dateAccBegin,
    dateAccEnd,
    realtimeComparativeDate,
    realtimeCurrentDate,
    weeklyDate
  } = filters;
  switch (dateFilter) {
    case ACC:
      if (isAfter(startOfMonth(currentDateTime), fromUnixTime(currentDate))) {
        return {
          id: ACC,
          date: currentDate,
          format: 'MMMM - yyyy',
          auxDate: comparativeDate,
          customComparativeDate,
          showComparative: false,
          preset: ['lastMonth', 'yesterday']
        };
      }
      return {
        id: ACC,
        date: currentDate,
        auxDate: comparativeDate,
        customComparativeDate,
        preset: ['lastMonth', 'yesterday']
      };
    case BSCDATE_PERIOD:
      return {
        id: BSCDATE_PERIOD,
        date: bscDateBegin,
        auxDate: bscDateEnd,
        format: 'MMMM yyyy',
        preset: ['pepkorYear']
      };
    case CURRENTDATE:
      return {
        id: CURRENTDATE,
        date: currentDate,
        preset: ['lastMonth', 'yesterday']
      };
    case CURRENTDATE_COMPARATIVE:
      return {
        id: CURRENTDATE_COMPARATIVE,
        date: currentDate,
        auxDate: comparativeDate,
        customComparativeDate,
        preset: ['lastMonth', 'yesterday']
      };
    case DREDATE:
      return {
        id: DREDATE,
        date: dreDate,
        format: 'MMMM yyyy',
        preset: ['monthBeforeLast', 'lastMonth']
      };
    case DREDATE_PERIOD:
      return {
        id: DREDATE_PERIOD,
        date: dreDateBegin,
        auxDate: dreDateEnd,
        format: 'MMMM yyyy',
        preset: ['monthBeforeLast', 'lastMonth']
      };
    case NPSDATE:
      return {
        id: NPSDATE,
        date: dateBegin,
        auxDate: dateEnd,
        preset: ['ninetyDays', 'sixtyDays', 'daysInMonth']
      };
    case PERIOD_ACC:
      return {
        id: PERIOD_ACC,
        date: dateAccBegin,
        auxDate: dateAccEnd
      };
    case REALTIME:
      return {
        id: REALTIME,
        enableAutoUpdate: true,
        date: realtimeCurrentDate,
        preset: ['yesterday', 'now']
      };
    case REALTIME_HOUR:
      return {
        id: REALTIME_HOUR,
        enableAutoUpdate: true,
        date: realtimeCurrentDate,
        hour: hour,
        preset: ['yesterday', 'lastHour', 'now']
      };
    case REALTIME_COMPARATIVE_HOUR:
      return {
        id: REALTIME_COMPARATIVE_HOUR,
        enableAutoUpdate: true,
        date: realtimeCurrentDate,
        auxDate: realtimeComparativeDate,
        customComparativeDate,
        hour: hour,
        preset: ['yesterday', 'lastHour', 'now']
      };
    case WEEKLY:
      return {
        id: WEEKLY,
        date: weeklyDate,
        format: 'MMM d yyyy',
        title: 'Semana de '
      };
    case MONTHLY:
      return {
        id: MONTHLY,
        date: currentDate,
        format: 'MMMM yyyy',
        preset: ['lastMonth', 'currentMonth']
      };
    case WITHOUT_DATE_FILTER:
      return {
        id: WITHOUT_DATE_FILTER
      };
    default:
      return {
        enableAutoUpdate: false
      };
  }
};
