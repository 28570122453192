import {t} from '@lingui/macro';
import CategoryIcon from '@material-ui/icons/Widgets';
import PropTypes from 'prop-types';
import React from 'react';

import AbstractFilter from './AbstractFilter';

export function getCategoriesList(key = null) {
  switch (key) {
    case 'CLUB':
      return [
        {id: 'COMPLIMENT', name: t`nps.category.COMPLIMENT`},
        {id: 'PAYMENT', name: t`nps.category.PAYMENT`},
        {id: 'INTERESTS', name: t`nps.category.INTERESTS`},
        {id: 'FINANCIAL_PRODUCTS', name: t`nps.category.FINANCIAL_PRODUCTS`},
        {id: 'NONE', name: t`nps.category.NONE`}
      ];
    case 'STORE':
      return [
        {id: 'ATTENDANCE', name: t`nps.category.ATTENDANCE`},
        {id: 'COMFORT', name: t`nps.category.COMFORT`},
        {id: 'COMPLIMENT', name: t`nps.category.COMPLIMENT`},
        {id: 'PRICE', name: t`nps.category.PRICE`},
        {id: 'PRODUCT', name: t`nps.category.PRODUCT`, disabled: true},
        {id: 'PRODUCT_AVAILABILITY', name: t`nps.category.PRODUCT_AVAILABILITY`},
        {id: 'PRODUCT_QUALITY', name: t`nps.category.PRODUCT_QUALITY`},
        {id: 'WAITING_TIME', name: t`nps.category.WAITING_TIME`},
        {id: 'NONE', name: t`nps.category.NONE`}
      ];
    case 'NONE':
      return [
        {id: 'ATTENDANCE', name: t`nps.category.ATTENDANCE`},
        {id: 'COMFORT', name: t`nps.category.COMFORT`},
        {id: 'COMPLIMENT', name: t`nps.category.COMPLIMENT`},
        {id: 'PAYMENT', name: t`nps.category.PAYMENT`},
        {id: 'PRICE', name: t`nps.category.PRICE`},
        {id: 'PRODUCT', name: t`nps.category.PRODUCT`, disabled: true},
        {id: 'PRODUCT_AVAILABILITY', name: t`nps.category.PRODUCT_AVAILABILITY`},
        {id: 'PRODUCT_QUALITY', name: t`nps.category.PRODUCT_QUALITY`},
        {id: 'WAITING_TIME', name: t`nps.category.WAITING_TIME`},
        {id: 'NONE', name: t`nps.category.NONE`}
      ];
    default:
      return [
        {id: 'ATTENDANCE', name: t`nps.category.ATTENDANCE`},
        {id: 'COMFORT', name: t`nps.category.COMFORT`},
        {id: 'COMPLIMENT', name: t`nps.category.COMPLIMENT`},
        {id: 'PAYMENT', name: t`nps.category.PAYMENT`},
        {id: 'PRICE', name: t`nps.category.PRICE`},
        {id: 'PRODUCT', name: t`nps.category.PRODUCT`, disabled: true},
        {id: 'PRODUCT_AVAILABILITY', name: t`nps.category.PRODUCT_AVAILABILITY`},
        {id: 'PRODUCT_QUALITY', name: t`nps.category.PRODUCT_QUALITY`},
        {id: 'INTERESTS', name: t`nps.category.INTERESTS`},
        {id: 'FINANCIAL_PRODUCTS', name: t`nps.category.FINANCIAL_PRODUCTS`},
        {id: 'WAITING_TIME', name: t`nps.category.WAITING_TIME`},
        {id: 'NONE', name: t`nps.category.NONE`}
      ];
  }
}

const CategoriesFilter = props => {
  const {handleChange, selected, visible} = props;
  const options = getCategoriesList();

  if (!visible) return null;
  return <AbstractFilter selected={selected} handleChange={handleChange} options={options} icon={<CategoryIcon />} title={t`common.categories`} />;
};

CategoriesFilter.propTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

export default CategoriesFilter;
