import {Trans} from '@lingui/react';
import {Popover, Badge} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import NotificationIcon from '@material-ui/icons/Notifications';
import classnames from 'classnames';
import {filter, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import styles from '../navbar/NavBarStyles';
import NotificationDrawer from './components/NotificationDrawer';

class NotificationArea extends React.Component {
  state = {
    anchorEl: null
  };

  handleOpen = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  getNotificationsAmount = () => {
    return size(filter(this.props.notifications.items, it => !it.done));
  };

  render() {
    const {anchorEl} = this.state;
    const {classes, notifications, isMobile} = this.props;
    return (
      <div className={classes.root} id="topbar-notifications">
        <IconButton className={classnames(anchorEl ? classes.buttonActive : null, classes.buttonCircle)} aria-owns={'simple-menu'} aria-haspopup="true" aria-label="Alertas" onClick={this.handleOpen}>
          <Badge badgeContent={this.getNotificationsAmount()} classes={{badge: classes.alertBadge}}>
            <NotificationIcon />
            <span>
              <Trans id="topbar.name.alerts">Alertas</Trans>
            </span>
          </Badge>
        </IconButton>
        <Popover
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          className={classes.subMenu}
          marginThreshold={32}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          <NotificationDrawer notifications={notifications} handleClose={this.handleClose} enableClose={isMobile} />
        </Popover>
      </div>
    );
  }
}

NotificationArea.propTypes = {
  classes: PropTypes.object,
  notifications: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired
};

export default connect(state => ({notifications: {...state.notification}}), null)(withStyles(styles)(NotificationArea));
