import Dialog from '@bit/grupo_avenida.components.dialog';
import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import Cookies from 'js-cookie';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

const FootwearCampaingDialog = ({isMobile}) => {
  const [dialog, setDialog] = useState(false);
  const cookieName = 'footwearCampaingShown';

  const handleCloseDialog = () => {
    setDialog(false);
    Cookies.set(cookieName, 'true', {expires: 0.25});
  };

  useEffect(() => {
    const cookieExists = Cookies.get(cookieName);
    if (!cookieExists) {
      setDialog(true);
    }
  }, []);

  return (
    <Dialog
      isMobile={isMobile}
      fullScreen={isMobile}
      open={dialog}
      primaryButtonAction={handleCloseDialog}
      title={
        <Typography size={18} weight="medium" lineHeight={22}>
          Cartão Avenida | Campanha no ar!
        </Typography>
      }
      primaryButtonText={<Trans id="common.action.close" />}
    >
      <a href="https://grupoavenida-my.sharepoint.com/:f:/g/personal/bruno_silva_grupoavenida_com_br/EvTXVQdwhv9JpaMMkmfiaXcBmWuSRD6LP1cu5HqxoSmqxw?e=0L6N8n" rel="noreferrer" target="_blank">
        <img style={{width: '100%', objectFit: 'cover'}} src={`${process.env.PUBLIC_URL}/imgs/campanha/cavCalcados.jpg`} alt="Copa NPS" />
      </a>
    </Dialog>
  );
};

FootwearCampaingDialog.propTypes = {
  isMobile: PropTypes.bool
};

const mapStateToProps = state => ({
  isMobile: state.configuration.isMobile
});

export default connect(mapStateToProps)(FootwearCampaingDialog);
