import green from '@material-ui/core/colors/green';
import red from '@material-ui/core/colors/red';

const styles = theme => ({
  root: {
    width: 'calc(100% - 30px)',
    margin: '20px 15px'
  },
  tableWrap: {
    marginBottom: 20
  },
  breadcrumb: {
    flexGrow: 1
  },
  todayAccSwitchMobile: {
    justifyContent: 'center !important'
  },
  breadLeft: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    fontStyle: 'italic'
  },
  breadRight: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  filters: {
    flexGrow: 1
  },
  goal: {
    fontSize: '12px',
    display: 'block',
    color: '#999',
    textAlign: 'center',
    margin: '0 5px',
    '& span': {
      position: 'relative',
      '& svg': {
        position: 'absolute',
        right: -20,
        top: -5
      }
    }
  },
  card: {
    minHeight: 70,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },
  cardGraph: {
    height: 218,
    paddingTop: 20
  },
  cardIcon: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    height: 30,
    width: '100%'
  },
  cardIconI: {
    padding: 5,
    margin: '0px 10px 0px 0px',
    height: 24,
    width: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#646464'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
    width: '100%'
  },
  cardRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: '100%'
  },
  cardSubtitle: {
    fontSize: 10
  },
  cardSubtitleMobile: {
    fontSize: 8
  },
  noOverflowX: {
    overflowX: 'hidden!important'
  },
  cardEntry: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    marginTop: 5,
    marginBottom: 10
  },
  cardContentDate: {
    width: '100%',
    margin: '0px'
  },
  cardIconCenter: {
    justifyContent: 'center',
    width: '100%'
  },
  cardContentTime: {
    margin: '0px'
  },
  inputGroup: {
    '& input': {
      fontSize: '14px',
      textAlign: 'center',
      padding: 5
    },
    '& span': {
      fontSize: '14px',
      textAlign: 'center',
      padding: '4px 5px'
    }
  },
  cardContentLeft: {
    alignItems: 'flex-start'
  },
  cardLead: {
    fontSize: 11,
    color: 'gray',
    margin: '5px 5px',
    textAlign: 'center'
  },
  cardLeadMobile: {
    fontSize: 8,
    color: 'gray',
    margin: '3px 3px',
    textAlign: 'center'
  },
  cardLeadDate: {
    fontSize: 11
  },
  cardValue: {
    fontSize: 17,
    color: 'black',
    margin: '5px 0px',
    '& input': {}
  },
  cardValueLess: {
    fontSize: 14
  },
  totals: {
    fontSize: 17,
    fontWeight: 'normal'
  },
  cardValueDate: {},
  checked: {
    color: '#FFCC32',
    '& + $bar': {
      backgroundColor: '#FFCC32'
    }
  },
  bar: {},
  positive: {
    color: green[800]
  },
  negative: {
    color: red[800]
  },
  datePicker: {
    maxWidth: 100
  },
  selectFilters: {
    minWidth: 120,
    fontSize: 14,
    textAlign: 'center'
  },
  button: {
    background: 'rgba(0, 0, 0, 0.07)',
    marginLeft: '10px',
    marginRight: '10px',
    borderBottom: 'none',
    color: '#9a978b',
    borderRadius: '7px',
    '&[disabled]': {
      background: 'rgba(0, 0, 0, 0.12)',
      color: 'black',
      borderBottom: '#FFCC32 solid'
    }
  },
  smallValue: {
    color: '#ccc',
    fontSize: 12
  },
  mainFilterBlock: {
    marginBottom: 25,
    paddingTop: 25
  },
  mainTop: {
    paddingTop: 10
  },
  familyShareTotal: {},
  familyShareTotalWrapper: {
    minHeight: 222,
    marginLeft: 0
  },
  familyShareTotalWrapperMobile: {
    marginRight: 0,
    marginLeft: 0
  },

  //TopCardGrid
  topCardsContainer: {
    marginBottom: 0,
    marginTop: 0
  },
  topCardWrapper: {
    marginBottom: theme.spacing(3),
    paddingBottom: '0px !important',
    paddingTop: '0px !important'
  },
  topCardButtonWrapper: {
    paddingTop: '0px !important',
    paddingBottom: '0px !important',
    marginBottom: theme.spacing(2)
  },
  //SmallStyle
  smallCard: {
    minHeight: 70,
    padding: '5px',
    display: 'flex',
    flexDirection: 'column',
    margin: '5px 10px',
    width: 'calc(100% - 30px)'
  },
  smallCardLead: {
    fontSize: 9,
    color: 'gray',
    margin: '5px 5px',
    textAlign: 'center'
  },
  smallTableValues: {
    alignSelf: 'center',
    '& tr': {
      '& th': {
        minWidth: '50px',
        fontWeight: 'normal',
        '&.fontValue': {
          fontSize: 13,
          fontWeight: 'bold',
          margin: '5px',
          color: '#646464'
        },
        '&.fontValueAcc': {
          fontSize: 13,
          margin: '0px',
          color: 'gray'
        }
      }
    }
  },

  //DatePicker Button
  date: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginLeft: '0',
    minWidth: '150px',
    paddingRight: '10px'
  },
  dateContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  dateButton: {
    width: '100%',
    minHeight: '65px',
    padding: '0px 16px',
    marginBottom: '24px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'flex-start',
    '&:hover': {
      background: '#fff'
    }
  },
  dateButtonContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row'
  },
  icon: {
    marginRight: '8px',
    color: '#AAA'
  },
  iconSideFilter: {
    height: '25px',
    marginRight: '8px',
    color: theme.colors.neutral.level7
  },
  title: {
    color: '#333',
    fontSize: '12px',
    paddingRight: '16px'
  },
  titleDateButton: {
    alignItems: 'center',
    display: 'flex',
    fontSize: '1rem !important',
    fontWeight: 400,
    height: '25px',
    textTransform: 'capitalize'
  },
  filterButton: {
    height: 25,
    borderRadius: 4,
    margin: '20px 0px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'transparent'
  },
  salesDashboardChartTitle: {
    fontSize: 14,
    fontFamily: 'Ubuntu',
    fontWeight: 500,
    color: '#555555',
    margin: '5px 5px',
    textAlign: 'left'
  }
});
export default styles;
