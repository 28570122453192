const styles = theme => ({
  contentContainer: {
    width: '440px',
    heigth: '98px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      paddingRight: theme.spacing(2),
      width: '24px',
      heigth: '24px'
    }
  }
});

export default styles;
