import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {filter, map, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../../../app/config';
import {setNotificationAsOld} from '../actions/NotificationsActions';
import Connection from '../socket/connection';
import NotificationCard from './NotificationCard';

const styles = theme => ({
  root: {
    position: 'fixed',
    zIndex: 100,
    width: 350,
    minHeight: 162
  },
  rootPosition: {
    top: 137,
    right: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      right: theme.spacing(2)
    }
  },
  mobileRootPosition: {
    bottom: 50,
    width: '80%',
    left: '10%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  popup: {
    position: 'absolute',
    borderRadius: 5,
    border: `1px solid ${theme.colors.neutral.level8}`,
    backgroundColor: theme.colors.neutral.white,
    padding: 5,
    boxShadow: '#00000029 -5px 10px 10px'
  }
});

class NotificationComponent extends React.Component {
  renderNotificationsPopups = newNotifications => {
    const {classes, isMobile, setNotificationAsOld} = this.props;

    let marginTop = isMobile ? -5 * size(newNotifications) : 0;
    return map(newNotifications, it => {
      const buttonsActions = () => {
        setNotificationAsOld(it.id);
      };
      const card = (
        <div key={it.dateTime} className={classnames([classes.popup, 'noPrint'])} style={{marginTop}}>
          <NotificationCard {...it} enableClose closeButtonAction={buttonsActions} buttonCallback={buttonsActions} />
        </div>
      );
      marginTop += 5;
      return card;
    });
  };

  render() {
    const {
      classes,
      notifications: {items},
      isMobile
    } = this.props;
    const newNotifications = filter(items, it => it.isNew);
    return (
      <div>
        <Connection />
        {size(newNotifications) > 0 && <div className={classnames(classes.root, isMobile ? classes.mobileRootPosition : classes.rootPosition)}>{this.renderNotificationsPopups(newNotifications)}</div>}
      </div>
    );
  }
}

NotificationComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  setNotificationAsOld: PropTypes.func.isRequired,
  notifications: PropTypes.object,
  isMobile: PropTypes.bool.isRequired
};

export default connect(
  state => ({
    isMobile: isMobile(state),
    notifications: {...state.notification}
  }),
  {setNotificationAsOld}
)(withStyles(styles)(NotificationComponent));
