import Dialog from '@bit/grupo_avenida.components.dialog';
import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import Hotkeys from 'react-hot-keys';
import {connect} from 'react-redux';

import styles from './styles';
import Title from './Title';

const SpecialKeys = props => {
  const [type, setType] = useState(null);
  const {classes, loadingExport, loadingPrint} = props;

  const onKeyPressed = (keyName, e) => {
    if ((e.ctrlKey || e.metaKey) && (e.key === 'p' || e.charCode === 16 || e.keyCode === 80)) {
      if (loadingPrint) {
        return null;
      } else if (props.print) {
        props.print();
      } else {
        setType('print');
      }
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    } else if ((e.ctrlKey || e.metaKey) && (e.key === 'e' || e.charCode === 0 || e.keyCode === 69)) {
      if (loadingExport) {
        return null;
      } else if (props.export) {
        props.export();
      } else {
        setType('export');
      }
      e.cancelBubble = true;
      e.preventDefault();
      e.stopImmediatePropagation();
    }
  };

  let description = '';
  let title = '';
  if (type === 'print') {
    title = 'Imprimir relatório';
    description = 'Este relatório não pode ser impresso.';
  } else if (type === 'export') {
    title = 'Exportar relatório';
    description = 'Este relatório não pode ser exportado.';
  }

  const closeModal = () => {
    setType(null);
  };

  return (
    <Hotkeys keyName="ctrl+p,ctrl+e, cmd+p, cmd+e" onKeyDown={onKeyPressed}>
      <Dialog open={type !== null} onClose={closeModal} title={<Title title={title} />} primaryButtonText="Fechar" primaryButtonAction={closeModal}>
        <div className={classes.contentContainer}>
          <Typography family="fontSecondary" size={16} lineHeight={20}>
            {description}
          </Typography>
        </div>
      </Dialog>
    </Hotkeys>
  );
};

SpecialKeys.propTypes = {
  print: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  export: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  classes: PropTypes.object,
  loadingPrint: PropTypes.bool,
  loadingExport: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    loadingPrint: state.print.loading,
    loadingExport: state.export.loading
  };
};

export default connect(mapStateToProps)(withStyles(styles)(SpecialKeys));
