import Service from '../service/UserPreferencesService';
import {SET_PREFERENCES, CLEAR_PREFERENCES, SET_TABLE_COLUMNS, SET_AUTO_UPDATE_INTERVAL, SET_FONT_SIZE, SET_LANGUAGE} from './UserPreferencesActionTypes';

const handleException = exception => {
  if (exception && exception.message === 'cancel') return; // ignore cancel
  console.error('Error saving preferences.', exception);
};

export const getData = () => {
  return Service.getPreferences();
};

export const dispatchPreferences = content => dispatch => {
  dispatch({type: SET_PREFERENCES, payload: content});
};

export const clearPreferences = () => dispatch => {
  dispatch({type: CLEAR_PREFERENCES});
};

export const dispatchTableColumnsFor = (table, columns) => dispatch => {
  Service.saveHiddenColumns({[table]: columns}).catch(handleException);
  dispatch({type: SET_TABLE_COLUMNS, payload: {table, columns}});
};

export const dispatchAutoUpdateInterval = minutesInterval => dispatch => {
  Service.saveAutoUpdateInterval(minutesInterval).catch(handleException);
  dispatch({type: SET_AUTO_UPDATE_INTERVAL, payload: minutesInterval});
};

export const dispatchFontSize = fontSize => dispatch => {
  Service.saveFontSize(fontSize).catch(handleException);
  dispatch({type: SET_FONT_SIZE, payload: fontSize});
};

export const dispatchLanguage = language => dispatch => {
  Service.saveLanguage(language).catch(handleException);
  dispatch({type: SET_LANGUAGE, payload: language});
};
