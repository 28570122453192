import {endOfMonth, getDaysInMonth, getHours, getUnixTime, setMonth, startOfMonth, startOfWeek, subDays, subYears} from 'date-fns';

import {getDateFns} from '../dateFns';
import * as actions from './FilterActionsTypes';

const currentDateTime = getDateFns();
const yesterdayDateTime = subDays(currentDateTime, 1);
const intialState = {
  autoupdate: true,
  bscDateBegin: getUnixTime(startOfMonth(setMonth(subYears(currentDateTime, 1), 9))),
  bscDateEnd: getUnixTime(endOfMonth(setMonth(currentDateTime, 8))),
  comparable: true,
  comparativeDate: null,
  comparativeDateLoading: true,
  currentDate: getUnixTime(yesterdayDateTime),
  customComparativeDate: false,
  dateBegin: getUnixTime(subDays(currentDateTime, getDaysInMonth(yesterdayDateTime))),
  dateEnd: getUnixTime(yesterdayDateTime),
  dateAccBegin: getUnixTime(startOfMonth(yesterdayDateTime)),
  dateAccEnd: getUnixTime(yesterdayDateTime),
  dreDate: null,
  dreDateBegin: null,
  dreDateEnd: null,
  hour: getHours(currentDateTime),
  isAcc: false,
  ignoreMobile: false,
  ignoreEcommerce: false,
  loading: true,
  loadingDREDate: true,
  notComparable: true,
  preset: 'custom',
  realtimeComparativeDate: null,
  realtimeComparativeDateLoading: true,
  realtimeCurrentDate: getUnixTime(currentDateTime),
  showClosedStores: false,
  weeklyDate: getUnixTime(startOfWeek(currentDateTime, {weekStartsOn: 1})),

  // Selecionados
  areasList: [],
  categoriesList: [],
  cities: [],
  departments: [],
  families: [],
  flags: [],
  regional: [],
  states: [],
  statusList: [],
  stores: [],
  newStores: [],
  storeType: [],

  // Opções selecionaveis
  bandeiras: {
    entries: [],
    loading: false
  },
  cidades: {
    entries: [],
    loading: false
  },
  estados: {
    entries: [],
    loading: false
  },
  departamentos: {
    entries: [],
    loading: false
  },
  familias: {
    entries: [],
    loading: false
  },
  filiais: {
    entries: [],
    loading: false
  },
  novasFiliais: {
    entries: [],
    loading: false
  },
  regionais: {
    entries: [],
    loading: false
  },
  tipoFilial: {
    entries: [],
    loading: false
  }
};

export default function(state = intialState, action) {
  const {comparativeDateLoading, realtimeComparativeDateLoading, loadingDREDate} = state;
  switch (action.type) {
    case actions.RANKING_FILTER_DATE:
      return {...state, currentDate: action.payload};
    case actions.RANKING_FILTER_SUCCESS:
      return {...state, comparativeDate: action.payload, comparativeDateLoading: false, loading: realtimeComparativeDateLoading || loadingDREDate};
    case actions.RANKING_FILTER_REQUEST:
      return {...state, comparativeDateLoading: true, loading: true};
    case actions.RANKING_REALTIME_COMPARATIVE_REQUEST:
      return {...state, realtimeComparativeDateLoading: true, loading: true};
    case actions.RANKING_FILTER_FAILURE:
      return {...state, comparativeDateLoading: false, loading: realtimeComparativeDateLoading || loadingDREDate};
    case actions.RANKING_REALTIME_COMPARATIVE_FAILURE:
      return {...state, realtimeComparativeDateLoading: false, loading: comparativeDateLoading || loadingDREDate};
    case actions.AUTOUPDATE_FILTER_SET:
      return {...state, autoupdate: action.payload};
    case actions.RANKING_FILTER_SET:
      return {...state, ...action.payload};
    case actions.RANKING_REALTIME_COMPARATIVE_SUCCESS:
      return {
        ...state,
        realtimeComparativeDate: action.payload,
        realtimeComparativeDateLoading: false,
        loading: comparativeDateLoading || loadingDREDate
      };
    case actions.LOAD_REQUEST_DREDATE:
      return {...state, loadingDREDate: true, loading: true};
    case actions.LOAD_SUCCESS_DREDATE:
      return {...state, dreDate: action.payload, dreDateBegin: action.payload, dreDateEnd: action.payload, loadingDREDate: false, loading: comparativeDateLoading || realtimeComparativeDateLoading};
    case actions.LOAD_FINISH_DREDATE:
      return {...state, dreDate: action.payload, dreDateBegin: action.payload, dreDateEnd: action.payload, loadingDREDate: false, loading: comparativeDateLoading || realtimeComparativeDateLoading};
    case actions.RANKING_FILTER_STORES:
      return {...state, stores: action.payload};
    case actions.RANKING_FILTER_CLEAR:
      return {...state, ...intialState};
    case actions.LOAD_REQUEST_FILIAL:
      return {...state, filiais: {...state.filiais, loading: true}};
    case actions.LOAD_REQUEST_NEW_FILIAL:
      return {...state, novasFiliais: {...state.novasFiliais, loading: true}};
    case actions.LOAD_REQUEST_STORE_TYPE:
      return {...state, tipoFilial: {...state.tipoFilial, loading: true}};
    case actions.LOAD_REQUEST_REGIONAL:
      return {...state, regionais: {...state.regionais, loading: true}};
    case actions.LOAD_REQUEST_BANDEIRA:
      return {...state, bandeiras: {...state.bandeiras, loading: true}};
    case actions.LOAD_REQUEST_FAMILIES:
      return {...state, familias: {...state.familias, loading: true}};
    case actions.LOAD_REQUEST_DEPARTMENTS:
      return {...state, departamentos: {...state.departamentos, loading: true}};
    case actions.LOAD_REQUEST_CITIES:
      return {...state, cidades: {...state.cidades, loading: true}};
    case actions.LOAD_REQUEST_STATES:
      return {...state, estados: {...state.estados, loading: true}};

    case actions.LOAD_SUCCESS_FILIAL:
      return {
        ...state,
        filiais: {...state.filiais, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_NEW_FILIAL:
      return {
        ...state,
        novasFiliais: {...state.novasFiliais, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_STORE_TYPE:
      return {
        ...state,
        tipoFilial: {
          ...state.tipoFilial,
          loading: false,
          entries: action.payload
        }
      };
    case actions.LOAD_SUCCESS_REGIONAL:
      return {
        ...state,
        regionais: {...state.regionais, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_BANDEIRA:
      return {
        ...state,
        bandeiras: {...state.bandeiras, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_FAMILIES:
      return {
        ...state,
        familias: {...state.familias, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_DEPARTMENTS:
      return {
        ...state,
        departamentos: {
          ...state.departamentos,
          loading: false,
          entries: action.payload
        }
      };
    case actions.LOAD_SUCCESS_CITIES:
      return {
        ...state,
        cidades: {...state.cidades, loading: false, entries: action.payload}
      };
    case actions.LOAD_SUCCESS_STATES:
      return {
        ...state,
        estados: {...state.estados, loading: false, entries: action.payload}
      };

    case actions.LOAD_FAILURE_FILIAL:
      return {
        ...state,
        filiais: {...state.filiais, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_NEW_FILIAL:
      return {
        ...state,
        novasFiliais: {...state.novasFiliais, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_STORE_TYPE:
      return {
        ...state,
        tipoFilial: {...state.tipoFilial, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_REGIONAL:
      return {
        ...state,
        regionais: {...state.regionais, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_BANDEIRA:
      return {
        ...state,
        bandeiras: {...state.bandeiras, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_FAMILIES:
      return {
        ...state,
        familias: {...state.familias, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_DEPARTMENTS:
      return {
        ...state,
        departamentos: {...state.departamentos, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_CITIES:
      return {
        ...state,
        cidades: {...state.cidades, loading: false, entries: []}
      };
    case actions.LOAD_FAILURE_STATES:
      return {
        ...state,
        estados: {...state.estados, loading: false, entries: []}
      };

    case actions.FILTER_REMOVE:
      return {
        ...state,
        areasList: [],
        categoriesList: [],
        cities: [],
        departments: [],
        families: [],
        flags: [],
        statusList: [],
        states: [],
        stores: [],
        newStores: [],
        storeType: [],
        regional: [],
        customComparativeDate: false,
        comparable: true,
        ignoreMobile: false,
        ignoreEcommerce: false,
        notComparable: true,
        showClosedStores: false
      };
    case actions.FILTER_CLEAR:
      return {...state, ...intialState};
    default:
      return state;
  }
}
