import {getUnixTime} from 'date-fns';
import {concat, map, remove} from 'lodash';

import {getDateFns} from '../../dateFns';
import {ACTION_SET_NOTIFICATIONS, ACTION_NEW_NOTIFICATION, ACTION_SET_NOTIFICATION_AS_OLD, ACTION_CHECK_NOTIFICATION_STATUS, ACTION_CLEAR_NOTIFICATIONS} from './NotificationsActionTypes';

const initialState = {
  checkStatus: {},
  items: []
};

const addNewNotification = (list, newNotification) => {
  remove(list, it => it.type === newNotification.type);

  if (newNotification.done) {
    return list;
  }

  return concat(list, newNotification);
};

const setNotificationAsOld = (notificationId, notifications) => {
  return map(notifications, it => {
    if (it.id === notificationId) {
      return {...it, isNew: false};
    }
    return it;
  });
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_SET_NOTIFICATIONS:
      return {...state, items: action.payload};
    case ACTION_NEW_NOTIFICATION:
      return {...state, items: addNewNotification([...state.items], action.payload)};
    case ACTION_SET_NOTIFICATION_AS_OLD:
      return {...state, items: setNotificationAsOld(action.payload, state.items)};
    case ACTION_CHECK_NOTIFICATION_STATUS:
      return {...state, checkStatus: {type: action.payload, updated: getUnixTime(getDateFns())}};
    case ACTION_CLEAR_NOTIFICATIONS:
      return {...state, ...initialState};
    default:
      return state;
  }
};
