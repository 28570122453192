import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import styles from '../../../../styles/FilterStyles';

const HourPicker = props => {
  const elements = [];
  for (let i = 0; i < 24; i++) {
    let value = `0${i}`.slice(-2);
    value = parseInt(value);
    elements.push(
      <MenuItem value={value} key={value}>
        {value}
      </MenuItem>
    );
  }

  const handleChange = ({target}) => {
    const {value} = target;
    props.onChange(value);

    ReactGA.event({
      category: 'Filters',
      action: 'Change hour',
      label: `${value}`
    });
  };

  const {classes, value} = props;
  if (!value) return null;
  return (
    <div className={classes.panelContent}>
      <Typography family="fontSecondary" size={16} color="neutral.level5">
        <Trans id="topbar.hourFilter.hour">HORA</Trans>
      </Typography>
      <Select className={classes.hourFieldSelect} onChange={handleChange} value={value}>
        {elements}
      </Select>
    </div>
  );
};

HourPicker.propTypes = {
  classes: PropTypes.object,
  onChange: PropTypes.func,
  show: PropTypes.bool,
  value: PropTypes.number,
  visible: PropTypes.bool
};

export default withStyles(styles)(HourPicker);
