import {t} from '@lingui/macro';
import StoresIcon from '@material-ui/icons/Store';
import PropTypes from 'prop-types';
import React from 'react';

import AbstractFilter from './AbstractFilter';

class StoreTypeFilter extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func,
    selected: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired
  };

  render() {
    if (!this.props.visible) return null;
    const options = [
      {id: 'RUA', name: 'Rua', type: 'storeType'},
      {id: 'SHOPPING', name: 'Shopping', type: 'storeType'},
      {id: 'GALERIA', name: 'Galeria', type: 'storeType'}
    ];

    return <AbstractFilter handleChange={this.props.handleChange} icon={<StoresIcon />} options={options} selected={this.props.selected} showSelectAll title={t`common.storeType`} />;
  }
}

export default StoreTypeFilter;
