import styled from 'styled-components';

const TopContainer = styled.div`
  margin-bottom: ${({theme}) => `${theme.spacing(2)}px`};
  width: 100%;
`;

const TitlesList = styled.ul`
  width: 100%;
  padding-left: ${({theme: {spacing}}) => spacing(2)}px;
`;

export {TitlesList, TopContainer};
