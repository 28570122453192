import {Trans} from '@lingui/react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import {withStyles} from '@material-ui/core/styles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import NavbarContext from '../context/NavbarContext';
import Excel from './actions/ExcelComponent';
import Print from './actions/PrintComponent';
import styles from './NavBarStyles';

class ExtraActions extends React.Component {
  static contextType = NavbarContext;

  static propTypes = {
    isMobile: PropTypes.bool,
    classes: PropTypes.object
  };

  state = {
    anchorEl: null
  };

  handleClose = () => {
    this.setState({anchorEl: null});
  };

  handleOpen = event => {
    this.setState({anchorEl: event.currentTarget});
  };

  isDisabled = () => {
    return !this.context.print && !this.context.excel;
  };

  render() {
    const {anchorEl} = this.state;
    const {classes} = this.props;

    return (
      <div className={classes.root} id="topbar-extra_actions">
        <IconButton className={classnames(anchorEl ? classes.buttonActive : null, classes.buttonCircle)} disabled={this.isDisabled()} aria-label="Ações" onClick={this.handleOpen}>
          <MoreVertIcon />
          <span>
            <Trans id="common.action">Ações</Trans>
          </span>
        </IconButton>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          className={classes.subMenu}
          disableAutoFocusItem
          getContentAnchorEl={null}
          onClose={this.handleClose}
          open={Boolean(anchorEl)}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center'
          }}
        >
          {this.context.print && <Print classes={classes} onClick={this.handleClose} />}
          {this.context.excel && <Excel classes={classes} onClick={this.handleClose} />}
        </Menu>
      </div>
    );
  }
}

export default withStyles(styles)(ExtraActions);
