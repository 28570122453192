import Dialog from '@bit/grupo_avenida.components.dialog';
import Typography from '@bit/grupo_avenida.components.typography';
import {RadioGroup, FormControlLabel, Radio, FormControl} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import {TimePicker} from '@material-ui/pickers';
import {result, toLower} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {formatDateFns} from '../../../../dateFns';
import value from '../../../../formatter/value';
import {checkConflicts} from '../actions/HolidayAlertActions';
import styles from './DialogStyles';

class HolidayActivityRegisterModal extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    openModal: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    updateData: PropTypes.func
  };

  constructor(props) {
    super(props);
    this.state = {
      dialogData: {},
      auxDialogData: {},
      actionLoading: false,
      dialogLoading: false,
      checkForConflicts: this.checkForConflicts,
      shouldDisableSaveButton: this.shouldDisableSaveButton,
      getDialogBody: this.getDialogBody
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.openModal === nextProps.openModal) return null;

    const {data, closeModal} = nextProps;
    const {holidayDate, holidayName} = data;
    const formattedHolidayDate = formatDateFns(holidayDate);
    return {
      dialogData: {
        ...data,
        title: `${holidayName} - ${formattedHolidayDate}`,
        status: null,
        message: null,
        primaryButtonText: 'Salvar',
        primaryButtonAction: prevState.checkForConflicts,
        shouldDisablePrimary: prevState.shouldDisableSaveButton,
        secondaryButtonText: 'Fechar',
        secondaryButtonAction: closeModal,
        children: prevState.getDialogBody
      },
      holidayDate,
      openModal: nextProps.openModal
    };
  }

  getOpenLastYearValue = openLastYear => {
    if (openLastYear === true) {
      return 'Sim';
    }
    if (openLastYear === false) {
      return 'Não';
    }
    return 'Não definido';
  };

  getDialogBody = () => {
    const {classes} = this.props;
    const {dialogData} = this.state;
    const {disableFields} = dialogData;

    return (
      <div className={classes.childrenWrapper}>
        <div className={classes.line}>
          <div className={classes.centralizedColumn}>
            <Typography lineHeight={16} size={14}>
              Loja
            </Typography>
            <Typography family="fontSecondary" lineHeight={22} size={14} weight="medium">
              {dialogData.storeName}
            </Typography>
          </div>
        </div>
        <div className={classes.line}>
          <div className={classes.centralizedColumn}>
            <Typography lineHeight={16} size={14}>
              Abertura A.A.
            </Typography>
            <Typography family="fontSecondary" lineHeight={22} size={14} weight="medium">
              {this.getOpenLastYearValue(dialogData.openLastYear)}
            </Typography>
          </div>
          <div className={classes.centralizedColumn}>
            <Typography lineHeight={16} size={14}>
              Valor Vendido A.A.
            </Typography>
            <Typography family="fontSecondary" lineHeight={22} size={14} weight="medium">
              {value(dialogData.comparativeSale)}
            </Typography>
          </div>
        </div>
        <div className={classes.centralizedColumn}>
          <Typography lineHeight={16} size={14}>
            Abertura
          </Typography>
          <FormControl component="fieldset" disabled={disableFields}>
            <RadioGroup name="radio" row={true} onChange={this.handleOptionChange} value={this.getIsOpen()}>
              <FormControlLabel
                value={'true'}
                control={<Radio />}
                label={
                  <Typography family="fontSecondary" lineHeight={22} size={14} weight="medium">
                    Sim
                  </Typography>
                }
              />
              <FormControlLabel
                value={'false'}
                control={<Radio />}
                label={
                  <Typography family="fontSecondary" lineHeight={22} size={14} weight="medium">
                    Não
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.lineCenter}>
          <div className={classes.fieldTime}>
            <Typography lineHeight={16} size={14}>
              Hora entrada
            </Typography>
            <TimePicker
              className={classes.timePicker}
              disabled={!dialogData.isOpen || disableFields}
              onChange={this.handleTimeChange('openingTime')}
              value={dialogData.openingTime && dialogData.isOpen ? dialogData.openingTime : (dialogData.openingTime = null)}
              ampm={false}
              autoOk
            />
          </div>
          <div className={classes.fieldTime}>
            <Typography lineHeight={16} size={14}>
              Hora saída
            </Typography>
            <TimePicker
              className={classes.timePicker}
              disabled={!dialogData.isOpen || disableFields}
              onChange={this.handleTimeChange('closingTime')}
              value={dialogData.closingTime && dialogData.isOpen ? dialogData.closingTime : (dialogData.closingTime = null)}
              ampm={false}
              autoOk
            />
          </div>
        </div>
        <div className={classes.line}>
          <div className={classes.centralizedColumn}>
            <Typography lineHeight={16} size={14}>
              Observações
            </Typography>
            <input maxLength={100} onChange={this.handleInputChange('note')} defaultValue={dialogData ? dialogData.note : ''} className={classes.note} type="text" disabled={disableFields}></input>
          </div>
        </div>
      </div>
    );
  };

  startLoading = () => {
    const {dialogData} = this.state;
    this.setState({actionLoading: true, dialogData: {...dialogData, disableFields: true, status: null}});
  };

  checkForConflicts = async () => {
    const {dialogData, holidayDate} = this.state;
    const {storeCode, isOpen} = dialogData;

    const date = formatDateFns(holidayDate, 'yyyy-MM-dd');
    this.startLoading();
    checkConflicts(date, storeCode, isOpen)
      .then(response => {
        this.setState({actionLoading: false});
        if (response.hasConflict) {
          this.showConfirmation(response.storeType);
        } else {
          this.handleSave();
        }
      })
      .catch(() => {
        this.setState({
          actionLoading: false,
          dialogData: {
            ...this.state.dialogData,
            disableFields: false,
            status: 'error',
            message: 'Falha ao salvar informação de abertura.',
            primaryButtonText: 'Tentar Novamente'
          }
        });
      });
  };

  showConfirmation = storeType => {
    const {dialogData} = this.state;
    let children;

    if (dialogData.isOpen) {
      children = (
        <span>
          Lojas de <u>{toLower(storeType)}</u> de sua região fecharão nesta data. Você confirma a abertura?
        </span>
      );
    } else {
      children = (
        <span>
          Lojas de <u>{toLower(storeType)}</u> de sua região abrirão nesta data. Você confirma o fechamento?
        </span>
      );
    }

    this.setState({
      auxDialogData: dialogData,
      dialogData: {
        ...dialogData,
        children: () => children,
        title: 'Conflito!',
        primaryButtonText: 'Salvar',
        primaryButtonAction: this.handleSave,
        secondaryButtonText: 'Voltar',
        secondaryButtonAction: () => this.setState({dialogData: {...dialogData, disableFields: false}, auxDialogData: {}})
      }
    });
  };

  handleSave = async () => {
    const {dialogData, holidayDate} = this.state;
    const {data, onSave, closeModal} = this.props;
    const date = formatDateFns(holidayDate, 'yyyy-MM-dd');
    this.startLoading();
    await onSave({...dialogData, storeCode: data.storeCode, date})
      .then(() => {
        this.setState({
          actionLoading: false,
          dialogData: {
            ...this.state.dialogData,
            status: 'success',
            message: 'Salvo com sucesso!',
            primaryButtonText: null,
            primaryButtonAction: null,
            secondaryButtonText: 'Fechar',
            secondaryButtonAction: closeModal
          }
        });
        result(this.props, 'updateData');
      })
      .catch(() => {
        this.setState({
          actionLoading: false,
          dialogData: {
            ...this.state.dialogData,
            disableFields: false,
            status: 'error',
            message: 'Falha ao salvar informação de abertura.',
            primaryButtonText: 'Tentar Novamente'
          }
        });
      });
  };

  handleChange = (name, value) => {
    const {dialogData} = this.state;
    this.setState({dialogData: {...dialogData, [name]: value}});
  };

  handleInputChange = name => ({target}) => {
    const {value} = target;
    this.handleChange(name, value);
  };

  handleTimeChange = name => time => {
    this.handleChange(name, time);
  };

  handleOptionChange = event => {
    const {value} = event.target;
    this.handleChange('isOpen', value === 'true');
  };

  shouldDisableSaveButton = () => {
    const {
      dialogData: {isOpen, openingTime, closingTime}
    } = this.state;
    return isOpen == null || (isOpen && (!openingTime || !closingTime));
  };

  getIsOpen = () => {
    const {
      dialogData: {isOpen}
    } = this.state;
    if (isOpen) {
      return 'true';
    } else if (isOpen === false) {
      return 'false';
    }
    return null;
  };

  renderChildren = () => {
    return result(this.state, 'dialogData.children');
  };

  render() {
    const {openModal, closeModal} = this.props;
    const {dialogData, actionLoading} = this.state;
    return (
      <Dialog
        maxWidth="xs"
        open={openModal}
        onClose={closeModal}
        title={dialogData.title}
        status={dialogData.status}
        message={dialogData.message}
        actionLoading={actionLoading}
        disablePrimary={actionLoading || result(dialogData, 'shouldDisablePrimary', false)}
        disableSecondary={actionLoading}
        disableClose={actionLoading}
        primaryButtonText={dialogData.primaryButtonText}
        primaryButtonAction={dialogData.primaryButtonAction}
        secondaryButtonText={dialogData.secondaryButtonText}
        secondaryButtonAction={dialogData.secondaryButtonAction}
      >
        {this.renderChildren()}
      </Dialog>
    );
  }
}

export default withStyles(styles)(HolidayActivityRegisterModal);
