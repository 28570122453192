import {t} from '@lingui/macro';
import Day from '@material-ui/pickers/views/Calendar/Day';
import {isAfter, isBefore, isSameDay, fromUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import {getDateFns, formatDateFns} from '../../../../dateFns';
import DateComponent from './components/DateComponent';

const ProductivityDateFilter = props => {
  const {
    dateFilter: {date, auxDate},
    onChange
  } = props;
  const currentDateTime = getDateFns();

  const renderDay = (dateValue, selectedDate, disabled, currentMonth) => {
    return (
      <Day selected={isSameDay(dateValue, selectedDate)} disabled={disabled} hidden={!currentMonth}>
        {formatDateFns(dateValue, 'd')}
      </Day>
    );
  };

  const renderStartDay = (dateValue, selectedDate, currentMonth) => {
    const disabled = isAfter(dateValue, fromUnixTime(auxDate));
    return renderDay(dateValue, selectedDate, disabled, currentMonth);
  };

  const renderEndDay = (dateValue, selectedDate, currentMonth) => {
    const disabled = isBefore(dateValue, fromUnixTime(date)) || isAfter(dateValue, currentDateTime);
    return renderDay(dateValue, selectedDate, disabled, currentMonth);
  };

  return (
    <React.Fragment>
      <DateComponent label={t`topbar.dateFilter.initialDate`} value={date} maxDate={auxDate} onChange={onChange('dateAccBegin')} renderDay={renderStartDay} />
      <DateComponent label={t`topbar.dateFilter.finalDate`} value={auxDate} minDate={date} onChange={onChange('dateAccEnd')} renderDay={renderEndDay} />
    </React.Fragment>
  );
};

ProductivityDateFilter.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ProductivityDateFilter;
