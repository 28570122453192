import {sideMenuColor, sideBorderColor} from './SideMenuStyles';

const iconColor = 'rgba(100,100,100,1)';

const styles = () => ({
  topbar: {
    backgroundColor: 'rgba(220,220,220,1)',
    '& .progressBarStyle': {
      margin: 0,
      position: 'fixed !import',
      top: '54px',
      width: '100%'
    }
  },
  progressBarStyle: {
    position: 'fixed !important',
    top: '70px',
    width: '100%'
  },
  grid: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    zIndex: 150,
    width: '100%'
  },
  group: {
    float: 'left',
    fontSize: 15,
    height: 70,
    minWidth: 52,
    margin: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgb(240,240,240)',
    borderLeft: '1px solid #c7c6c6',
    borderLeftColor: sideBorderColor,
    '& > div': {
      height: '100%',
      width: '100%'
    },
    '& button': {
      minWidth: 'auto',
      padding: 0,
      height: '100%',
      width: '100%',
      borderRadius: 0,
      '&:hover': {
        backgroundColor: sideMenuColor
      }
    },
    '&:nth-child(1)': {
      width: 70, //246,
      marginLeft: 0,
      borderLeft: 'none',
      backgroundColor: sideMenuColor,
      justifyContent: 'center',
      marginTop: 0,
      borderRight: '1px solid #c7c6c6',
      borderRightColor: sideBorderColor,
      '& button:hover': {
        backgroundColor: 'rgba(0,0,0,0.05)'
      }
    },
    '&:nth-child(2)': {
      width: 'calc(100% - 128px)',
      paddingLeft: 20
    },
    '&:nth-child(4)': {
      width: 270,
      justifyContent: 'flex-end',
      marginRight: 0
    }
  },
  appLogo: {
    float: 'left',
    width: 80
  },
  pLabel: {
    margin: 0,
    fontSize: 13,
    fontWeight: '300',
    color: iconColor
  },
  pLabelUser: {},
  DateTimeIcons: {
    marginRight: 10,
    marginLeft: 10,
    color: iconColor
  },
  PersonIcon: {
    color: iconColor
  },
  date: {},
  time: {},
  logo: {},
  login: {}
});

export default styles;
export {iconColor};
