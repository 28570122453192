import Button from '@bit/grupo_avenida.components.button';
import Dialog from '@bit/grupo_avenida.components.dialog';
import Input from '@bit/grupo_avenida.components.input';
import ListDragDrop from '@bit/grupo_avenida.components.list-drag-drop';
import Select from '@bit/grupo_avenida.components.select';
import StatusComponent from '@bit/grupo_avenida.components.status';
import Typography from '@bit/grupo_avenida.components.typography';
import {t} from '@lingui/macro';
import {Trans} from '@lingui/react';
import {withStyles} from '@material-ui/core';
import {findIndex, get, isEqual, map, size, trim} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

class EditingPanel extends React.Component {
  constructor(props) {
    super(props);
    const {
      availableCharts,
      panelData: {charts}
    } = props;
    this.state = {
      availableCharts: this.getAvailableCharts(availableCharts, charts),
      openDialog: false
    };
  }

  componentDidUpdate(prevProps) {
    if (!isEqual(get(prevProps, 'panelData.charts'), get(this.props, 'panelData.charts'))) {
      const {
        availableCharts,
        panelData: {charts}
      } = this.props;
      this.setState({availableCharts: this.getAvailableCharts(availableCharts, charts)});
    }
  }

  getAvailableCharts = (availableCharts, panelCharts) => {
    return map(availableCharts, chart => {
      const alreadySelectedChart = findIndex(panelCharts, {id: chart.id}) !== -1;
      return {...chart, isDisabled: alreadySelectedChart};
    });
  };

  getParsedCharts = () => {
    const {
      panelData: {charts}
    } = this.props;
    return map(charts, chart => ({id: chart.id, content: get(chart, 'name', 'Gráfico descontinuado')}));
  };

  deleteConfirmation = () => {
    this.setState({openDialog: true});
  };

  deletePanel = () => {
    this.setState({openDialog: false});
    this.props.handleDelete();
  };

  cancelDelete = () => {
    this.setState({openDialog: false});
  };

  render() {
    const {
      classes,
      handleAddChart,
      handleCancel,
      handleChangeCharts,
      handleChangeName,
      handleSave,
      loading,
      loadingDelete,
      loadingDeleteError,
      loadingSave,
      loadingSaveError,
      panelData: {id, name, charts}
    } = this.props;
    const {availableCharts, openDialog} = this.state;

    const hasCharts = size(charts) > 0;
    const disableContent = loading || loadingDelete || loadingSave;
    const disableSave = disableContent || !trim(name) || !hasCharts;
    const status = loadingDeleteError || loadingSaveError ? 'error' : false;
    const message = status ? 'Erro ao processar sua solicitação' : null;

    return (
      <div className={classes.mainContainer}>
        <div className={classes.inputWrapper}>
          <Input
            onChange={handleChangeName}
            disabled={disableContent}
            placeholder={t`salesDashboard.editingPanel.nameYourDashboard`}
            title={t`salesDashboard.editingPanel.dashboardName`}
            value={name}
          />
        </div>
        <div className={classes.selectWrapper}>
          <Select
            disabled={disableContent}
            getOptionLabel={option => option.name}
            getOptionValue={option => option.id}
            maxWidth={600}
            noOptionsMessage={t`salesDashboard.editingPanel.noChartsAvailable`}
            onChange={handleAddChart}
            options={availableCharts}
            placeholder={t`salesDashboard.editingPanel.selectChart`}
            title={t`salesDashboard.editingPanel.addChart`}
            value={null}
          />
        </div>
        <div className={classes.dragAndDropWrapper}>
          <ListDragDrop disabled={loading} items={this.getParsedCharts()} onChange={handleChangeCharts} title={t`salesDashboards.editingPanel.chartsList`} />
          {!hasCharts && (
            <Typography family="fontSecondary" color="neutral.level3" size={14}>
              <Trans id="salesDashboard.youDontHaveAnyChartYet">Você ainda não adicionou gráficos.</Trans>
            </Typography>
          )}
        </div>
        <div className={classes.messageWrapper}>
          <StatusComponent status={status} message={message} />
        </div>
        <div className={classes.actionButtonsWrapper}>
          <div className={classes.deleteButtonWrapper}>
            <Button fullWidth variant="danger" onClick={this.deleteConfirmation} disabled={disableContent || !id} loading={loadingDelete}>
              <Trans id="salesDashboard.editingPanel.delete">Excluir</Trans>
            </Button>
          </div>
          <div className={classes.saveCancelButtonWrapper}>
            <div className={classes.cancelButton}>
              <Button fullWidth variant="outline" onClick={handleCancel} disabled={disableContent}>
                <Trans id="common.action.cancel">Cancelar</Trans>
              </Button>
            </div>
            <div className={classes.saveButton}>
              <Button fullWidth variant="primary" onClick={handleSave} disabled={disableSave} loading={loadingSave}>
                <Trans id="salesDashboard.editingPanel.saveDashboard">Salvar painel</Trans>
              </Button>
            </div>
          </div>
        </div>
        <div className={classes.dialogWrapper}>
          <Dialog
            open={openDialog}
            onClose={this.cancelDelete}
            primaryButtonAction={this.deletePanel}
            primaryButtonText={t`salesDashboard.dialog.confirmDelete`}
            primaryButtonVariant="danger"
            secondaryButtonAction={this.cancelDelete}
            secondaryButtonText={t`common.action.cancel`}
            secondaryButtonVariant="secondary"
            title={t`salesDashboard.dialog.deleteDashboard`}
          >
            <Typography family="fontSecondary" size={16}>
              <Trans id="salesDashboard.dialog.deleteConfirmationMessage">Você tem certeza que deseja excluir o painel</Trans> &nbsp;<b>{name}</b>?
            </Typography>
          </Dialog>
        </div>
      </div>
    );
  }
}

EditingPanel.propTypes = {
  availableCharts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired
    })
  ).isRequired,
  classes: PropTypes.object.isRequired,
  handleAddChart: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleChangeCharts: PropTypes.func.isRequired,
  handleChangeName: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingDelete: PropTypes.bool.isRequired,
  loadingDeleteError: PropTypes.bool.isRequired,
  loadingSave: PropTypes.bool.isRequired,
  loadingSaveError: PropTypes.bool.isRequired,
  panelData: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    charts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string
      })
    )
  }).isRequired
};

export default withStyles(styles)(EditingPanel);
