import GuidedVisit from '@bit/grupo_avenida.components.guided-visit';
import {find} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';

import {dispatchFinished, dispatchSeeLater} from '../../../app/guidedvisit/loader/action';
import {TOP_BAR_ID} from '../../../app/guidedvisit/loader/types';

const steps = [
  {
    content: 'Preparamos uma visita guiada para apresentar as principais funcionalidades do cabeçalho.',
    placement: 'bottom',
    showProgress: false,
    showSecondaryButton: true,
    showTertiaryButton: true,
    spotlightPadding: 4,
    target: '#topbar',
    title: 'Navegação do Siga'
  },
  {
    content: 'Para navegar pelo Siga, use este menu lateral. Por ele você pode ter acesso a todos os relatórios do sistema.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#topbar-menu',
    title: 'Menu'
  },
  {
    content: 'Esta é outra maneira de navegar através do Siga. Por aqui, além de alternar entre os relatórios, é possível alterar entre o tipo de informação a ser mostrada.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#breadcrumb',
    title: 'Navegação'
  },
  {
    content: 'Use os filtros para buscar informações específicas de um relatório.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#topbar-filter-button',
    title: 'Filtros'
  },
  {
    content: 'Aqui você tem a lista dos seus alertas e encontra informações para resolvê-los.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#topbar-notifications',
    title: 'Alertas'
  },
  {
    content: 'Utilize a aba ações para exportar ou imprimir o conteúdo.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#topbar-extra_actions',
    title: 'Ações'
  },
  {
    content: 'Por fim, aqui você pode desconectar sua conta do Siga.',
    placement: 'bottom',
    showCloseButton: true,
    showSecondaryButton: true,
    spotlightPadding: 4,
    target: '#topbar-user_menu',
    title: 'Usuário'
  }
];

const TopBarGuidedVisit = props => {
  const {pendingVisits} = props;
  const run = find(pendingVisits, it => it === TOP_BAR_ID) !== undefined;

  const handleFinish = () => {
    ReactGA.event({
      category: 'GuidedVisit',
      action: 'Finish',
      label: `${TOP_BAR_ID}`
    });
    props.dispatchFinished(TOP_BAR_ID);
  };

  const handleSkip = () => {
    ReactGA.event({
      category: 'GuidedVisit',
      action: 'Skip',
      label: `${TOP_BAR_ID}`
    });
    props.dispatchFinished(TOP_BAR_ID);
  };

  const handleClose = () => {
    props.dispatchSeeLater(TOP_BAR_ID);
  };

  return <GuidedVisit disableOverlayClose showProgress onClose={handleClose} onFinish={handleFinish} onSkip={handleSkip} run={run} steps={steps} />;
};

TopBarGuidedVisit.propTypes = {
  pendingVisits: PropTypes.array,
  dispatchFinished: PropTypes.func.isRequired,
  dispatchSeeLater: PropTypes.func.isRequired
};

export default connect(state => ({pendingVisits: [...state.guidedVisit.pending]}), {dispatchFinished, dispatchSeeLater})(TopBarGuidedVisit);
