import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

const Title = props => {
  const {classes, title} = props;

  return (
    <div className={classes.header}>
      <WarningIcon />
      <Typography size={20} lineHeight={24}>
        {title}
      </Typography>
    </div>
  );
};

Title.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string
};

export default withStyles(styles)(Title);
