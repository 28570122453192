import BaseService from '../../service/BaseService';

class AdminFilterService extends BaseService {
  prefix = 'filter';

  getDREDate() {
    return this.get(null, 'dre/latestDate');
  }

  loadStores() {
    return this.get('stores');
  }

  loadRegionais() {
    return this.get('regionalManagers');
  }

  loadFlags() {
    return this.get('flags');
  }
}

export default new AdminFilterService();
