import {isBefore, fromUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router';

import {getDateFns} from '../app/dateFns';
import {updateToken} from '../login/actions/LoginActions';
import {isAdmin} from '../login/permissions';
import Base from './AdminStructure';

class AdminProtectedRoute extends React.Component {
  static propTypes = {
    updateToken: PropTypes.func.isRequired,
    user: PropTypes.object,
    component: PropTypes.object,
    children: PropTypes.node,
    render: PropTypes.node
  };

  render() {
    const {user, component: Component, render, children, ...props} = this.props;
    if (user.tokenExpireDate && !user.updatingToken && isBefore(fromUnixTime(user.tokenExpireDate), getDateFns())) {
      this.props.updateToken();
    }

    if (!user.logged) {
      return (
        <Redirect
          to={{
            pathname: '/login',
            state: {from: props.location, isAdminRequired: true}
          }}
        />
      );
    }

    if (!isAdmin()) {
      return <Redirect to={{pathname: '/'}} />;
    }

    if (children) {
      return <Route {...props}>{children}</Route>;
    } else if (render) {
      return <Route {...props} render={render} />;
    }
    return (
      <Route
        {...props}
        render={p => (
          <Base>
            <Component {...p} />
          </Base>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: {...state.user}
});

export default connect(mapStateToProps, {updateToken})(AdminProtectedRoute);
