import BaseService from '../../service/BaseService';

class LoginService extends BaseService {
  prefix = 'login/v2';

  async verifyToken() {
    const response = await this.post({}, 'login/token/decode');
    const {roles, token, name, writableStores, userStore} = response;
    return {roles, name, writableStores, userStore, token};
  }

  async logoutUser() {
    await this.post({}, '/logout');
  }
}

export default new LoginService();
