const urlSettings = {
  url: 'operacional/tudoav',
  routes: {
    averageTicket: 'ticket-medio',
    registrations: 'cadastros',
    monthlyChart: 'grafico-mensal',
    transactions: 'transacoes'
  }
};

export default urlSettings;
