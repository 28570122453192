import {t} from '@lingui/macro';
import AdjustIcon from '@material-ui/icons/Adjust';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssignmentOutlined from '@material-ui/icons/AssignmentOutlined';
import BarChartIcon from '@material-ui/icons/BarChart';
import BusinessIcon from '@material-ui/icons/BusinessCenter';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CreditCard from '@material-ui/icons/CreditCard';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import EventIcon from '@material-ui/icons/Event';
import HourglassIcon from '@material-ui/icons/HourglassEmptyOutlined';
import ListIcon from '@material-ui/icons/List';
import MessageIcon from '@material-ui/icons/Message';
import MobileFriendlyIcon from '@material-ui/icons/MobileFriendly';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StoreVisitIcon from '@material-ui/icons/Store';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import React, {Component} from 'react';

import dailySalesUrlSettings from '../../dailysales/DailySalesUrl';
import dailyShoppingUrlSettings from '../../dailyshopping/DailyShoppingUrl';
import productivityUrlSettings from '../../productivity/ProductivityUrl';
import scheduleUrlSettings from '../../schedule/ScheduleUrl';
import SalesIcon from '../../styles/icons/money';
import NPSIcon from '../../styles/icons/nps';
import ProductivityIcon from '../../styles/icons/productivity';
import TopSalesIcon from '../../styles/icons/topSales';
import tudoavUrlSettings from '../../tudoav/tudoavUrl';
import Menu from './Menu';

class MenuMapProvider extends Component {
  menuMap = [
    {
      key: 'ranking',
      title: t`reports.ranking.name`,
      path: '/ranking',
      icon: EqualizerIcon,
      children: [
        {
          title: t`reports.comparative.name`,
          path: '/ranking/comparativo',
          icon: CompareArrowsIcon
        },
        {
          title: t`reports.families.name`,
          path: '/ranking/familias',
          icon: ShoppingCartIcon
        },
        {
          title: t`reports.mobilePOS.name`,
          path: '/ranking/pdvmovel',
          icon: MobileFriendlyIcon
        }
      ]
    },
    {
      key: 'goals',
      title: t`reports.goals.name`,
      path: '/compromissos',
      icon: AdjustIcon,
      children: []
    },
    {
      key: 'operations',
      title: t`reports.operations.name`,
      path: '/operacional',
      icon: ListIcon,
      children: [
        {
          title: t`reports.dayToDayShopping.name`,
          path: `/${dailyShoppingUrlSettings.url}`,
          icon: BusinessIcon,
          subChildren: [
            {
              path: `/${dailyShoppingUrlSettings.url}/estoque-em-pecas`,
              title: t`reports.stockInPieces.name`
            },
            {
              path: `/${dailyShoppingUrlSettings.url}/estoque-em-valor`,
              title: t`reports.stockInValue.name`
            },
            {
              path: `/${dailyShoppingUrlSettings.url}/estoque-por-filial-valor`,
              title: t`reports.stockByStoreValue.name`
            },
            {
              path: `/${dailyShoppingUrlSettings.url}/estoque-por-filial-pecas`,
              title: t`reports.stockByStorePieces.name`
            },
            {
              path: `/${dailyShoppingUrlSettings.url}/shares`,
              title: t`reports.shares.name`
            }
          ]
        },
        {
          title: t`reports.schedule.name`,
          path: `/${scheduleUrlSettings.url}`,
          icon: PermContactCalendarIcon,
          subChildren: [
            {
              path: `/${scheduleUrlSettings.url}/painel`,
              title: t`reports.schedule.dashboard`
            },
            {
              path: `/${scheduleUrlSettings.url}/preenchimento`,
              title: t`reports.schedule.fillForm`
            }
          ]
        },

        {
          title: t`reports.storeHour.name`,
          path: '/operacional/horario-lojas',
          icon: HourglassIcon
        },
        {
          title: t`reports.salesDashboardV2.name`,
          path: '/operacional/painel-v2',
          icon: DashboardIcon
        },
        {
          title: t`reports.startingPoint.name`,
          path: '/operacional/pontodepartida',
          icon: DeviceHubIcon
        },
        {
          title: t`reports.productivity.name`,
          path: `/${productivityUrlSettings.url}`,
          icon: ProductivityIcon,
          subChildren: [
            {
              path: `/${productivityUrlSettings.url}/funcionarios`,
              title: t`reports.employees.name`
            },
            {
              path: `/${productivityUrlSettings.url}/lotacionograma`,
              title: t`reports.lotacionogram.name`
            }
          ]
        },
        {
          title: t`reports.financialProducts.name`,
          path: '/operacional/produtos-financeiros',
          icon: CreditCard
        },
        {
          path: '/operacional/prova-pop',
          title: t`reports.popQuiz.name`,
          icon: AssignmentOutlined
        },
        {
          title: t`reports.holidaysSummary.name`,
          path: '/operacional/resumo-feriados',
          icon: EventIcon
        },
        {
          title: t`reports.topSale.name`,
          path: '/operacional/top-vendas',
          icon: TopSalesIcon
        },
        {
          title: t`reports.tudoAv.name`,
          path: `/${tudoavUrlSettings.url}`,
          icon: PermContactCalendarIcon,
          subChildren: [
            {
              title: t`reports.tudoAv.monthlyChart.name`,
              path: `/${tudoavUrlSettings.url}/${tudoavUrlSettings.routes.monthlyChart}`
            },
            {
              title: t`reports.tudoAv.averageTicket.name`,
              path: `/${tudoavUrlSettings.url}/${tudoavUrlSettings.routes.averageTicket}`
            },
            {
              title: t`reports.tudoAv.transactions.name`,
              path: `/${tudoavUrlSettings.url}/${tudoavUrlSettings.routes.transactions}`
            },
            {
              title: t`reports.tudoAv.registrations.name`,
              path: `/${tudoavUrlSettings.url}/${tudoavUrlSettings.routes.registrations}`
            }
          ]
        },
        {
          title: t`reports.saleByArea.name`,
          path: '/operacional/area-venda',
          icon: StoreVisitIcon
        },
        {
          title: t`reports.dayToDaySales.name`,
          path: `/${dailySalesUrlSettings.url}`,
          icon: SalesIcon,
          subChildren: [
            {
              path: `/${dailySalesUrlSettings.url}/compromisso`,
              title: t`reports.saleGoal.name`
            },
            {
              path: `/${dailySalesUrlSettings.url}/compromisso-diario`,
              title: t`reports.dailySaleGoal.name`
            },
            {
              path: `/${dailySalesUrlSettings.url}/projecoes`,
              title: t`reports.projections.name`
            },
            {
              path: `/${dailySalesUrlSettings.url}/venda`,
              title: t`reports.dailySales.name`
            }
          ]
        }
      ]
    },
    {
      key: 'financialProducts',
      title: t`reports.financialProducts.name`,
      path: '/produtos-financeiros',
      icon: CreditCard,
      children: [
        {
          title: t`reports.newStores.name`,
          path: '/produtos-financeiros/lojas-novas',
          icon: CreditCard
        }
      ]
    },
    {
      key: 'dre',
      title: t`reports.dre.name`,
      path: '/dre',
      icon: AssessmentIcon,
      children: [
        {
          title: t`reports.analytical.name`,
          path: '/dre/analitico',
          icon: AssessmentIcon
        },
        {
          title: t`reports.bsc.name`,
          path: '/dre/bsc',
          icon: BarChartIcon
        },
        {
          title: t`reports.dre.name`,
          path: '/dre/dre',
          icon: AssessmentIcon
        },
        {
          title: t`reports.costsRanking.name`,
          path: '/dre/ranking-de-custos',
          icon: TrendingUpIcon
        }
      ]
    },
    {
      key: 'nps',
      title: t`reports.nps.name`,
      path: '/nps',
      icon: NPSIcon,
      children: [
        {
          title: t`reports.npsDashboard.name`,
          path: '/nps/painel',
          icon: MessageIcon
        },
        {
          title: t`reports.comments.name`,
          path: '/nps/comentarios',
          icon: MessageIcon
        }
      ]
    }
  ];

  render() {
    return <Menu menuMap={this.menuMap} />;
  }
}

export default MenuMapProvider;
