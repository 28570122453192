import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../../app/config';
import {checkNotificationStatus} from '../notifications/actions/NotificationsActions';
import {REGISTER_HOLIDAY, PENDING_HOLIDAY_STORES} from '../notifications/util/notificationTypes';
import {clear} from './actions';
import HolidayActivityComponent from './components/holiday/HolidayActivityComponent';
import HolidayRegisterComponent from './components/holiday/HolidayRegisterComponent';

class NotificationActionComponent extends React.Component {
  componentWillUnmount() {
    this.props.clear();
  }

  render() {
    const {
      notificationActions: {action},
      clear,
      isMobile,
      checkNotificationStatus
    } = this.props;

    switch (action) {
      case REGISTER_HOLIDAY:
        return <HolidayRegisterComponent isMobile={isMobile} clear={clear} checkNotificationStatus={checkNotificationStatus} />;
      case PENDING_HOLIDAY_STORES:
        return <HolidayActivityComponent isMobile={isMobile} clear={clear} checkNotificationStatus={checkNotificationStatus} />;
      default:
        return <div />;
    }
  }
}

NotificationActionComponent.propTypes = {
  notificationActions: PropTypes.shape({
    action: PropTypes.string
  }).isRequired,
  clear: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  checkNotificationStatus: PropTypes.func.isRequired
};

export default connect(state => ({notificationActions: {...state.notificationActions}, isMobile: isMobile(state)}), {checkNotificationStatus, clear})(NotificationActionComponent);
