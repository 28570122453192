import {t} from '@lingui/macro';
import {getUnixTime} from 'date-fns';

import {getDateFns, parseDate} from '../../../app/dateFns';
import {showError} from '../../../toast/toastAction';
import service from '../AdminFilterService';
import * as actions from './AdminFilterActionsTypes';

export const setFilters = params => dispatcher => dispatcher({type: actions.SET_FILTER, payload: params});
export const removeFilters = () => dispatcher => dispatcher({type: actions.CLEAR_FILTER});

export const loadFilters = () => async dispatcher => {
  loadDREDate()(dispatcher);
  loadFiliais()(dispatcher);
  loadRegionais()(dispatcher);
  loadBandeiras()(dispatcher);
};

export const loadFiliais = () => async dispatcher => {
  dispatcher({type: actions.LOAD_FILIAL_REQUEST});
  try {
    const payload = await service.loadStores();
    dispatcher({type: actions.LOAD_FILIAL_SUCCESS, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.filter.stores`)(dispatcher);
    dispatcher({type: actions.LOAD_FILIAL_FAILURE});
  }
};

export const loadRegionais = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REGIONAL_REQUEST});
  try {
    const payload = await service.loadRegionais();
    dispatcher({type: actions.LOAD_REGIONAL_SUCCESS, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.filter.regionalManagers`)(dispatcher);
    dispatcher({type: actions.LOAD_REGIONAL_FAILURE});
  }
};

export const loadBandeiras = () => async dispatcher => {
  dispatcher({type: actions.LOAD_BANDEIRA_REQUEST});
  try {
    const payload = await service.loadFlags();
    dispatcher({type: actions.LOAD_BANDEIRA_SUCCESS, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.filter.flags`)(dispatcher);
    dispatcher({type: actions.LOAD_BANDEIRA_FAILURE});
  }
};

export const loadDREDate = () => async dispatcher => {
  dispatcher({type: actions.LOAD_DREDATE_REQUEST});
  try {
    const response = await service.getDREDate();
    let payload = getUnixTime(getDateFns());
    if (response) {
      payload = getUnixTime(parseDate(response));
    }
    dispatcher({type: actions.LOAD_DREDATE_SUCCESS, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.filter.DREDate`)(dispatcher);
    dispatcher({type: actions.LOAD_DREDATE_FAILURE});
  }
};
