import {t} from '@lingui/macro';
import React, {Component} from 'react';

import tudoavUrlSettings from '../tudoav/tudoavUrl';
import Breadcrumb from './navbar/BreadCrumb';

class BreadCrumbProvider extends Component {
  groups = {
    filial: {
      path: 'filial',
      label: t`common.stores`,
      drillDown: true,
      permission: ['STORES']
    },
    bandeira: {
      path: 'bandeira',
      label: t`common.flags`,
      drillDown: true,
      permission: ['FLAGS']
    },
    regional: {
      path: 'regional',
      label: t`common.regionalManagers`,
      drillDown: true,
      permission: ['REGIONAL_MANAGERS']
    },
    cidade: {
      path: 'cidade',
      label: t`common.cities`,
      drillDown: true,
      permission: ['CITIES']
    },
    estado: {
      path: 'estado',
      label: t`common.states`,
      drillDown: true,
      permission: ['STATES']
    },
    familia: {
      path: 'familia',
      label: t`common.families`,
      drillDown: true
      // permission: ['FAMILY']
    },
    departamento: {
      path: 'departamento',
      label: t`common.departments`,
      drillDown: false
      // permission: ['DEPARTMENT']
    }
  };

  combineGroups = list => list.map(i => this.groups[i]);

  siteMap = {
    path: '/',
    permission: ['SIGA'],
    label: 'Siga',
    selectable: false,
    children: [
      {
        path: 'ranking',
        label: t`reports.ranking.name`,
        selectable: false,
        permission: ['SIGA'],
        children: [
          {
            path: 'comparativo',
            label: t`reports.comparative.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado', 'familia'])
          },
          {
            path: 'familias',
            label: t`reports.families.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          },
          {
            path: 'pdvmovel',
            label: t`reports.mobilePOS.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          }
        ]
      },
      {
        path: 'compromissos',
        label: t`reports.goals.name`,
        permission: ['SIGA'],
        selectable: true,
        groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
      },
      {
        path: 'operacional',
        label: t`reports.operations.name`,
        permission: ['SIGA'],
        selectable: false,
        children: [
          {
            path: 'compras-dia-a-dia',
            label: t`reports.dayToDayShopping.name`,
            selectable: false,
            children: [
              {
                path: 'estoque-em-pecas',
                label: t`reports.stockInPieces.name`,
                selectable: true,
                groups: this.combineGroups(['familia', 'departamento', 'filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: 'estoque-em-valor',
                label: t`reports.stockInValue.name`,
                selectable: true,
                groups: this.combineGroups(['familia', 'departamento'])
              },
              {
                path: 'estoque-por-filial-valor',
                label: t`reports.stockByStoreValue.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: 'estoque-por-filial-pecas',
                label: t`reports.stockByStorePieces.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: 'shares',
                label: t`reports.shares.name`,
                selectable: true,
                groups: []
              }
            ]
          },
          {
            path: 'escala',
            label: t`reports.schedule.name`,
            selectable: false,
            children: [
              {
                path: 'painel',
                label: t`reports.schedule.dashboard`,
                selectable: true,
                groups: this.combineGroups(['filial', 'regional'])
              },
              {
                path: 'preenchimento',
                label: t`reports.schedule.fillForm`,
                selectable: true,
                groups: []
              }
            ]
          },

          {
            path: 'horario-lojas',
            label: t`reports.storeHour.name`,
            permission: ['SIGA'],
            selectable: true
          },
          {
            path: 'painel-v2',
            label: t`reports.salesDashboardV2.name`,
            selectable: true
          },
          {
            path: 'painel',
            label: t`reports.salesDashboard.name`,
            selectable: true
          },
          {
            path: 'pontodepartida',
            label: t`reports.startingPoint.name`,
            selectable: true
          },
          {
            path: 'produtividade',
            label: t`reports.productivity.name`,
            selectable: false,
            children: [
              {
                path: 'funcionarios',
                label: t`reports.employees.name`,
                selectable: true,
                groups: []
              },
              {
                path: 'lotacionograma',
                label: t`reports.lotacionogram.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              }
            ]
          },
          {
            path: 'produtos-financeiros',
            label: t`reports.financialProducts.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          },
          {
            path: 'prova-pop',
            label: t`reports.popQuiz.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'regional'])
          },
          {
            path: 'resumo-feriados',
            label: t`reports.holidaysSummary.name`,
            selectable: true,
            addItemToBreadCrumb: true
          },
          {
            path: 'top-vendas',
            label: t`reports.topSale.name`,
            selectable: true,
            groups: []
          },
          {
            path: 'tudoav',
            label: t`reports.tudoAv.name`,
            selectable: false,
            addItemToBreadCrumb: true,
            children: [
              {
                path: tudoavUrlSettings.routes.monthlyChart,
                label: t`reports.tudoAv.monthlyChart.name`,
                selectable: true
              },
              {
                path: tudoavUrlSettings.routes.averageTicket,
                label: t`reports.tudoAv.averageTicket.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: tudoavUrlSettings.routes.transactions,
                label: t`reports.tudoAv.transactions.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: tudoavUrlSettings.routes.registrations,
                label: t`reports.tudoAv.registrations.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              }
            ]
          },
          {
            path: 'vendas-dia-a-dia',
            label: t`reports.dayToDaySales.name`,
            selectable: false,
            children: [
              {
                path: 'compromisso',
                label: t`reports.saleGoal.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: 'compromisso-diario',
                label: t`reports.dailySaleGoal.name`,
                selectable: true,
                groups: []
              },
              {
                path: 'projecoes',
                label: t`reports.projections.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              },
              {
                path: 'venda',
                label: t`reports.dailySales.name`,
                selectable: true,
                groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
              }
            ]
          },
          {
            path: 'area-venda',
            label: t`reports.saleByArea.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          }
        ]
      },
      {
        path: 'produtos-financeiros',
        label: t`reports.financialProducts.name`,
        permission: ['SIGA'],
        selectable: false,
        children: [
          {
            path: 'lojas-novas',
            label: t`reports.newStores.name`,
            selectable: true
          }
        ]
      },
      {
        path: 'dre',
        label: t`reports.dre.name`,
        permission: ['SIGA'],
        selectable: false,
        children: [
          {
            path: 'analitico',
            label: t`reports.analytical.name`,
            selectable: true
          },
          {
            path: 'bsc',
            label: t`reports.bsc.name`,
            selectable: true
          },
          {
            path: 'dre',
            label: t`reports.dre.name`,
            selectable: true
          },
          {
            path: 'ranking-de-custos',
            label: t`reports.costsRanking.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          }
        ]
      },
      {
        path: 'nps',
        label: t`reports.nps.name`,
        selectable: false,
        permission: ['SIGA'],
        children: [
          {
            path: 'painel',
            label: t`reports.npsDashboard.name`,
            selectable: true,
            groups: this.combineGroups(['filial', 'bandeira', 'regional', 'cidade', 'estado'])
          },
          {
            path: 'comentarios',
            label: t`reports.comments.name`,
            selectable: true
          }
        ]
      }
    ]
  };

  render() {
    return <Breadcrumb siteMap={this.siteMap} />;
  }
}

export default BreadCrumbProvider;
