import Day from '@material-ui/pickers/views/Calendar/Day';
import {getUnixTime, isAfter, isSameDay, startOfDay, subDays} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import {formatDateFns, getDateFns, isPastMonth} from '../../../../dateFns';
import ComparativeDate from './ComparativeDate';
import DateComponent from './components/DateComponent';

const AccumulatedFilter = props => {
  const {
    dateFilter: {auxDate, customComparativeDate, date, format},
    updateState
  } = props;
  const maxDate = startOfDay(subDays(getDateFns(), 1));

  const renderDay = (date, selectedDate, currentMonth) => {
    if (isPastMonth(selectedDate)) {
      if (formatDateFns(date, 'yyyy-MM') === formatDateFns(selectedDate, 'yyyy-MM')) {
        return <Day selected>{formatDateFns(date, 'd')}</Day>;
      }
    }
    const disabled = isAfter(date, maxDate);
    const selected = isSameDay(date, selectedDate);
    return (
      <Day disabled={disabled} hidden={!currentMonth} selected={selected}>
        {formatDateFns(date, 'd')}
      </Day>
    );
  };

  return (
    <React.Fragment>
      <DateComponent format={format} maxDate={getUnixTime(maxDate)} onChange={updateState('currentDate')} renderDay={renderDay} value={date} />
      <ComparativeDate customComparativeDate={customComparativeDate} onChange={updateState('comparativeDate')} onChangeCustomComparativeDate={updateState('customComparativeDate')} value={auxDate} />
    </React.Fragment>
  );
};

AccumulatedFilter.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  updateState: PropTypes.func.isRequired
};

export default AccumulatedFilter;
