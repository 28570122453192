import {getUnixTime, startOfMonth} from 'date-fns';

import {getDateFns} from '../../app/dateFns';
import * as actions from './actions/AdminFilterActionsTypes';

const currentDateTime = getDateFns();

const intialState = {
  currentDate: getUnixTime(startOfMonth(currentDateTime)),
  dreDate: null,
  loadingDREDate: false,
  filiais: {
    entries: [],
    loading: false
  },
  bandeiras: {
    entries: [],
    loading: false
  },
  regionais: {
    entries: [],
    loading: false
  }
};

export default function(state = intialState, action) {
  switch (action.type) {
    case actions.SET_FILTER:
      return {...state, ...action.payload};
    case actions.CLEAR_FILTER:
      return {...state, ...intialState};
    case actions.LOAD_FILIAL_REQUEST:
      return {...state, filiais: {loading: true}};
    case actions.LOAD_REGIONAL_REQUEST:
      return {...state, regionais: {loading: true}};
    case actions.LOAD_BANDEIRA_REQUEST:
      return {...state, bandeiras: {loading: true}};
    case actions.LOAD_FILIAL_SUCCESS:
      return {...state, filiais: {entries: action.payload, loading: false}};
    case actions.LOAD_REGIONAL_SUCCESS:
      return {...state, regionais: {entries: action.payload, loading: false}};
    case actions.LOAD_BANDEIRA_SUCCESS:
      return {...state, bandeiras: {entries: action.payload, loading: false}};
    case actions.LOAD_FILIAL_FAILURE:
      return {...state, filiais: {loading: false}};
    case actions.LOAD_REGIONAL_FAILURE:
      return {...state, regionais: {loading: false}};
    case actions.LOAD_BANDEIRA_FAILURE:
      return {...state, bandeiras: {loading: false}};
    case actions.LOAD_DREDATE_REQUEST:
      return {...state, loadingDREDate: true};
    case actions.LOAD_DREDATE_SUCCESS:
      return {...state, dreDate: action.payload, loadingDREDate: false};
    case actions.LOAD_DREDATE_FAILURE:
      return {...state, loadingDREDate: false};
    default:
      return state;
  }
}
