import {get} from 'lodash';

import CacheStorage from '../../../app/CacheStorage';
import {ACTION_FONT_WEIGHT, ACTION_FILTER_COLUMN, ACTION_HIDDEN_COLLECTION} from './TableActionsTypes';

const hiddenCollectionCache = new CacheStorage('table-hidden-collections');

const initialState = {
  lineWeightClass: '',
  filteredColumns: [],
  hiddenCollections: {
    ...hiddenCollectionCache.data
  }
};

export default (state = initialState, action) => {
  let hiddenCollections;
  switch (action.type) {
    case ACTION_FONT_WEIGHT:
      return {...state, lineWeightClass: get(action, 'payload', '')};
    case ACTION_FILTER_COLUMN:
      return {...state, filteredColumns: {...state.filteredColumns, [get(action, 'payload.key', '')]: get(action, 'payload.columns', [])}};
    case ACTION_HIDDEN_COLLECTION:
      hiddenCollections = get(action, 'payload', {});
      hiddenCollectionCache.update(hiddenCollections);
      return {...state, hiddenCollections: hiddenCollections};
    default:
      return state;
  }
};
