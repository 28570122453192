const resolveFlagLabel = path => {
  switch (path) {
    case 'ac':
      return 'AV Calçados';
    case 'a':
      return 'Avenida';
    case 'e':
      return 'E-commerce';
    default:
      return path;
  }
};

export {resolveFlagLabel};
export default resolveFlagLabel;
