import {connectRouter} from 'connected-react-router';
import {combineReducers} from 'redux';

import adminReducers from '../admin/reducers';
import configuration from '../app/config/ConfigurationReducer';
import exp from '../app/export/ExportReducer';
import guidedVisit from '../app/guidedvisit/loader/reducer';
import notification from '../app/notifications/reducer/NotificationsReducer';
import notificationActions from '../app/notificationUserActions/reducer/reducer';
import print from '../app/print/PrintReducer';
import tableActions from '../component/table/reducer/TableActionsReducer';
import UserReducer from '../login/reducer/UserReducer';
import releaseNotes from '../releaseNotes/releaseNotesAlert/reducer';
import toast from '../toast/toastReducer';
import filter from './filters/FilterReducer';
import navbar from './navbar/NavBarReducer';
import userPreferences from './userPreferences/reducer/UserPreferencesReducer';

// uma função para receber history
// e criar um redutor raiz
export default history =>
  combineReducers({
    router: connectRouter(history),
    user: UserReducer,
    filter,
    toast,
    print,
    export: exp,
    navbar,
    configuration,
    tableActions,
    notification,
    notificationActions,
    userPreferences,
    guidedVisit,
    releaseNotes,
    ...adminReducers
  });
