import ReactFlagsSelect from 'react-flags-select';
import styled from 'styled-components';

const CustomFlagSelect = styled(ReactFlagsSelect)`
  span {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    font-family: ${({theme}) => theme.fonts.fontSecondary};
    color: #646464;
    &::after {
      display: none;
    }
    svg {
      font-size: 20px;
      padding-bottom: 5px;
    }
  }
  button {
    display: block;
    width: 100%;
    margin: 0;
    padding: ${({theme}) => theme.spacing(1)}px;
    &::after {
      display: none !important;
    }
    :hover {
      background-color: ${({theme}) => theme.colors.primary.main};
    }
  }
  ul {
    position: absolute;
    display: inline-block;
    justify-content: center;
    align-items: center;
    border: none;
    box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
    background: ${({theme}) => theme.colors.neutral.white};
    top: 60px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 180px;
    border-radius: 5px;
    span {
      display: inline-block;
      align-items: center;
      justify-content: left;
      svg {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-bottom: 0px;
      }
    }
    li {
      margin: 0;
      padding: ${({theme}) => theme.spacing(1.5) + 'px ' + theme.spacing(2) + 'px'};
      span {
        display: flex;
        flex-direction: row;
        color: ${({theme}) => theme.colors.neutral.black}DE;
      }
      &:hover {
        background: ${({theme}) => theme.colors.neutral.level10};
      }
    }
  }
`;

const CustomFlagItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-width: 130px;
  font-family: ${({theme}) => theme.fonts.fontSecondary};
  font-weight: 400;
  line-height: 1.5;
  span {
    margin: 0 8px;
  }
  svg {
    align-items: left;
    margin-left: auto;
    margin-right: 0;
    fill: #646464;
  }
`;

export {CustomFlagSelect, CustomFlagItem};
