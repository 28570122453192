import {t} from '@lingui/macro';
import StatusIcon from '@material-ui/icons/Toc';
import PropTypes from 'prop-types';
import React from 'react';

import AbstractFilter from './AbstractFilter';

export const getStatusList = () => {
  return [
    {id: 'NAO_SE_APLICA', name: t`nps.comment.nonApplicable`, type: 'statusList'},
    {id: 'NAO_TRATADO', name: t`nps.comment.unresolved`, type: 'statusList'},
    {id: 'TRATADO', name: t`nps.comment.resolved`, type: 'statusList'}
  ];
};

const StatusFilter = props => {
  const {handleChange, selected, visible} = props;
  if (!visible) return null;
  return <AbstractFilter selected={selected} handleChange={handleChange} options={getStatusList()} icon={<StatusIcon />} title={t`common.status`} />;
};

StatusFilter.propTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

export default StatusFilter;
