import {Trans} from '@lingui/react';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/icons/GetApp';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactGA from 'react-ga4';

import NavbarContext from '../../context/NavbarContext';

class ExcelButton extends Component {
  static contextType = NavbarContext;

  doExport = () => {
    this.context.excel && this.context.excel();
    this.props.onClick && this.props.onClick();
    ReactGA.event({
      category: 'Excel',
      action: 'Click to export to excel'
    });
  };

  render() {
    const {classes} = this.props;
    return (
      <MenuItem onClick={this.doExport}>
        <div className={classes.menuItemText}>
          <Trans id="topbar.actions.excel">Exportar Excel</Trans>
        </div>
        <Icon className={classes.menuItemIcon} />
      </MenuItem>
    );
  }
}

ExcelButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func
};

export default ExcelButton;
