import {endOfYesterday, getUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../component/DatePicker';

const DREDateFilter = props => {
  const {
    dateFilter: {date, format},
    disabled,
    form,
    onChange,
    sideFilter,
    title
  } = props;
  const subCurrentDate = endOfYesterday();

  if (form) {
    return (
      <DatePicker variant="form" disabled={disabled} format={format} maxDate={getUnixTime(subCurrentDate)} monthly onChange={onChange('dreDate')} sideFilter={sideFilter} title={title} value={date} />
    );
  }

  return <DatePicker monthly variant="button" disabled={disabled} format={format} maxDate={getUnixTime(subCurrentDate)} onChange={onChange('dreDate')} sideFilter={sideFilter} value={date} />;
};

DREDateFilter.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sideFilter: PropTypes.bool,
  title: PropTypes.string
};

export default DREDateFilter;
