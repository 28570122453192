import {Plural, t} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

class TimeButton extends React.Component {
  render() {
    const {classes, data, onClick, selected} = this.props;
    const isSelected = selected && 'selected';

    const handleClick = () => {
      onClick(data);
    };

    return (
      <div className={classnames(classes.container, isSelected)} onClick={handleClick}>
        <div className={classes.spaceContainer}>
          <span className={classnames(classes.text, isSelected)}>
            <Plural value={data} zero={t`topbar.autoUpdate.disabledOption`} other={`# ${t`topbar.autoUpdate.minutes`}`} />
          </span>
        </div>
      </div>
    );
  }
}

const styles = theme => ({
  container: {
    width: '100%',
    height: '50px',
    cursor: 'pointer',
    '&:last-child': {
      marginBottom: theme.spacing(5)
    },
    '&:hover': {
      backgroundColor: theme.colors.neutral.level10
    },
    '&.selected': {
      backgroundColor: theme.colors.primary.main
    }
  },
  spaceContainer: {
    marginLeft: theme.spacing(3),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
    marginRight: theme.spacing(3),
    alignItems: 'flex-start',
    width: `calc(100% - ${theme.spacing(6)})`,
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.neutral.level9
  },
  text: {
    fontSize: 16,
    fontWeight: 400,
    fontFamily: theme.fonts.fontSecondary,
    color: theme.colors.neutral.level3,
    '&.selected': {
      color: theme.colors.secondary.main
    }
  }
});

TimeButton.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  classes: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  data: PropTypes.object,
  selected: PropTypes.bool
};

export default withStyles(styles)(TimeButton);
