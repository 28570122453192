import {t} from '@lingui/macro';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../styles/FilterStyles';

const CheckboxesFilters = props => {
  const {classes, comparable, enableComparable, enablePhoneSales, enableShowClosedStores, enableEcommerce, ignoreMobile, ignoreEcommerce, onChange, showClosedStores, notComparable} = props;

  const handleChange = type => (e, checked) => {
    onChange(type)(checked);
  };

  const handleChangeNegation = type => (e, checked) => {
    onChange(type)(!checked);
  };

  const renderComparables = () => {
    if (!enableComparable) {
      return null;
    }
    return (
      <>
        <FormControlLabel control={<Checkbox checked={!!comparable} color="primary" onChange={handleChange('comparable')} />} label={t`common.filters.options.showComparables`} />
        <FormControlLabel control={<Checkbox checked={!!notComparable} color="primary" onChange={handleChange('notComparable')} />} label={t`common.filters.options.showNonComparables`} />
      </>
    );
  };

  const renderShowClosedStores = () => {
    if (!enableShowClosedStores) {
      return null;
    }
    return <FormControlLabel control={<Checkbox checked={showClosedStores} color="primary" onChange={handleChange('showClosedStores')} />} label={t`common.filters.options.showClosedStores`} />;
  };

  const renderPhoneSales = () => {
    if (!enablePhoneSales) {
      return null;
    }
    return <FormControlLabel control={<Checkbox checked={!ignoreMobile} color="primary" onChange={handleChangeNegation('ignoreMobile')} />} label={t`common.filters.options.showTelephonySales`} />;
  };

  if (!enableComparable && !enablePhoneSales && !enableShowClosedStores) {
    return null;
  }

  const renderEcommerceSales = () => {
    if (!enableEcommerce) {
      return null;
    }
    return <FormControlLabel control={<Checkbox checked={!ignoreEcommerce} color="primary" onChange={handleChangeNegation('ignoreEcommerce')} />} label={t`common.filters.options.showEcommerce`} />;
  };

  return (
    <Card classes={{root: classes.cardPanel}}>
      <CardContent className={classes.filterContent}>
        {renderComparables()}
        {renderShowClosedStores()}
        {renderPhoneSales()}
        {renderEcommerceSales()}
      </CardContent>
    </Card>
  );
};

CheckboxesFilters.propTypes = {
  showClosedStores: PropTypes.bool,
  comparable: PropTypes.bool,
  notComparable: PropTypes.bool,
  ignoreMobile: PropTypes.bool,
  ignoreEcommerce: PropTypes.bool,
  classes: PropTypes.shape({
    cardPanel: PropTypes.string,
    filterContent: PropTypes.string
  }),
  onChange: PropTypes.func.isRequired,
  enableComparable: PropTypes.bool.isRequired,
  enablePhoneSales: PropTypes.bool.isRequired,
  enableShowClosedStores: PropTypes.bool.isRequired,
  enableEcommerce: PropTypes.bool.isRequired
};

export default withStyles(styles)(CheckboxesFilters);
