import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import {withStyles} from '@material-ui/core/styles';
import ArrowDownIcon from '@material-ui/icons/ArrowDropDown';
import classnames from 'classnames';
import {push} from 'connected-react-router';
import {get, compact} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {hasPermission} from '../../login/permissions';

const styles = () => ({
  arrowButton: {
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 0,
    margin: 0
  },
  breadcrumb: {
    color: 'rgba(100, 100, 100, 0.5)',
    fontWeight: 500,
    fontSize: 16,
    position: 'relative',
    bottom: '4px',
    cursor: 'default',
    textTransform: 'none',
    '&:hover': {
      background: 'none',
      color: '#646464',
      cursor: 'pointer'
    },
    '&:last-child': {
      color: '#646464'
    }
  },
  popper: {
    zIndex: 10
  }
});

class BreadCrumbItem extends React.Component {
  static propTypes = {
    item: PropTypes.any.isRequired,
    disabled: PropTypes.bool.isRequired,
    index: PropTypes.any.isRequired,
    stack: PropTypes.any.isRequired,
    siteMap: PropTypes.any.isRequired,
    push: PropTypes.func.isRequired
  };

  state = {
    open: false
  };

  handleToggle = e => {
    e.preventDefault();
    this.setState(state => ({open: !state.open}));
  };

  handleClose = event => {
    if (this.anchorEl.contains(event.target)) {
      return;
    }

    this.setState({open: false});
  };

  handleClick = selectedIndex => {
    const {index: indexItem, stack, siteMap} = this.props;
    const redirectPath = compact(
      stack.map((item, index) => {
        if (index > indexItem) {
          return '';
        }

        if (indexItem === index) {
          let selectedItem = {};
          let isGroup = false;
          let childrenProp = 'children';

          if (indexItem === 0) {
            selectedItem = get(siteMap, `children[${selectedIndex}]`);
          } else {
            let parent = index <= 0 ? siteMap : stack[index - 1];
            isGroup = parent && parent.selectable;

            if (isGroup) {
              childrenProp = 'groups';
            }

            selectedItem = get(stack, `[${indexItem - 1}].${childrenProp}[${selectedIndex}]`);
          }

          const selectedChildren = get(selectedItem, childrenProp, []);

          if (!selectedItem) {
            return '';
          }

          if (selectedItem.selectable || isGroup) {
            return selectedItem.path;
          }

          if (!selectedItem.selectable && !selectedChildren.length) {
            return '';
          }

          return `${selectedItem.path}/${get(selectedChildren, '[0].path')}`;
        }

        if (index < indexItem) {
          return item.path;
        }

        return '';
      })
    ).join('/');

    this.props.push(`/${redirectPath}`);

    this.setState({open: false});
  };

  render() {
    const {classes, item, stack, index, siteMap} = this.props;
    const {open} = this.state;

    if (!item) return;

    const parentItem = index > 0 ? get(stack, `[${index - 1}]`, {}) : siteMap;
    const childrenProp = !parentItem.selectable ? 'children' : 'groups';
    const children = get(parentItem, childrenProp, []);

    return (
      <React.Fragment>
        <Button
          className={classnames(classes.arrowButton, classes.breadcrumb)}
          buttonRef={node => {
            this.anchorEl = node;
          }}
          aria-haspopup="true"
          onClick={this.handleToggle}
          disabled={this.props.disabled}
        >
          <span>{item.label}</span>
          {!!children.length && <ArrowDownIcon />}
        </Button>
        <Popper open={open} anchorEl={this.anchorEl} transition disablePortal className={classes.popper}>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              id="menu-list-grow"
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={this.handleClose}>
                  <MenuList>
                    {children.map((levelOption, levelIndex) => {
                      if (!levelOption.permission || hasPermission(levelOption.permission, false)) {
                        return (
                          <MenuItem key={`${item.path}-${levelIndex}`} onClick={() => this.handleClick(levelIndex)}>
                            {levelOption.label}
                          </MenuItem>
                        );
                      }
                      return null;
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </React.Fragment>
    );
  }
}

BreadCrumbItem.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(null, {push})(withStyles(styles)(BreadCrumbItem));
