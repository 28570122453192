import {i18n} from '@lingui/core';
import {I18nProvider} from '@lingui/react';
import {en, pt} from 'make-plural/plurals';
import PropTypes from 'prop-types';
import React, {useEffect} from 'react';
import {connect} from 'react-redux';

const pluralZero = fn => (n, ord) => (n === 0 ? 'zero' : fn(n, ord));

i18n.loadLocaleData({
  en: {plurals: pluralZero(en)},
  pt: {plurals: pluralZero(pt)}
});

const dynamicActivate = async language => {
  const selectedLanguage = language || 'pt';
  // ESLINT doesn't like parsing string on dynamic import https://stackoverflow.com/a/60593353
  const {messages} = await import('../../locales/' + selectedLanguage + '/messages');
  i18n.load(selectedLanguage, messages);
  i18n.activate(selectedLanguage);
};

const LanguageProvider = ({children, language}) => {
  useEffect(() => dynamicActivate(language), [language]);
  return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};

LanguageProvider.propTypes = {
  children: PropTypes.node,
  language: PropTypes.oneOf(['pt', 'en'])
};

export default connect(state => ({language: state.userPreferences.language}))(LanguageProvider);
