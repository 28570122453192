import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import {size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';

import Loading from '../app/Loading';
import Toast from '../toast/toast';
import {loadFilters} from './filters/actions/AdminFilterActions';
import SideMenu from './menu/SideMenu';
import Topbar from './menu/TopBar';

const styles = theme => ({
  menu: {
    width: 250
  },
  mainContainer: {
    padding: `${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      paddingTop: `${theme.spacing(3)}px`,
      paddingBottom: `${theme.spacing(3)}px`,
      paddingLeft: `${theme.spacing(2)}px`,
      paddingRight: `${theme.spacing(2)}px`
    }
  }
});

class BaseStructure extends React.Component {
  static propTypes = {
    children: PropTypes.any,
    classes: PropTypes.object,
    filterLoaded: PropTypes.bool,
    filterLoading: PropTypes.bool,
    loadFilters: PropTypes.func.isRequired
  };

  state = {
    open: false
  };

  _sendGaEvent() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Side menu admin ' + (!this.state.open ? 'opened' : 'closed')
    });
  }

  toggleOpen = () => {
    this.setState({open: !this.state.open});
    this._sendGaEvent();
  };

  displayDrawer = open => () => {
    this.setState({open: open});
    this._sendGaEvent();
  };

  componentDidMount() {
    if (!this.props.filterLoaded && !this.props.filterLoading) {
      this.props.loadFilters();
    }
  }

  render() {
    const {classes, filterLoaded} = this.props;
    const content = filterLoaded ? this.props.children : <Loading />;
    return (
      <div className="mainRoot">
        <Topbar toggleOpen={this.toggleOpen} open={this.state.open} />
        <SwipeableDrawer open={this.state.open} onClose={this.displayDrawer(false)} onOpen={this.displayDrawer(true)}>
          <SideMenu className={classes.menu} toggleOpen={this.toggleOpen} open={this.state.open} />
        </SwipeableDrawer>
        <div className={classes.mainContainer}>{content}</div>
        <Toast />
      </div>
    );
  }
}

const mapStateToProps = ({adminFilter}) => {
  const filtersAmount = size(adminFilter.bandeiras.entries) + size(adminFilter.filiais.entries) + size(adminFilter.regionais.entries);
  const filterLoading = adminFilter.bandeiras.loading || adminFilter.filiais.loading || adminFilter.regionais.loading;
  return {filterLoaded: filtersAmount > 0, filterLoading};
};

export default connect(mapStateToProps, {loadFilters})(withStyles(styles)(BaseStructure));
