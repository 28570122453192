import Day from '@material-ui/pickers/views/Calendar/Day';
import {isAfter, isSameDay, isSameYear} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../component/DatePicker';
import {getDateFns, formatDateFns} from '../../../../dateFns';
import ComparativeDate from './ComparativeDate';
import DateComponent from './components/DateComponent';
import HourPicker from './components/HourPicker';

const RealtimeDate = props => {
  const {
    dateFilter: {auxDate, customComparativeDate, date, hour},
    sideFilter,
    enable,
    onChange
  } = props;
  const currentDateTime = getDateFns();

  const renderDay = (date, selectedDate, currentMonth) => {
    let disabled = isAfter(date, currentDateTime);
    let selected = isSameDay(date, selectedDate) && isSameYear(date, selectedDate);
    return (
      <Day selected={selected} disabled={disabled} hidden={!currentMonth}>
        {formatDateFns(date, 'd')}
      </Day>
    );
  };

  if (!auxDate && !hour) {
    return <DatePicker variant="button" disableFuture onChange={onChange('realtimeCurrentDate')} renderDay={renderDay} sideFilter={sideFilter} value={date} />;
  } else {
    return (
      <React.Fragment>
        <DateComponent disableFuture onChange={onChange('realtimeCurrentDate')} renderDay={renderDay} value={date} />
        <ComparativeDate
          customComparativeDate={customComparativeDate}
          enable={enable}
          onChange={onChange('realtimeComparativeDate')}
          onChangeCustomComparativeDate={onChange('customComparativeDate')}
          value={auxDate}
        />
        <HourPicker value={hour} onChange={onChange('hour')} />
      </React.Fragment>
    );
  }
};

RealtimeDate.propTypes = {
  comparativeDate: PropTypes.number,
  customComparativeDate: PropTypes.bool,
  enable: PropTypes.bool,
  dateFilter: PropTypes.shape({
    auxDate: PropTypes.number,
    customComparativeDate: PropTypes.bool,
    date: PropTypes.number,
    hour: PropTypes.number
  }),
  locked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sideFilter: PropTypes.bool
};

export default RealtimeDate;
