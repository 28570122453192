export const colors = {
  charts: {
    positive: '#4473C3',
    negative: '#A71337',
    neutral: '#333333',
    contrast: '#D9A918',
    comparative: {
      primary: '#4473C3',
      secondary: '#FF7F14'
    }
  },
  error: {
    contrastText: '#FFF',
    dark: '#D32F2F',
    light: '#E57373',
    main: '#F44336'
  },
  info: {
    light: '#64b5f6',
    main: '#2196f3',
    dark: '#1976d2',
    contrastText: '#fff'
  },
  neutral: {
    black: '#000000',
    level1: '#151515',
    level2: '#333333',
    level3: '#555555',
    level4: '#666666',
    level5: '#707070',
    level6: '#999999',
    level7: '#B5B5B5',
    level8: '#D7D7D7',
    level9: '#EAEAEA',
    level10: '#F2F2F2',
    level11: '#FAFAFA',
    white: '#FFFFFF',
    transparent: 'transparent'
  },
  primary: {
    contrastText: '#2E0D1E',
    dark: '#F2C22F',
    light: '#FFFDEC',
    main: '#FFCC32'
  },
  secondary: {
    contrastText: '#FFF',
    dark: '#0D0308',
    light: '#96858E',
    main: '#2E0D1E'
  },
  success: {
    contrastText: 'rgba(0,0,0,0.87)',
    dark: '#388E3C',
    light: '#81C784',
    main: '#4CAF50'
  }
};
