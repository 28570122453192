import {t} from '@lingui/macro';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import styles from '../../../styles/FilterStyles';
import DateComponent from './components/DateComponent';

class ComparativeDate extends React.Component {
  static propTypes = {
    classes: PropTypes.object,
    currentDate: PropTypes.number,
    customComparativeDate: PropTypes.bool,
    disabled: PropTypes.bool,
    disableCheckbox: PropTypes.bool,
    enable: PropTypes.bool,
    label: PropTypes.string,
    onChange: PropTypes.func,
    onChangeCustomComparativeDate: PropTypes.func,
    value: PropTypes.number
  };
  static defaultProps = {
    enable: false,
    label: t`common.date.lastYear`
  };

  handleDateChange = date => {
    this.props.onChange(date);

    if (!date) {
      ReactGA.event({
        category: 'Filters',
        action: 'Clear comparative date'
      });
    } else {
      ReactGA.event({
        category: 'Filters',
        action: 'Change comparative date',
        label: `${date}`
      });
    }
  };

  handleCustomDate = event => {
    const value = event.target.checked;
    this.props.onChangeCustomComparativeDate(value);
  };

  renderCheckbox = () => {
    const {classes, enable, disableCheckbox} = this.props;
    if (disableCheckbox) {
      return null;
    }
    return (
      <FormControlLabel
        className={classes.checkboxComparativeDate}
        disabled={!enable}
        control={<Checkbox color="primary" onChange={this.handleCustomDate} checked={this.props.customComparativeDate} />}
        label={t`topbar.dateFilter.customComparativeDate`}
      />
    );
  };

  render() {
    if (!this.props.value) return null;
    const {customComparativeDate, disabled, disableCheckbox, enable, label, value} = this.props;
    const isDisabled = disableCheckbox !== true ? !customComparativeDate || !enable : disabled;
    return (
      <div>
        <DateComponent disableFuture disabled={isDisabled} label={label} onChange={this.handleDateChange} value={value} />
        {this.renderCheckbox()}
      </div>
    );
  }
}
export default withStyles(styles)(ComparativeDate);
