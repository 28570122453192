import Button from '@bit/grupo_avenida.components.button';
import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import logoSiga from '../../assets/img/logo_home_siga.svg';

class ErrorMessage extends Component {
  render() {
    const {classes} = this.props;
    return (
      <section className={classes.wrapper}>
        <div className={classes.mainLogoSection}>
          <img src={`${process.env.PUBLIC_URL}/imgs/grupo_avenida_logo.svg`} className={classes.avenidaLogo} alt="Logo Grupo Avenida" />
        </div>
        <div className={classes.contentWrapper}>
          <div className="inputGroup">
            <img src={logoSiga} className={classes.sigaLogo} alt="Logo Siga" />
          </div>
          <div>
            <WarningIcon className={classes.warningIcon} />
          </div>
          <div className={classes.warningMessage}>
            <Typography lineHeight={24} size={14}>
              Ocorreu um erro ao processar a sua solicitação. Por favor, tente novamente.
            </Typography>
          </div>
          <div className={classes.tryAgain}>
            <Button onClick={() => window.location.reload()} size="medium" variant="dark">
              Tentar novamente
            </Button>
          </div>
          <div className={classes.errorMessage}>
            <Typography lineHeight={14} size={12}>
              Caso o problema persista, entre em contato com <b>suporte@grupoavenida.com.br</b>
            </Typography>
          </div>
        </div>
      </section>
    );
  }
}

ErrorMessage.propTypes = {
  classes: PropTypes.object.isRequired
};

const styles = theme => ({
  mainLogoSection: {
    float: 'left',
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  avenidaLogo: {
    width: 'calc(100% - 60px)',
    margin: '5px 0px 50px 0px',
    maxWidth: '450px'
  },
  errorMessage: {
    textAlign: 'center'
  },
  sigaLogo: {
    marginBottom: '20px',
    maxWidth: '150px'
  },
  tryAgain: {
    marginBottom: '50px'
  },
  warningMessage: {
    marginBottom: '20px',
    textAlign: 'center'
  },
  warningIcon: {
    fontSize: '40px',
    color: theme.colors.neutral.level7
  },
  wrapper: {
    position: 'fixed',
    left: 0,
    top: 0,
    float: 'left',
    height: '100%',
    width: '100%',
    background: '#ffcc32',
    display: 'flex !important',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  inputGroup: {
    float: 'left',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '0px 0px 20px 0px'
  },
  contentWrapper: {
    float: 'left',
    display: 'flex',
    width: 'calc(100 % - 120px)',
    maxWidth: '320px',
    padding: '40px',
    marginTop: '20px',
    background: 'white',
    borderRadius: '40px',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    alignItems: 'center',
    justifyContent: 'space-around',
    flexDirection: 'column',
    '-webkit-box-shadow': '0px 0px 10px 4px rgba(0, 0, 0, 0.1)',
    '-moz-box-shadow': '0px 0px 10px 4px rgba(0, 0, 0, 0.1)',
    'box-shadow': '0px 0px 10px 4px rgba(0, 0, 0, 0.1)'
  }
});

export default withStyles(styles)(ErrorMessage);
