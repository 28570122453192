import * as actions from './ExportActionTypes';

const initialState = {
  fileContent: null,
  loading: false,
  filename: 'export.xslx'
};

export default (state = initialState, action = {}) => {
  const {type} = action;
  switch (type) {
    case actions.CLEAR:
      return {...state, ...initialState};
    case actions.DOWNLOAD:
      return {...state, fileContent: action.payload, loading: false};
    case actions.LOADING:
      return {...state, loading: !!action.payload, filename: `${action.payload ?? initialState.filename}`};
    case actions.FAILURE:
      return {...state, loading: false};
    default:
      return state;
  }
};
