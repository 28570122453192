import {SET_PREFERENCES, CLEAR_PREFERENCES, SET_TABLE_COLUMNS, SET_AUTO_UPDATE_INTERVAL, SET_FONT_SIZE, SET_LANGUAGE} from '../action/UserPreferencesActionTypes';

const initialState = {
  hiddenColumns: {},
  autoUpdateInterval: 5,
  fontSize: null,
  language: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PREFERENCES:
      return {...state, ...action.payload};
    case SET_TABLE_COLUMNS:
      return {...state, hiddenColumns: {...state.hiddenColumns, [action.payload.table]: action.payload.columns}};
    case SET_AUTO_UPDATE_INTERVAL:
      return {...state, autoUpdateInterval: action.payload};
    case SET_LANGUAGE:
      return {...state, language: action.payload};
    case SET_FONT_SIZE:
      return {...state, fontSize: action.payload};
    case CLEAR_PREFERENCES:
      return {...state, ...initialState};
    default:
      return state;
  }
};
