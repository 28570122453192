import PropTypes from 'prop-types';
import React from 'react';

import {ACC, BSCDATE_PERIOD, CURRENTDATE, CURRENTDATE_COMPARATIVE, DREDATE, DREDATE_PERIOD, NPSDATE, PERIOD_ACC, REALTIME, REALTIME_COMPARATIVE_HOUR, REALTIME_HOUR, WEEKLY, MONTHLY} from './dateUtil';
import AccumulatedFilter from './fields/AccumulatedFilter';
import BSCFilterByPeriod from './fields/BSCFilterByPeriod';
import CurrentDate from './fields/CurrentDate';
import DREDateFilter from './fields/DREDateFilter';
import DreFilterByPeriod from './fields/DreFilterByPeriod';
import MonthlyDateFilter from './fields/MonthlyDateFilter';
import NPSDateFilter from './fields/NPSDateFilter';
import PeriodAccDateFilter from './fields/PeriodAccDateFilter';
import RealtimeDate from './fields/RealtimeDate';
import WeeklyFilter from './fields/WeeklyFilter';

const ComponentDateType = props => {
  const {dateFilter, sideFilter, updateState} = props;
  switch (dateFilter.id) {
    case ACC:
      return <AccumulatedFilter dateFilter={dateFilter} updateState={updateState} />;
    case BSCDATE_PERIOD:
      return <BSCFilterByPeriod dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case CURRENTDATE:
      return <CurrentDate dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case DREDATE:
      return <DREDateFilter dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case DREDATE_PERIOD:
      return <DreFilterByPeriod dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case NPSDATE:
      return <NPSDateFilter dateFilter={dateFilter} onChange={updateState} />;
    case PERIOD_ACC:
      return <PeriodAccDateFilter dateFilter={dateFilter} onChange={updateState} />;
    case REALTIME:
      return <RealtimeDate dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case REALTIME_HOUR:
      return <RealtimeDate dateFilter={dateFilter} onChange={updateState} />;
    case REALTIME_COMPARATIVE_HOUR:
      return <RealtimeDate dateFilter={dateFilter} enable onChange={updateState} />;
    case WEEKLY:
      return <WeeklyFilter dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case MONTHLY:
      return <MonthlyDateFilter dateFilter={dateFilter} onChange={updateState} sideFilter={sideFilter} />;
    case CURRENTDATE_COMPARATIVE:
    default:
      return <CurrentDate dateFilter={dateFilter} onChange={updateState} />;
  }
};

ComponentDateType.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  sideFilter: PropTypes.bool,
  updateState: PropTypes.func.isRequired
};

export default ComponentDateType;
