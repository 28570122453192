import PropTypes from 'prop-types';
import React from 'react';

import ErrorMessage from './ErrorMessage';
import ErrorService from './ErrorService';

export default class ErrorHandler extends React.Component {
  state = {hasError: false};

  componentDidCatch(error, errorInfo) {
    const currentUserName = localStorage.getItem('name');
    ErrorService.post({
      error: JSON.stringify(error),
      errorInfo: JSON.stringify(errorInfo),
      path: window.location.pathname,
      user: currentUserName
    }).catch(console.error);
  }

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    if (this.state.hasError) {
      return <ErrorMessage />;
    }
    return this.props.children;
  }
}

ErrorHandler.propTypes = {
  children: PropTypes.object.isRequired
};
