import Typography from '@bit/grupo_avenida.components.typography';
import {withStyles} from '@material-ui/core/styles';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateRangeIcon from '@material-ui/icons/DateRange';
import {getHours, getMinutes} from 'date-fns';
import {isEqual} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import {formatDateFns, getDateFns} from '../../../dateFns';
import styles from '../../styles/FilterStyles';

const ButtonDateFilter = props => {
  const {classes, dateFilter, isMobile} = props;
  const {auxDate, date, format, hour, showComparative = true} = dateFilter;
  const currentDateTime = getDateFns();

  const renderDate = () => {
    if (date) {
      if (isMobile) {
        return <div>{formatDateFns(date, format)}</div>;
      }
      return formatDateFns(date, format);
    }
    return '';
  };

  const renderComparative = () => {
    if (auxDate && showComparative) {
      if (isMobile) {
        return <div>{formatDateFns(auxDate, format)}</div>;
      }
      return ` - ${formatDateFns(auxDate, format)}`;
    }
    return '';
  };

  const parsedHour = () => {
    let parsedHour = `0${hour}`.slice(-2);
    const isToday = isEqual(formatDateFns(date), formatDateFns(currentDateTime));

    if (hour === getHours(currentDateTime) && isToday) {
      const minute = `0${getMinutes(currentDateTime)}`.slice(-2);
      parsedHour = `${parsedHour}:${minute}`;
    } else {
      parsedHour = `${parsedHour}:59`;
    }
    return parsedHour;
  };

  const renderHour = () => {
    if (hour) {
      return (
        <React.Fragment>
          <AccessTimeIcon className={classes.hourIcon} />
          {parsedHour()}
        </React.Fragment>
      );
    }
    return '';
  };

  return (
    <React.Fragment>
      <DateRangeIcon className={classes.icon} />
      <Typography tag="div" size={isMobile ? 12 : 14} family="fontSecondary" className={isMobile ? classes.mobileButton : classes.button}>
        <Typography size={isMobile ? 12 : 14} family="fontSecondary" className={isMobile ? classes.mobileDates : classes.dates} tag="span">
          {renderDate()}
          {renderComparative()}
        </Typography>
        {renderHour()}
      </Typography>
    </React.Fragment>
  );
};

ButtonDateFilter.propTypes = {
  classes: PropTypes.object,
  dateFilter: PropTypes.object.isRequired,
  disabledTitle: PropTypes.bool,
  isMobile: PropTypes.bool,
  loading: PropTypes.bool
};

export default withStyles(styles)(ButtonDateFilter);
