import {t} from '@lingui/macro';
import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import DepartmentIcon from '../../../styles/icons/department';
import styles from '../styles/FilterStyles';
import AbstractFilter from './AbstractFilter';

class DepartmentsFilter extends React.Component {
  render() {
    const {classes} = this.props;
    if (!this.props.visible) return null;

    return (
      <AbstractFilter
        handleChange={this.props.handleChange}
        icon={<DepartmentIcon className={classes.departmentIcon} />}
        options={this.props.options}
        selected={this.props.selected}
        showSelectAll
        title={t`common.departments`}
      />
    );
  }
}

const mapStateToProps = state => {
  const {filter} = state;
  return {options: filter.departamentos.entries};
};

DepartmentsFilter.propTypes = {
  classes: PropTypes.object,
  handleChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  selected: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

export default connect(mapStateToProps)(withStyles(styles)(DepartmentsFilter));
