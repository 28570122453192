import {CLEAR, SHOW} from './toastActionType';

export const SEVERITY = {
  ERROR: 'error',
  WARNING: 'warning',
  INFO: 'info',
  SUCCESS: 'success'
};

export const showError = message => dispatcher => {
  dispatcher({type: SHOW, severity: SEVERITY.ERROR, payload: message});
};
export const showWarning = message => dispatcher => {
  dispatcher({type: SHOW, severity: SEVERITY.WARNING, payload: message});
};

export const showInfo = message => dispatcher => {
  dispatcher({type: SHOW, severity: SEVERITY.INFO, payload: message});
};

export const showSuccess = message => dispatcher => {
  dispatcher({type: SHOW, severity: SEVERITY.SUCCESS, payload: message});
};

export const clear = () => dispatcher => dispatcher({type: CLEAR});
