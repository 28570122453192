import {datadogRum} from '@datadog/browser-rum';
import {toInteger, omit, get} from 'lodash';

const shouldTrack = () => {
  // Não faz tracking no localhost
  return process.env.NODE_ENV !== 'development';
};
const getEnvName = () => {
  return new RegExp(/devsiga/gi).test(window.location.hostname) ? 'qa' : 'prod';
};

const resourceIgnorePattern = new RegExp(/(google-analytics\.com|fonts\.gstatic\.com|fonts\.googleapis\.com|googletagmanager\.com|\/socket\.io\/)/);

export const init = () => {
  if (!shouldTrack()) return;
  datadogRum.init({
    applicationId: process.env.REACT_APP__DATADOG_APPID,
    clientToken: process.env.REACT_APP__DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'siga',
    env: getEnvName(),
    // Specify a version number to identify the deployed version of your application in Datadog
    version: process.env.REACT_APP_VERSION,
    sessionSampleRate: toInteger(process.env.REACT_APP__DATADOG_SAMPLE_RATE),
    sessionReplaySampleRate: toInteger(process.env.REACT_APP__DATADOG_REPLAY_SAMPLE_RATE),
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackViewsManually: true,
    defaultPrivacyLevel: 'mask-user-input',
    beforeSend: event => {
      if (event.type === 'resource') {
        return !resourceIgnorePattern.test(get(event, 'resource.url'));
      }
      return true;
    }
  });
};

export const trackView = path => {
  datadogRum.startView({name: path});
};

export const trackUser = userInfo => {
  if (!userInfo) {
    datadogRum.clearUser();
    return;
  }
  datadogRum.setUser(omit(userInfo, 'token'));
};
