import BaseService from '../../../service/BaseService';

class UserPreferencesService extends BaseService {
  prefix = 'user/preferences';

  async getPreferences() {
    const payload = await this.get();
    if (payload) {
      if (!payload.language) {
        payload.language = /^pt\b/.test(navigator.language) ? 'pt' : 'en';
      }
    }
    return payload;
  }

  saveHiddenColumns = hiddenColumns => {
    return this.post({hiddenColumns}, `${this.prefix}/hidden_columns`);
  };

  saveAutoUpdateInterval(autoUpdateInterval) {
    //in minutes
    return this.post({autoUpdateInterval}, `${this.prefix}/auto_update_interval`);
  }

  saveFontSize(fontSize) {
    return this.post({fontSize}, `${this.prefix}/font_size`);
  }

  saveLanguage(language) {
    return this.post({language}, `${this.prefix}/language`);
  }
}

export default new UserPreferencesService();
