import {Plural, t} from '@lingui/macro';
import {Trans} from '@lingui/react';
import {SwipeableDrawer} from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import {withStyles} from '@material-ui/core/styles';
import {Close, KeyboardArrowDown, KeyboardArrowUp} from '@material-ui/icons';
import classnames from 'classnames';
import {getUnixTime, getHours, subHours} from 'date-fns';
import {isEqual, isNil} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';
import {connect} from 'react-redux';

import {getDateFns} from '../../app/dateFns';
import {setFilters, setAutoupdate} from '../../app/filters/FilterActions';
import trackPage from '../../app/ga/trackPages';
import history from '../../app/history';
import {dispatchAutoUpdateInterval} from '../../app/userPreferences/action/UserPreferencesAction';
import styles from './styles';
import TimeButton from './timeButton';

class AutoUpdate extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    filter: PropTypes.object.isRequired,
    isMobile: PropTypes.bool.isRequired,
    enabled: PropTypes.bool.isRequired,
    setFilters: PropTypes.func.isRequired,
    setAutoupdate: PropTypes.func.isRequired,
    disableExtraTopbar: PropTypes.bool,
    dispatchAutoUpdateInterval: PropTypes.func.isRequired,
    autoUpdateInterval: PropTypes.number,
    open: PropTypes.bool,
    onClose: PropTypes.func
  };

  static defaultProps = {
    enabled: false
  };

  constructor(props) {
    super(props);
    const {autoUpdateInterval} = props;
    const interval = !isNil(autoUpdateInterval) ? autoUpdateInterval : 5;
    this.state = {
      interval, //minutes
      timeleft: interval * 60, //seconds
      anchorEl: null
    };
  }

  componentDidUpdate(prevProps) {
    const {enabled, autoUpdateInterval} = this.props;

    if (enabled) {
      if (!isNil(autoUpdateInterval) && prevProps.autoUpdateInterval !== autoUpdateInterval && autoUpdateInterval !== this.state.interval) {
        this.setState({interval: autoUpdateInterval}, this.resetTimer);
      } else if (!isEqual(prevProps.filter, this.props.filter)) {
        this.resetTimer();
      }
    }
  }

  componentDidMount() {
    this.resetTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  clearTimer = () => {
    clearInterval(this.intervalId);
    clearInterval(this.timeLeftInterval);
  };

  resetTimer = () => {
    this.clearTimer();
    this.setTimer();
  };

  setTimer = () => {
    const {interval} = this.state;
    const {enabled} = this.props;
    if (enabled && interval) {
      this.setState({timeleft: interval * 60});
      this.intervalId = setInterval(this.updateReport, interval * 60 * 1000);
      this.timeLeftInterval = setInterval(this.updateTimeLeft, 1000);
    }
  };

  updateTimeLeft = () => {
    const {timeleft} = this.state;
    this.setState({timeleft: timeleft - 1});
  };

  updateReport = () => {
    const {preset, realtimeComparativeDate} = this.props.filter;
    const currentDateTime = getDateFns();
    const filter = {
      realtimeCurrentDate: getUnixTime(currentDateTime),
      realtimeComparativeDate,
      hour: getHours(currentDateTime),
      preset: 'custom',
      ms: getUnixTime(currentDateTime), // Objeto para forçar o fetch da API
      realtime: true // Autoupdate só faz sentido em reports "realtime"
    };

    if (preset === 'lastHour') {
      filter.hour = getHours(subHours(currentDateTime, 1));
      filter.preset = preset;
    }

    trackPage(history);
    this.resetTimer();

    this.props.setFilters(filter);
  };

  handleChange = id => {
    this.setState({interval: id}, this.resetTimer);
    this.props.dispatchAutoUpdateInterval(id);
    const {autoupdate} = this.props.filter;
    ReactGA.event({
      category: 'Filters',
      action: 'Toogle autoupdate',
      label: `${!autoupdate}`
    });
    this.closeTimeButton();
  };

  openTimeButton = event => {
    if (!this.props.enabled) return null;
    this.setState({...this.state, anchorEl: event.currentTarget});
  };

  closeTimeButton = () => {
    this.setState({...this.state, anchorEl: null});
  };

  renderTimeLeft = () => {
    const seconds = this.state.timeleft % 60;
    const minutes = Math.floor(this.state.timeleft / 60);
    return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  render() {
    const {classes, isMobile, enabled, disableExtraTopbar} = this.props;
    const isDisabled = !enabled;
    const open = Boolean(this.state.anchorEl);
    const {interval} = this.state;
    const openClass = open && 'enabled';
    const disableClass = !enabled && 'disableClass';

    const timeOptions = [0, 5, 15, 30, 60];

    if (disableExtraTopbar || !enabled) return null;

    const renderAutoUpdateContent = () => {
      return (
        <div className={classes.container}>
          <div className={classes.header}>
            <div className={classes.linha}>
              <h2 className={classes.popOverTitle}>
                <Trans id="topbar.autoUpdate.title">Atualização Automática</Trans>
              </h2>
              <Close className={classes.icon} onClick={this.closeTimeButton}></Close>
            </div>
            <div>
              <p className={classes.popOverSubTitle}>
                <Trans id="topbar.autoUpdate.subTitle">Selecione o período de tempo para que o sistema atualize automaticamente:</Trans>
              </p>
            </div>
          </div>
          <div>
            {timeOptions.map(item => (
              <TimeButton key={item} data={item} onClick={this.handleChange} selected={this.state.interval === item} />
            ))}
          </div>
        </div>
      );
    };

    const renderButton = () => {
      return (
        <span className={classnames(classes.timeCounterLabel, openClass, disableClass)} onClick={this.openTimeButton}>
          <Plural value={interval} zero={t`topbar.autoUpdate.updateDisabled`} other={`${t`topbar.autoUpdate.willUpdateIn`} ${this.renderTimeLeft()}`} />
          {open ? <KeyboardArrowUp className={classes.icon} /> : <KeyboardArrowDown className={classes.icon} />}
        </span>
      );
    };

    return (
      <div className={classnames(isDisabled ? classes.disabled : null)}>
        {renderButton()}
        {isMobile ? (
          <SwipeableDrawer anchor="bottom" open={open} onOpen={this.openTimeButton} onClose={this.closeTimeButton}>
            {renderAutoUpdateContent()}
          </SwipeableDrawer>
        ) : (
          <Popover
            classes={{paper: classes.popOver}}
            open={open}
            onClose={this.closeTimeButton}
            anchorEl={this.state.anchorEl}
            anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
            transformOrigin={{vertical: 'top', horizontal: 'right'}}
          >
            {renderAutoUpdateContent()}
          </Popover>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  filter: {...state.filter},
  autoUpdateInterval: state.userPreferences.autoUpdateInterval
});

const dispatcherProps = {
  setFilters,
  setAutoupdate,
  dispatchAutoUpdateInterval
};
export default connect(mapStateToProps, dispatcherProps)(withStyles(styles)(AutoUpdate));
