import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {logOutUser} from '../../login/actions/LoginActions';
import {isMobile} from '../config';
import NavbarContext from '../context/NavbarContext';
import Filter from '../filters';
import Notifications from '../notifications';
import Breadcrumb from '../siteMap';
import ExtraActions from './ExtraActions';
import LanguageSelector from './LanguageSelector';
import styles from './NavBarStyles';
import UserMenu from './UserMenu';

class NavBar extends React.Component {
  static contextType = NavbarContext;

  static propTypes = {
    admin: PropTypes.bool,
    classes: PropTypes.object,
    isMobile: PropTypes.bool,
    logOutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    variant: PropTypes.string
  };

  logout = () => {
    this.props.logOutUser();
  };

  renderTopbar = () => {
    const {classes, isMobile, user} = this.props;

    // TODO: mover para dentro do Filter
    const filters = this.context.filters;
    const {dateFilter} = filters;

    if (isMobile === true) {
      return (
        <div className={classes.nav}>
          <div className={classes.mobileActionsWrapper}>
            <LanguageSelector isMobile={isMobile} />
            <Filter dateFilter={dateFilter} isMobile={isMobile} filters={filters} disabled={filters.disabled} />
            <Notifications isMobile={isMobile} />
            <ExtraActions isMobile={isMobile} />
            <UserMenu isMobile={isMobile} username={user.name || localStorage.getItem('name') || 'Usuário Avenida'} logout={this.logout} />
          </div>
        </div>
      );
    }

    return (
      <div className={classes.nav}>
        <div className={classes.breadCrumbWrapper}>
          <Breadcrumb />
        </div>
        <div className={classes.actionsWrapper}>
          <LanguageSelector isMobile={isMobile} />
          <Filter dateFilter={dateFilter} filters={filters} disabled={filters.disabled} />
          <Notifications isMobile={isMobile} />
          <ExtraActions isMobile={isMobile} />
          <UserMenu isMobile={isMobile} username={user.name || localStorage.getItem('name') || 'Usuário Avenida'} logout={this.logout} />
        </div>
      </div>
    );
  };

  renderBase = () => {
    const {isMobile} = this.props;
    if (!isMobile) {
      return null;
    }
    return <Breadcrumb />;
  };

  render() {
    const {variant = 'topbar'} = this.props;

    if (variant === 'topbar') {
      return this.renderTopbar();
    }
    return this.renderBase();
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    isMobile: isMobile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: user => dispatch(logOutUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
