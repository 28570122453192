import {withStyles} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import classnames from 'classnames';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../config';
import AbstractBreadCrumb from './AbstractBreadCrumb';

const breadcrumbStyles = theme => ({
  root: {
    padding: 19
  },
  breadMobile: {
    overflow: 'auto',
    whiteSpace: 'nowrap',
    marginTop: `${theme.spacing(3)}px`,
    marginLeft: `${theme.spacing(2)}px`,
    marginRight: `${theme.spacing(2)}px`,
    padding: 0,
    paddingBottom: 8
  },
  wrapper: {
    margin: 0
  },
  svg: {
    position: 'relative',
    top: '4px'
  },
  arrow: {
    color: '#E2E2E2',
    fill: 'currentColor'
  },
  home: {
    color: '#646464',
    marginRight: 8,
    cursor: 'pointer'
  }
});

class BreadCrumb extends AbstractBreadCrumb {
  constructor(props) {
    super(props);
    this.breadMobile = React.createRef();
  }

  static propTypes = {
    isMobile: PropTypes.bool,
    classes: PropTypes.shape({}),
    push: PropTypes.func.isRequired
  };

  componentDidMount() {
    const node = this.breadMobile.current;
    node.scrollTo(1000, 0);
  }
  render() {
    const {classes, isMobile} = this.props;
    return (
      <div ref={this.breadMobile} className={classnames(classes.root, isMobile && classes.breadMobile)} id="breadcrumb">
        <div className={classnames(isMobile ? classes.wrapper : null)}>
          <HomeIcon onClick={() => this.props.push('/')} className={classnames(classes.home, classes.svg)} />
          {this.renderBase()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname,
  isMobile: isMobile(state)
});

const mapActions = {
  push
};

export default connect(mapStateToProps, mapActions)(withStyles(breadcrumbStyles)(BreadCrumb));
