import {isString} from 'lodash';
import querystring from 'querystring';

import api from './api/BaseApi';

class BaseService {
  api = api;

  get(id, prefix = this.prefix) {
    if (id) {
      return api.get(`${prefix}/${id}`);
    }
    return api.get(prefix);
  }

  getBlob(params = {}, prefix = this.prefix, options) {
    const query = isString(params) ? params : querystring.stringify(params);
    return api.getBlob(`${prefix}?${query}`, options);
  }

  post(body, prefix = this.prefix, options) {
    return api.post(prefix, body, options);
  }

  put(id, body, prefix = this.prefix) {
    return api.put(`${prefix}/${id}`, body);
  }

  search(params = {}, prefix = this.prefix, options = {}) {
    const query = isString(params) ? params : querystring.stringify(params);
    return api.get(`${prefix}?${query}`, options);
  }

  update(entity, prefix = this.prefix) {
    return api.put(`${prefix}/${entity.id}`, entity);
  }

  remove(id, prefix = this.prefix) {
    return api.remove(`${prefix}/${id}`);
  }

  delete(body = {}, prefix = this.prefix) {
    return api.remove(prefix, body);
  }

  parseType(type) {
    switch (type) {
      case 'bandeira':
        return 'flags';
      case 'regional':
        return 'regionalManagers';
      case 'cidade':
        return 'cities';
      case 'estado':
        return 'states';
      case 'filial':
        return 'stores';
      case 'departamento':
        return 'departments';
      case 'familia':
        return 'families';
      case 'total':
        return 'total';
      default:
        return '';
    }
  }
}

export default BaseService;
