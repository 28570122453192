import {Check} from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {connect} from 'react-redux';

import {dispatchLanguage} from '../userPreferences/action/UserPreferencesAction';
import {CustomFlagItem, CustomFlagSelect} from './LanguageSelectorStyles.js';

const LanguageSelector = ({language, saveLanguage}) => {
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (language) setSelected(language === 'pt' ? 'BR' : 'US');
  }, [language]);

  const handleLanguage = newLanguage => {
    if (language !== newLanguage) {
      saveLanguage(newLanguage);
    }
  };

  return (
    <CustomFlagSelect
      selected={selected}
      onSelect={code => {
        setSelected(code);
        handleLanguage(code === 'BR' ? 'pt' : 'en');
      }}
      countries={['BR', 'US']}
      showSelectedLabel={true}
      customLabels={{
        BR: {
          primary: 'PT-BR',
          secondary: (
            <CustomFlagItem>
              <span>Português</span>
              {selected === 'BR' && <Check />}
            </CustomFlagItem>
          )
        },
        US: {
          primary: 'EN-US',
          secondary: (
            <CustomFlagItem>
              <span>English</span>
              {selected === 'US' && <Check />}
            </CustomFlagItem>
          )
        }
      }}
      showSecondarySelectedLabel={false}
      showSecondaryOptionLabel={true}
      showOptionLabel={false}
      selectedSize={10}
      className="menu-flags"
    />
  );
};

LanguageSelector.propTypes = {
  saveLanguage: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired
};

const mapStateToProps = state => {
  return {
    language: state.userPreferences.language
  };
};

export default connect(mapStateToProps, {saveLanguage: dispatchLanguage})(LanguageSelector);
