import {t} from '@lingui/macro';
import AreaIcon from '@material-ui/icons/DeviceHub';
import PropTypes from 'prop-types';
import React from 'react';

import AbstractFilter from './AbstractFilter';

export const getAreasList = () => {
  return [
    {id: 'CLUB', name: t`nps.category.CLUB`, type: 'areasList'},
    {id: 'STORE', name: t`nps.category.STORE`, type: 'areasList'},
    {id: 'NONE', name: t`nps.category.NONE`, type: 'areasList'}
  ];
};

const AreasFilter = props => {
  const {handleChange, selected, visible} = props;

  if (!visible) return null;
  return <AbstractFilter selected={selected} handleChange={handleChange} options={getAreasList()} icon={<AreaIcon />} title={t`common.areas`} />;
};

AreasFilter.propTypes = {
  handleChange: PropTypes.func,
  selected: PropTypes.array.isRequired,
  visible: PropTypes.bool.isRequired
};

export default AreasFilter;
