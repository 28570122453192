import SvgIcon from '@material-ui/core/SvgIcon';
import React from 'react';

const topsales = props => {
  return (
    <SvgIcon {...props}>
      <svg version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24">
        <g>
          <path d="M8.6,16.4H6.8v6.1H5.4v-6.1H3.6v-1.2h5.1V16.4z" />
          <path
            d="M14.5,19.5c0,1-0.2,1.7-0.7,2.3c-0.5,0.5-1.1,0.8-1.9,0.8c-0.8,0-1.5-0.3-1.9-0.8c-0.5-0.5-0.7-1.3-0.7-2.3
            v-1.2c0-1,0.2-1.8,0.7-2.4c0.5-0.6,1.1-0.8,1.9-0.8c0.8,0,1.5,0.3,1.9,0.8c0.5,0.6,0.7,1.3,0.7,2.3V19.5z M13,18.2
            c0-0.7-0.1-1.1-0.3-1.5c-0.2-0.3-0.5-0.5-0.9-0.5c-0.4,0-0.7,0.2-0.9,0.5c-0.2,0.3-0.3,0.8-0.3,1.4v1.3c0,0.6,0.1,1.1,0.3,1.4
            c0.2,0.3,0.5,0.5,0.9,0.5c0.4,0,0.7-0.1,0.9-0.4c0.2-0.3,0.3-0.8,0.3-1.4V18.2z"
          />
          <path
            d="M17,19.9v2.6h-1.5v-7.3H18c0.7,0,1.3,0.2,1.7,0.7c0.4,0.4,0.6,1,0.6,1.7c0,0.7-0.2,1.3-0.6,1.7
            c-0.4,0.4-1,0.6-1.8,0.6H17z M17,18.7h1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.2-0.5,0.2-0.8c0-0.4-0.1-0.7-0.2-0.9
            c-0.2-0.2-0.4-0.3-0.6-0.3h-1V18.7z"
          />
        </g>
        <polygon points="16.8,7.5 12,1.6 7.3,7.5 10.3,7.5 10.3,14.4 13.7,14.4 13.7,7.5 " />
      </svg>
    </SvgIcon>
  );
};
export default topsales;
