import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {withStyles} from '@material-ui/core/styles';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import {push} from 'connected-react-router';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {hasPermission} from '../../login/permissions';
import MenuLink from './MenuLink';
import styles from './SideMenuStyles.js';
import {CustomCategory} from './styles';
import {isCurrentPath} from './util';

class MenuItem extends React.Component {
  static propTypes = {
    push: PropTypes.func.isRequired,

    data: PropTypes.shape({
      title: PropTypes.string.isRequired,
      key: PropTypes.string,
      path: PropTypes.string,
      icon: PropTypes.any.isRequired,
      children: PropTypes.array,
      subChildren: PropTypes.array
    }),
    isChild: PropTypes.bool,
    pathname: PropTypes.string,
    classes: PropTypes.shape({
      menuIcon: PropTypes.any,
      menuItem: PropTypes.any,
      nested: PropTypes.any,
      listItem: PropTypes.any,
      spanText: PropTypes.any
    })
  };

  constructor(props) {
    super(props);
    this.state = {
      isOpen: isCurrentPath(props.data.path, props.pathname)
    };
  }

  handleCollapse = () => {
    this.setState({isOpen: !this.state.isOpen});
  };

  renderComponent = item => {
    if (item.permission && !hasPermission(item.permission, false)) {
      return null;
    }

    const {classes, pathname, push} = this.props;

    return <MenuItem classes={classes} data={item} isChild pathname={pathname} push={push} key={item.key + item.path} />;
  };

  renderChildren = data => {
    if (!data) return null;

    return (
      <Collapse in={this.state.isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {map(data, this.renderComponent)}
        </List>
      </Collapse>
    );
  };

  render() {
    const {
      classes,
      isChild,
      data: {children, icon, path, subChildren, title}
    } = this.props;
    const Icon = icon;

    const content = (
      <>
        <ListItemIcon className={classes.menuIcon}>
          <Icon />
        </ListItemIcon>
        <ListItemText primary={title} className={classes.spanText} />
      </>
    );

    if (isChild) {
      return (
        <MenuLink button className={children ? classes.listItem : classes.nested} path={path} subChildren={subChildren}>
          {content}
        </MenuLink>
      );
    }

    const goTo = path => () => this.props.push(path);

    const handleMenuClick = children.length ? this.handleCollapse : goTo(path);

    return (
      <>
        <CustomCategory button className={classes.listItem} open={this.state.isOpen} onClick={handleMenuClick}>
          {content}
          {children.length ? this.state.isOpen ? <ExpandLess /> : <ExpandMore /> : ''}
        </CustomCategory>
        {this.renderChildren(children)}
      </>
    );
  }
}

const mapStateToProps = state => {
  return {pathname: state.router.location.pathname};
};
export default connect(mapStateToProps, {push})(withStyles(styles)(MenuItem));
