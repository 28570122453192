import axios, {CancelToken} from 'axios';
import {debounce, get, set, unset, each} from 'lodash';
import queryString from 'query-string';

import appStore from '../../app/stores';
import {LOGOUT} from '../../login/actions/LoginActionTypes';

let requestsMap = {};

class BaseApi {
  constructor() {
    axios.interceptors.response.use(
      response => response,
      error => {
        const response = error.response;
        const url = get(response, 'config.url', '');
        if (!url.match(/^\/api\/login*/gi)) {
          const status = get(response, 'status');
          if (status === 403) {
            this.logoutUser();
            return null;
          } else if (status === 401) {
            return null;
          }
        }
        return Promise.reject(error);
      }
    );
  }

  logoutUser = debounce(() => {
    appStore.dispatch({type: LOGOUT});
  }, 500);

  async fetch(url, options = {}) {
    const requestOp = {...options};
    requestOp.headers = options.headers || {};

    if (!requestOp.headers['Content-Type']) {
      requestOp.headers['Content-Type'] = 'application/json';
    }

    const {body: requestBody, ...newRequestOp} = requestOp;

    const requestUrl = options.local ? url : `${this.url}/${url}`;
    const responseType = options.blob ? 'blob' : null;

    const parsed = queryString.parseUrl(requestUrl);
    const query = {};
    each(options.uniqueParams, it => {
      set(query, it, get(parsed.query, it, ''));
    });

    const urlId = parsed.url + '?' + queryString.stringify(query);

    this.checkRequest(urlId);

    return await axios({
      url: requestUrl,
      data: requestBody,
      responseType,
      cancelToken: new CancelToken(c => {
        this.setRequest(urlId, c);
      }),
      ...newRequestOp
    })
      .then(response => {
        this.clearRequest(urlId);
        return response ? response.data : null;
      })
      .catch(thrown => {
        if (axios.isCancel(thrown)) {
          throw new Error('cancel');
        }
        this.clearRequest(urlId);
        throw thrown;
      });
  }

  checkRequest(url) {
    const cancel = get(requestsMap, url, '');
    if (cancel) {
      cancel();
      this.clearRequest(url);
    }
  }

  setRequest(url, cancelFuntion) {
    set(requestsMap, url, cancelFuntion);
  }

  clearRequest(url) {
    unset(requestsMap, url);
  }

  getBlob(url, options) {
    const op = {...options, blob: true};
    return this.fetch(url, op);
  }

  get(url, options) {
    return this.fetch(url, options);
  }

  post(url, body, options = {}) {
    return this.fetch(url, {...options, method: 'POST', body});
  }

  put(url, body) {
    return this.fetch(url, {method: 'PUT', body});
  }

  patch(url, body) {
    return this.fetch(url, {method: 'PATCH', body});
  }

  remove(url, body = {}) {
    return this.fetch(url, {method: 'DELETE', body});
  }

  get url() {
    return process.env.REACT_APP_API_URL;
  }
}

export default new BaseApi();
