import {t} from '@lingui/macro';
import StoresIcon from '@material-ui/icons/Store';
import {map} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import AbstractFilter from './AbstractFilter';

class StoresFilter extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired,
    selectedFlags: PropTypes.array.isRequired,
    selectedRegionals: PropTypes.array.isRequired
  };

  state = {
    availableStores: this.props.options
  };

  componentDidMount = () => {
    this.handleFlagAndRegionalFilter();
  };

  componentDidUpdate = prevProps => {
    const {selectedFlags, selectedRegionals, options} = this.props;

    if (selectedFlags !== prevProps.selectedFlags || selectedRegionals !== prevProps.selectedRegionals || options !== prevProps.options) {
      this.handleFlagAndRegionalFilter();
    }
  };

  handleAvailableStores = stores => {
    this.setState({availableStores: stores});
  };

  handleFlagAndRegionalFilter = () => {
    const {selectedFlags, selectedRegionals, selected, options, handleChange} = this.props;

    let newSelectedStores = selected;
    const availableStores = this.checkAvailableStores(options);

    if (selectedFlags.length || selectedRegionals.length) {
      const detailedStoreSelection = options.filter(it => selected.includes(it.id));
      newSelectedStores = map(this.checkAvailableStores(detailedStoreSelection), 'id');
    }

    handleChange(newSelectedStores);
    this.handleAvailableStores(availableStores);
  };

  checkAvailableStores = stores => {
    const {selectedFlags, selectedRegionals} = this.props;

    if (!selectedFlags.length && !selectedRegionals.length) {
      return stores;
    }

    return stores.filter(it => {
      if (selectedFlags.length && selectedRegionals.length) {
        return selectedFlags.includes(it.flag) && selectedRegionals.includes(it.regionalCode);
      } else {
        return selectedFlags.includes(it.flag) || selectedRegionals.includes(it.regionalCode);
      }
    });
  };

  render() {
    if (!this.props.visible) return null;
    const {availableStores} = this.state;

    return <AbstractFilter handleChange={this.props.handleChange} icon={<StoresIcon />} options={availableStores} selected={this.props.selected} showSelectAll title={t`common.stores`} />;
  }
}

const mapStateToProps = state => {
  const {filter} = state;
  return {options: filter.filiais.entries};
};

export default connect(mapStateToProps)(StoresFilter);
