import {Trans} from '@lingui/react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import classname from 'classnames';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';

import Menu from './adminMenuMap';
import styles from './SideMenuStyles.js';

class SideMenu extends Component {
  static propTypes = {
    push: PropTypes.func,
    classes: PropTypes.shape({
      admMenuCompanyLogo: PropTypes.string,
      backToSigaButton: PropTypes.string,
      companyLogo: PropTypes.string,
      drawerHeader: PropTypes.string,
      groupItems: PropTypes.string,
      groupFooter: PropTypes.string,
      listItem: PropTypes.string,
      menuIcon: PropTypes.string,
      SideBar: PropTypes.string,
      version: PropTypes.string,
      versionAdm: PropTypes.string,
      wrapperHeader: PropTypes.string
    }),
    toggleOpen: PropTypes.func
  };

  goTo = path => () => {
    this.props.push(path);
  };

  backToSigaButton() {
    const {classes} = this.props;

    return (
      <Button onClick={this.goTo('/')} className={classes.backToSigaButton}>
        <KeyboardArrowLeftIcon />
        <span>
          <Trans id="admin.sideMenu.backButton">Voltar para o SIGA</Trans>
        </span>
      </Button>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <div className={classname(classes.SideBar, 'sidebar')}>
        <div className={classes.groupItems}>
          <div className={classes.drawerHeader} onClick={this.props.toggleOpen}>
            <div className={classes.wrapperHeader}>
              <img src={`${process.env.PUBLIC_URL}/imgs/grupo_avenida_logo.svg`} className={classes.admMenuCompanyLogo} alt="Avenida Logo" />
            </div>
            <IconButton onClick={this.props.toggleOpen}>
              <KeyboardArrowLeftIcon />
            </IconButton>
          </div>
          <div className={classes.versionAdm}>
            <Trans id="admin.sideMenu.version">Versão</Trans>: {process.env.REACT_APP_VERSION}
          </div>
          <Divider />
          <Menu />
          <Divider />
          {this.backToSigaButton()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {...state.router.location};
};

export default connect(mapStateToProps, {push})(withStyles(styles)(SideMenu));
