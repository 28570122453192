const styles = theme => ({
  timeCounterLabel: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 8px 8px 16px',
    cursor: 'pointer',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: theme.colors.neutral.level8,
    borderRadius: '3px',
    color: theme.colors.neutral.level2,
    fontFamily: theme.fonts.fontSecondary,
    fontSize: '12px',
    '&.enabled': {
      backgroundColor: theme.colors.neutral.level9
    },
    '&.disableClass': {
      cursor: 'unset',
      color: 'unset'
    }
  },
  disabled: {
    color: '#646464a8'
  },
  popOverTitle: {
    margin: 0,
    fontFamily: 'Montserrat',
    fontWeight: 500,
    fontSize: 18,
    color: theme.colors.secondary.main
  },
  popOverSubTitle: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.neutral.level5,
    maxWidth: '272px',
    marginBottom: '0px'
  },
  container: {
    width: '100%',
    cursor: 'default'
  },
  header: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    alignItems: 'flex-start',
    width: `calc(100% - ${theme.spacing(6)})`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: theme.colors.neutral.level9
  },
  icon: {
    fill: theme.colors.neutral.level5,
    cursor: 'pointer'
  },
  linha: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
  },
  popOver: {
    marginTop: '5px'
  },
  buttonEnabled: {
    backgroundColor: theme.colors.neutral.level9
  }
});

export default styles;
