const flexRow = {
  display: 'flex',
  flexDirection: 'row'
};
const flexColumn = {
  display: 'flex',
  flexDirection: 'column'
};
const styles = theme => {
  const {spacing} = theme;
  return {
    mainContainer: {
      ...flexColumn,
      minWidth: 440,
      borderRadius: 5,
      border: '1px solid #EAEAEA'
    },
    mainContainerMobile: {
      ...flexColumn,
      borderRadius: 5,
      border: '1px solid #EAEAEA'
    },
    header: {
      ...flexRow,
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: `${spacing(2)}px ${spacing(3)}px`,
      height: 40,
      backgroundColor: theme.colors.neutral.level10,
      borderRadius: '5px 5px 0 0',
      [theme.breakpoints.down('sm')]: {
        padding: `${spacing(2)}px ${spacing(1)}px`
      }
    },
    content: {
      ...flexColumn,
      justifyContent: 'center',
      minHeight: 525,
      backgroundColor: theme.colors.neutral.white,
      borderRadius: '0 0 5px 5px',
      padding: `${spacing(3)}px ${spacing(3)}px ${spacing(5)}px ${spacing(3)}px`,
      [theme.breakpoints.down('sm')]: {
        padding: `${spacing(3)}px ${spacing(1)}px ${spacing(3)}px ${spacing(1)}px`
      }
    },
    selectWrapper: {
      flexGrow: 1
    },
    buttonWrapper: {
      paddingLeft: theme.spacing(3)
    },
    buttonText: {
      width: '100%'
    },
    editButtonWrapper: {
      ...flexRow,
      justifyContent: 'flex-end',
      alignItems: 'center',
      marginBottom: `${spacing(2)}px`
    },
    editButton: {
      minWidth: 96
    },
    editIcon: {
      color: `${theme.colors.neutral.level5} !important`
    },
    chartsWrapper: {
      flexGrow: 1,
      width: '100%',
      height: '100%'
    },
    singleChartWrapper: {
      ...flexRow,
      justifyContent: 'center',
      alignItems: 'center'
    }
  };
};

export default styles;
