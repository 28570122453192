import {get} from 'lodash';

import {IS_MOBILE} from './ConfigurationActionTypes';

const initialState = {
  isMobile: false
};

const initializeState = () => {
  if (/Mobi/.test(navigator.userAgent) || /^m\./.test(window.location.hostname)) {
    return {...initialState, isMobile: true};
  }

  return initialState;
};

export default (state = initializeState(), action) => {
  switch (action.type) {
    case IS_MOBILE:
      return {...state, isMobile: get(action, 'payload', true)};
    default:
      return state;
  }
};
