import {endOfDay, format, fromUnixTime, getUnixTime, isAfter, isBefore, parse, set, startOfDay, startOfMonth} from 'date-fns';
import {utcToZonedTime} from 'date-fns-tz';
import en from 'date-fns/locale/en-US';
import pt from 'date-fns/locale/pt';
import {split} from 'lodash';

import store from '../app/stores';

export function convertTimezone(date) {
  return utcToZonedTime(date, 'America/Cuiaba');
}

export function getDateFns() {
  return convertTimezone(new Date());
}

export const formatDateFns = (value = getDateFns(), valueFormat = 'dd/MM/yyyy', valueLocale = store.getState().userPreferences.language) => {
  const portugueseSelected = valueLocale === 'pt';
  if (typeof value === 'number') {
    value = fromUnixTime(value);
  }
  return format(value, valueFormat, {locale: portugueseSelected ? pt : en});
};

export const formatRequestDate = (value = getDateFns()) => {
  if (typeof value === 'number') {
    value = fromUnixTime(value);
  }
  return format(value, 'yyyy-MM-dd', {locale: pt});
};

export const fromRequestDate = value => {
  if (!value) return value;
  return getUnixTime(startOfDay(parseDate(split(value, '-'))));
};

export const formatMillisecondsDateFns = (value, valueFormat = 'dd/MM/yyyy') => {
  if (!value) return null;
  value = parse(value, 'T', new Date());
  return formatDateFns(value, valueFormat);
};

// TODO: substituir esta função pelo formatDateFns
export const formatDateFileName = value => {
  if (!value) {
    value = formatRequestDate();
  }
  if (typeof value === 'number') {
    value = formatRequestDate(value);
  }
  return `${value.substring(8)}-${value.substring(7, 5)}-${value.substring(4, 0)}`;
};

export const isPastMonth = value => {
  if (typeof value === 'number') {
    value = fromUnixTime(value);
  }
  return isAfter(startOfMonth(getDateFns()), value);
};

export const parseDate = value => {
  if (!value) return null;
  return set(getDateFns(), {
    year: Number(value[0]),
    month: Number(value[1] - 1),
    date: Number(value[2])
  });
};

export const parseDateTime = value => {
  if (!value) return null;
  return set(getDateFns(), {
    year: Number(value[0]),
    month: Number(value[1] - 1),
    date: Number(value[2]),
    hours: Number(value[3]),
    minutes: Number(value[4]),
    seconds: value[5] ? Number(value[5]) : 0
  });
};

export const parseTime = value => {
  if (!value) return null;
  return set(getDateFns(), {
    hours: Number(value[3]),
    minutes: Number(value[4])
  });
};

const EDGE_DATE_START = endOfDay(parseDate([2021, 2, 28]));
const EDGE_DATE_END = startOfDay(parseDate([2021, 8, 1]));

export const isDuringPandemic = value => {
  if (typeof value === 'number') {
    value = fromUnixTime(value);
  }
  return isAfter(value, EDGE_DATE_START) && isBefore(value, EDGE_DATE_END);
};

// Formato 2022-08-31T11:08
export const formatDateTime = (dateValue, timeValue) => {
  if (!dateValue || !timeValue) return null;
  const parsedDate = formatRequestDate(dateValue);
  return parsedDate + 'T' + timeValue;
};
