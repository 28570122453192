import BaseService from '../../service/BaseService';

class SalesDashboardService extends BaseService {
  prefix = 'dashboard/user/panels';

  getPanels() {
    return this.get();
  }

  getPanelCharts(panelId) {
    return this.get(panelId);
  }

  savePanel(panelData) {
    return this.post(panelData);
  }

  deletePanel(panelId) {
    return this.remove(panelId);
  }
}

export default new SalesDashboardService();
