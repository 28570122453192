const sideMenuColor = '#FFCC32';
const sideBorderColor = '#dcdbdb';

const styles = theme => ({
  SideBar: {
    background: sideMenuColor,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    minWidth: 300
  },
  drawerHeader: {
    alignItems: 'center',
    background: sideMenuColor,
    display: 'flex',
    flexBasis: '70px',
    flexGrow: 0,
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  groupItems: {
    alignContent: 'space-around',
    background: sideMenuColor,
    display: 'flex',
    flexDirection: 'column',
    float: 'left',
    height: '100%',
    justifyContent: 'space-around',
    width: '100%'
  },
  groupFooter: {
    float: 'left',
    margin: '10px 10px',
    width: '100%'
  },
  menuItem: {
    padding: '10px 25px'
  },
  listItem: {
    background: '#FFCC32',
    padding: '13px 16px'
  },
  menuIcon: {
    color: '#636363',
    margin: 0,
    minWidth: 24,
    padding: 0
  },
  spanText: {
    margin: 0,
    paddingLeft: 16,
    paddingRight: 16
  },
  companyLogo: {
    height: '200px',
    margin: '20px 0',
    width: 117
  },
  iconButton: {
    padding: '9px'
  },
  listText: {
    display: 'block',
    width: '100%'
  },
  nested: {
    background: '#FFE491',
    padding: '10px 35px',
    '&:hover': {
      backgroundColor: '#F7DD8D'
    }
  },
  sideFooter: {
    paddingBottom: '18px',
    width: '100%'
  },
  version: {
    alignItems: 'center',
    background: sideMenuColor,
    display: 'flex',
    fontSize: '10px',
    gap: '8px',
    justifyContent: 'flex-end',
    marginTop: '10px',
    marginBottom: '1px',
    paddingRight: '20px',
    textAlign: 'center',
    '& > a': {
      textDecoration: 'none',
      color: 'inherit',
      display: 'flex',
      alignItems: 'center'
    }
  },
  adminSide: {
    background: sideMenuColor,
    marginBottom: '28px',
    textAlign: 'center'
  },
  adminSideButton: {
    color: '#000',
    fontFamily: 'Montserrat',
    fontWeight: 500,
    justifyContent: 'flex-start',
    marginBottom: '27px',
    paddingLeft: '12px',
    width: '100%'
  },
  customAdminButton: {
    marginLeft: '8px'
  },
  identificationText: {
    color: theme.colors.secondary.main,
    fontFamily: 'Montserrat',
    fontSize: '10px',
    fontWeight: 700,
    margin: '16px 0 8px 16px',
    opacity: 0.5,
    textTransform: 'uppercase'
  },
  wrapperSideMenu: {
    flex: 1
  }
});

export default styles;

export {sideMenuColor, sideBorderColor};
