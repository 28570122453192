import {Trans} from '@lingui/react';
import Button from '@material-ui/core/Button';
import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import React from 'react';

import styles from '../styles/FilterStyles';

export default withStyles(styles)(props => {
  const {apply, classes, clear, isMobile} = props;

  return (
    <React.Fragment>
      <Button fullWidth variant="contained" className={classnames([classes.primaryButton, isMobile ? classes.mobilePrimaryButton : ''])} onClick={clear}>
        <Trans id="common.filters.clean">Limpar</Trans>
      </Button>
      <Button fullWidth variant="contained" className={classnames([classes.primaryButton, isMobile ? classes.mobilePrimaryButton : ''])} onClick={apply}>
        <Trans id="common.filters.apply">Aplicar</Trans>
      </Button>
    </React.Fragment>
  );
});
