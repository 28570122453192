import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import Badge from '@material-ui/core/Badge';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import FilterListIcon from '@material-ui/icons/FilterList';
import classnames from 'classnames';
import {getDaysInMonth, getUnixTime, setMonth, startOfMonth, subDays, subMonths, subYears} from 'date-fns';
import {size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {getDateFns} from '../../../app/dateFns';
import {hasPermission} from '../../../login/permissions.js';
import {isMobile} from '../../config';
import AreasFilter from '../component/AreasFilter';
import CategoriesFilter from '../component/CategoriesFilter';
import CheckboxesFilters from '../component/CheckboxesFilters';
import CitiesFilter from '../component/CitiesFilter';
import DateFilter from '../component/dateFilter/DateFilter';
import DepartmentsFilter from '../component/DepartmentsFilter';
import FamiliesFilter from '../component/FamiliesFilter';
import FlagsFilter from '../component/FlagsFilter';
import RegionalManagersFilter from '../component/RegionalManagersFilter';
import StatesFilter from '../component/StatesFilter';
import StatusFilter from '../component/StatusFilter';
import StoresFilter from '../component/StoresFilter';
import StoreTypeFilter from '../component/StoreTypeFilter';
import {setFilters, removeFilters} from '../FilterActions';
import styles from '../styles/FilterStyles';
import NavigationButtons from './NavigationButtons';

class Filter extends React.Component {
  static defaultProps = {
    dateFilter: '',
    filters: {}
  };

  state = {
    areasList: [],
    bscDateBegin: getUnixTime(subYears(setMonth(getDateFns(), 9), 1)),
    bscDateEnd: getUnixTime(setMonth(getDateFns(), 8)),
    categoriesList: [],
    cities: [],
    comparable: true,
    comparativeDate: null,
    currentDate: null,
    customComparativeDate: false,
    dateAccBegin: null,
    dateAccEnd: null,
    dateBegin: null,
    dateEnd: null,
    departments: [],
    dreDate: null,
    dreDateBegin: null,
    dreDateEnd: null,
    families: [],
    hour: null,
    open: false,
    ignoreMobile: false,
    ignoreEcommerce: false,
    notComparable: true,
    realtimeComparativeDate: null,
    realtimeCurrentDate: null,
    regional: [],
    states: [],
    statusList: [],
    stores: [],
    storeType: [],
    showClosedStores: false,
    weeklyDate: null
  };

  constructor(props) {
    const currentDateTime = getDateFns();
    super(props);
    const {
      open = false,
      areasList = [],
      bscDateBegin = getUnixTime(subYears(setMonth(currentDateTime, 9), 1)),
      bscDateEnd = getUnixTime(setMonth(currentDateTime, 8)),
      categoriesList = [],
      cities = [],
      comparable = true,
      comparativeDate = null,
      currentDate = subDays(currentDateTime, 1),
      customComparativeDate = false,
      departments = [],
      dreDate = null,
      dreDateBegin = null,
      dreDateEnd = null,
      families = [],
      flags = [],
      hour = 0,
      ignoreMobile = false,
      ignoreEcommerce = false,
      notComparable = true,
      realtimeCurrentDate = currentDateTime,
      realtimeComparativeDate = null,
      regional = [],
      states = [],
      statusList = [],
      stores = [],
      storeType = [],
      showClosedStores = false,
      weeklyDate = currentDateTime
    } = props;

    this.state = {
      open,
      areasList,
      bscDateBegin,
      bscDateEnd,
      categoriesList,
      cities,
      comparable,
      comparativeDate,
      currentDate,
      customComparativeDate,
      departments,
      dreDate,
      dreDateBegin,
      dreDateEnd,
      families,
      flags,
      hour,
      ignoreMobile,
      ignoreEcommerce,
      notComparable,
      realtimeCurrentDate,
      realtimeComparativeDate,
      regional,
      states,
      statusList,
      stores,
      storeType,
      showClosedStores,
      weeklyDate
    };
  }

  // TODO: remover esse método
  UNSAFE_componentWillReceiveProps(props) {
    const currentDateTime = getDateFns();
    const defaultComparativeDate = getUnixTime(subDays(currentDateTime, 364));
    const {
      areasList = [],
      bscDateBegin = getUnixTime(subYears(setMonth(currentDateTime, 9), 1)),
      bscDateEnd = getUnixTime(setMonth(currentDateTime, 8)),
      categoriesList = [],
      cities = [],
      departments = [],
      families = [],
      flags = [],
      regional = [],
      states = [],
      statusList = [],
      stores = [],
      storeType = [],
      comparativeDate = defaultComparativeDate,
      currentDate = subDays(currentDateTime, 1),
      customComparativeDate = false,
      dreDate = subMonths(currentDateTime, 1),
      dreDateBegin = getUnixTime(subDays(currentDateTime, 1)),
      dreDateEnd = getUnixTime(subDays(currentDateTime, 1)),
      hour = 0,
      dateBegin = getUnixTime(subDays(currentDateTime, getDaysInMonth(currentDateTime))),
      dateEnd = getUnixTime(subDays(currentDateTime, 1)),
      dateAccBegin = getUnixTime(startOfMonth(subDays(currentDateTime, 1))),
      dateAccEnd = getUnixTime(subDays(currentDateTime, 1)),
      realtimeComparativeDate = defaultComparativeDate,
      realtimeCurrentDate = currentDateTime,
      weeklyDate = currentDateTime,
      comparable = true,
      ignoreMobile = false,
      ignoreEcommerce = false,
      notComparable = true,
      showClosedStores = false
    } = props;
    this.setState({
      areasList,
      bscDateBegin,
      bscDateEnd,
      categoriesList,
      cities,
      departments,
      families,
      flags,
      regional,
      states,
      statusList,
      stores,
      storeType,
      comparativeDate,
      currentDate,
      customComparativeDate,
      dreDate,
      dreDateBegin,
      dreDateEnd,
      hour,
      dateBegin,
      dateEnd,
      dateAccBegin,
      dateAccEnd,
      realtimeComparativeDate,
      realtimeCurrentDate,
      weeklyDate,
      comparable,
      ignoreMobile,
      ignoreEcommerce,
      notComparable,
      showClosedStores
    });
  }

  handleClick = () => {
    this.setState({open: true});
  };

  handleClose = () => {
    this.setState({open: false});
  };

  handleChange = type => entries => {
    this.setState({[type]: entries});
  };

  clear = () => {
    this.handleClose();
    this.props.removeFilters();
  };

  apply = () => {
    const currentDateTime = getDateFns();
    const defaultComparativeDate = getUnixTime(subDays(currentDateTime, 364));
    const {
      areasList = [],
      bscDateBegin = getUnixTime(subYears(setMonth(currentDateTime, 9), 1)),
      bscDateEnd = getUnixTime(setMonth(currentDateTime, 8)),
      categoriesList = [],
      cities = [],
      departments = [],
      families = [],
      flags = [],
      regional = [],
      states = [],
      statusList = [],
      stores = [],
      storeType = [],
      comparativeDate = defaultComparativeDate,
      currentDate = subDays(currentDateTime, 1),
      customComparativeDate = false,
      dreDate = subMonths(currentDateTime, 1),
      dreDateBegin = getUnixTime(startOfMonth(subDays(currentDateTime, 1))),
      dreDateEnd = getUnixTime(subDays(currentDateTime, 1)),
      hour = 0,
      dateBegin = getUnixTime(subDays(currentDateTime, getDaysInMonth(currentDateTime))),
      dateEnd = getUnixTime(subDays(currentDateTime, 1)),
      dateAccBegin = getUnixTime(startOfMonth(subDays(currentDateTime, 1))),
      dateAccEnd = getUnixTime(subDays(currentDateTime, 1)),
      realtimeComparativeDate = defaultComparativeDate,
      realtimeCurrentDate = currentDateTime,
      weeklyDate = currentDateTime,
      comparable = true,
      ignoreMobile = false,
      ignoreEcommerce = false,
      notComparable = true,
      showClosedStores = false,
      preset
    } = this.state;

    this.props.setFilters({
      areasList,
      bscDateBegin,
      bscDateEnd,
      categoriesList,
      cities,
      departments,
      families,
      flags,
      regional,
      states,
      statusList,
      stores,
      storeType,
      comparativeDate,
      currentDate,
      customComparativeDate,
      dreDate,
      dreDateBegin,
      dreDateEnd,
      hour,
      dateBegin,
      dateEnd,
      dateAccBegin,
      dateAccEnd,
      realtimeComparativeDate,
      realtimeCurrentDate,
      weeklyDate,
      comparable,
      ignoreMobile,
      ignoreEcommerce,
      notComparable,
      showClosedStores,
      preset
    });
    this.handleClose();
  };

  getSize(shouldCount, list) {
    if (shouldCount !== false) {
      return size(list);
    }
    return 0;
  }

  renderIcon = () => {
    const {classes, filters} = this.props;
    const {
      enableAreas,
      enableCategories,
      enableCities,
      enableComparable,
      enablePhoneSales,
      enableDepartments,
      enableFamilies,
      enableFlags,
      enableRegional,
      enableStates,
      enableStatus,
      enableStores,
      enableStoreType,
      enableEcommerce,
      enableShowClosedStores
    } = filters;
    const {
      areasList,
      categoriesList,
      cities,
      comparable,
      departments,
      families,
      flags,
      ignoreMobile,
      ignoreEcommerce,
      notComparable,
      regional,
      states,
      statusList,
      stores,
      storeType,
      showClosedStores
    } = this.props;
    let filterCount = 0;
    if (enableComparable) {
      if (notComparable === false && comparable !== false) {
        filterCount++;
      } else if (comparable === false) {
        filterCount++;
      }
    }
    if (enablePhoneSales) {
      if (ignoreMobile === true) {
        filterCount++;
      }
    }
    if (enableEcommerce) {
      if (ignoreEcommerce === true) {
        filterCount++;
      }
    }
    if (enableShowClosedStores) {
      if (showClosedStores === true) {
        filterCount++;
      }
    }

    if (enableAreas) filterCount += this.getSize(filters.areasList, areasList);
    if (enableCategories) filterCount += this.getSize(filters.categoriesList, categoriesList);
    if (enableCities) filterCount += this.getSize(filters.cities, cities);
    if (enableDepartments) filterCount += this.getSize(filters.departments, departments);
    if (enableFamilies) filterCount += this.getSize(filters.families, families);
    if (enableFlags) filterCount += this.getSize(filters.flags, flags);
    if (enableRegional) filterCount += this.getSize(filters.regional, regional);
    if (enableStates) filterCount += this.getSize(filters.states, states);
    if (enableStatus) filterCount += this.getSize(filters.statusList, statusList);
    if (enableStores) filterCount += this.getSize(filters.stores, stores);
    if (enableStoreType) filterCount += this.getSize(filters.storeType, storeType);

    if (filterCount > 0) {
      return (
        <Badge badgeContent={filterCount} color="primary" classes={{badge: classes.badge}}>
          <FilterListIcon />
          <span>
            <Trans id="topbar.name.filters">Filtros</Trans>
          </span>
        </Badge>
      );
    }

    return (
      <React.Fragment>
        <FilterListIcon />
        <Trans id="topbar.name.filters">Filtros</Trans>
      </React.Fragment>
    );
  };

  render() {
    const {classes, dateFilter, filters, isMobile, setFilters} = this.props;
    const {
      bscDateBegin,
      bscDateEnd,
      comparativeDate,
      currentDate,
      customComparativeDate,
      dreDate,
      dreDateBegin,
      dreDateEnd,
      hour,
      open,
      dateBegin,
      dateEnd,
      dateAccBegin,
      dateAccEnd,
      realtimeComparativeDate,
      realtimeCurrentDate,
      weeklyDate
    } = this.state;
    const dates = {
      bscDateBegin,
      bscDateEnd,
      comparativeDate,
      currentDate,
      customComparativeDate,
      dreDate,
      dreDateBegin,
      dreDateEnd,
      hour,
      dateBegin,
      dateEnd,
      dateAccBegin,
      dateAccEnd,
      realtimeComparativeDate,
      realtimeCurrentDate,
      weeklyDate
    };
    let loading = this.props.loading;

    return (
      <div className={classes.root} id="topbar-filter-button">
        <IconButton className={classes.buttonCircle} disabled={loading || filters.disabled} aria-label="Filtros" onClick={this.handleClick} variant="contained">
          {this.renderIcon()}
        </IconButton>
        <Drawer
          anchor="right"
          open={open}
          onClose={this.handleClose}
          classes={{
            paper: !isMobile ? classes.drawerPaper : classes.drawerPaperMobile
          }}
        >
          <div className={classnames(classes.filterWrapper, classes.fixedWrapperTop)}>
            <div className={classes.titleWrapper}>
              <FilterListIcon className={classes.titleIcon} />
              <Typography family="fontSecondary" size={20} weight="medium">
                <Trans id="common.filters.title">Filtros</Trans>
              </Typography>
            </div>
            <CloseIcon className={classes.iconCloseFilter} onClick={this.clear} />
          </div>
          <div className={classes.filterCenterWrapper}>
            <DateFilter
              dateFilter={dateFilter}
              filters={dates}
              handleChange={this.handleChange}
              handleClose={this.handleClose}
              setFilters={setFilters}
              enablePresetFilter={filters.enablePresetFilter}
              isMobile={isMobile}
              visible={dateFilter}
            />
            <CheckboxesFilters
              onChange={this.handleChange}
              comparable={this.state.comparable}
              notComparable={this.state.notComparable}
              ignoreMobile={this.state.ignoreMobile}
              ignoreEcommerce={this.state.ignoreEcommerce}
              showClosedStores={this.state.showClosedStores}
              enablePhoneSales={filters.enablePhoneSales}
              enableComparable={filters.enableComparable}
              enableEcommerce={filters.enableEcommerce}
              enableShowClosedStores={filters.enableShowClosedStores}
            />
            <AreasFilter selected={this.state.areasList} handleChange={this.handleChange('areasList')} visible={filters.enableAreas} />
            <CategoriesFilter selected={this.state.categoriesList} handleChange={this.handleChange('categoriesList')} visible={filters.enableCategories} />
            <FlagsFilter selected={this.state.flags} handleChange={this.handleChange('flags')} visible={filters.enableFlags && hasPermission('FLAGS')} />
            <RegionalManagersFilter selected={this.state.regional} handleChange={this.handleChange('regional')} visible={filters.enableRegional} />
            <StatusFilter selected={this.state.statusList} handleChange={this.handleChange('statusList')} visible={filters.enableStatus} />
            <StoresFilter
              selected={this.state.stores}
              selectedFlags={this.state.flags}
              selectedRegionals={this.state.regional}
              handleChange={this.handleChange('stores')}
              visible={filters.enableStores}
            />
            <StoreTypeFilter selected={this.state.storeType} handleChange={this.handleChange('storeType')} visible={filters.enableStoreType} />
            <FamiliesFilter selected={this.state.families} handleChange={this.handleChange('families')} visible={filters.enableFamilies} />
            <DepartmentsFilter selected={this.state.departments} handleChange={this.handleChange('departments')} visible={filters.enableDepartments} />
            <StatesFilter selected={this.state.states} handleChange={this.handleChange('states')} visible={filters.enableStates} />
            <CitiesFilter selected={this.state.cities} handleChange={this.handleChange('cities')} visible={filters.enableCities} />
          </div>
          <div className={classnames(classes.filterWrapper, classes.fixedWrapperBottom)}>
            <NavigationButtons clear={this.clear} apply={this.apply} isMobile={isMobile} />
          </div>
        </Drawer>
      </div>
    );
  }
}

Filter.propTypes = {
  areasList: PropTypes.array,
  bscDateBegin: PropTypes.number,
  bscDateEnd: PropTypes.number,
  categoriesList: PropTypes.array,
  cities: PropTypes.array,
  classes: PropTypes.object,
  comparable: PropTypes.bool,
  comparativeDate: PropTypes.number,
  currentDate: PropTypes.number.isRequired,
  customComparativeDate: PropTypes.bool,
  dateFilter: PropTypes.string,
  departments: PropTypes.array,
  dreDate: PropTypes.number,
  dreDateBegin: PropTypes.number,
  dreDateEnd: PropTypes.number,
  families: PropTypes.array,
  filterLoaded: PropTypes.bool.isRequired,
  filters: PropTypes.object,
  flags: PropTypes.array,
  ignoreMobile: PropTypes.bool,
  ignoreEcommerce: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  hour: PropTypes.number.isRequired,
  loading: PropTypes.bool,
  notComparable: PropTypes.bool,
  open: PropTypes.func,
  dateEnd: PropTypes.number,
  dateBegin: PropTypes.number,
  dateAccEnd: PropTypes.number,
  dateAccBegin: PropTypes.number,
  realtimeComparativeDate: PropTypes.number,
  realtimeCurrentDate: PropTypes.number.isRequired,
  regional: PropTypes.array,
  removeFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired,
  showClosedStores: PropTypes.bool.isRequired,
  states: PropTypes.array,
  statusList: PropTypes.array,
  stores: PropTypes.array,
  storeType: PropTypes.array,
  weeklyDate: PropTypes.number.isRequired
};

const mapStateToProps = state => {
  const {filter} = state;
  const filterLoaded =
    size(filter.bandeiras.entries) ||
    size(filter.cidades.entries) ||
    size(filter.estados.entries) ||
    size(filter.departamentos.entries) ||
    size(filter.familias.entries) ||
    size(filter.filiais.entries) ||
    size(filter.regionais.entries) ||
    size(filter.tipoFilial.entries);
  return {
    areasList: filter.areasList,
    bscDateBegin: filter.bscDateBegin,
    bscDateEnd: filter.bscDateEnd,
    categoriesList: filter.categoriesList,
    cities: filter.cities,
    comparable: filter.comparable,
    comparativeDate: filter.comparativeDate,
    comparativeDateLoading: filter.loading,
    currentDate: filter.currentDate,
    customComparativeDate: filter.customComparativeDate,
    departments: filter.departments,
    dreDate: filter.dreDate,
    dreDateBegin: filter.dreDateBegin,
    dreDateEnd: filter.dreDateEnd,
    families: filter.families,
    filterLoaded: filterLoaded > 0,
    flags: filter.flags,
    hour: filter.hour,
    ignoreMobile: filter.ignoreMobile,
    ignoreEcommerce: filter.ignoreEcommerce,
    isMobile: isMobile(state),
    notComparable: filter.notComparable,
    dateBegin: filter.dateBegin,
    dateEnd: filter.dateEnd,
    dateAccBegin: filter.dateAccBegin,
    dateAccEnd: filter.dateAccEnd,
    realtimeComparativeDate: filter.realtimeComparativeDate,
    realtimeCurrentDate: filter.realtimeCurrentDate,
    regional: filter.regional,
    showClosedStores: filter.showClosedStores,
    states: filter.states,
    statusList: filter.statusList,
    stores: filter.stores,
    storeType: filter.storeType,
    weeklyDate: filter.weeklyDate
  };
};

export default connect(mapStateToProps, {
  removeFilters,
  setFilters
})(withStyles(styles)(Filter));
