import React from 'react';

class Arrow extends React.Component {
  render() {
    return (
      <svg width="24px" height="24px" {...this.props} >
        <g id="Rounded">
          <path d="M7.38,21.01L7.38,21.01c0.49,0.49,1.28,0.49,1.77,0l8.31-8.31c0.39-0.39,0.39-1.02,0-1.41L9.15,2.98
            c-0.49-0.49-1.28-0.49-1.77,0l0,0c-0.49,0.49-0.49,1.28,0,1.77L14.62,12l-7.25,7.25C6.89,19.73,6.89,20.53,7.38,21.01z"/>
        </g>
      </svg>
    );
  }
}

export default Arrow;
