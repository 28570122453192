import {t} from '@lingui/macro';
import {get, map} from 'lodash';

import chartsMap from '../chartsData';
import Service from '../service';

export const ALL_GRAPHS_PANEL_ID = 'all-graphs-panel-unique-id';

export const getAllGraphsPanel = () => {
  return {
    id: ALL_GRAPHS_PANEL_ID,
    name: t`salesDashboard.all`,
    charts: map(chartsMap(), (chart, chartId) => ({id: chartId, name: chart.name, image: chart.image}))
  };
};

export const getUserPanels = async () => {
  const ALL_GRAPHS_PANEL = getAllGraphsPanel();
  const panels = await Service.getPanels();
  panels.push({panelId: ALL_GRAPHS_PANEL.id, panelName: ALL_GRAPHS_PANEL.name});
  return map(panels, entry => {
    return {
      id: entry.panelId,
      name: entry.panelName
    };
  });
};

export const getPanelCharts = async panelId => {
  if (panelId === ALL_GRAPHS_PANEL_ID) {
    return getAllGraphsPanel();
  }

  const payload = await Service.getPanelCharts(panelId);
  const allChartsMap = chartsMap();
  return {
    id: payload.panelId,
    name: payload.panelName,
    charts: map(payload.charts, chartId => {
      const chartData = get(allChartsMap, chartId, {});
      return {
        id: chartId,
        name: chartData.name,
        image: chartData.image
      };
    })
  };
};

export const savePanel = panelData => {
  return Service.savePanel({
    panelId: panelData.id,
    panelName: panelData.name,
    charts: map(panelData.charts, panel => panel.id)
  });
};

export const deletePanel = panelId => {
  return Service.deletePanel(panelId);
};
