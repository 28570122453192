import Service from '../../services/ReleaseNotesServices';
import {SET_PENDING, CLEAR} from './types';

const handleException = exception => {
  if (exception && exception.message === 'cancel') return; // ignore cancel
  console.error('Error saving release note as viewed.', exception);
};

export const dispatchPending = content => dispatch => {
  dispatch({type: SET_PENDING, payload: content});
};

export const dispatchFinished = id => dispatch => {
  Service.saveFinished(id).catch(handleException);
  dispatch({type: CLEAR});
};

export const clear = () => dispatch => {
  dispatch({type: CLEAR});
};
