import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import {withStyles} from '@material-ui/core/styles';
import {setYear, setMonth, fromUnixTime, getUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import {formatDateFns} from '../../../app/dateFns';
import MonthPicker from '../../filters/MonthPicker';
import YearPicker from '../../filters/YearPicker';

const styles = {
  DateFilterSubmenu: {
    '& > div:nth-child(2)': {
      left: '30px !important',
      top: '115px !important',
      paddingLeft: 10,
      paddingRight: 10
    }
  }
};

class PanelDateFilter extends React.Component {
  static defaultProps = {
    disableFilter: false
  };

  constructor(props) {
    super(props);
    const {currentDate} = this.props;
    this.state = {
      currentDate
    };
  }

  state = {
    anchorEl: null
  };

  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
    ReactGA.event({
      category: 'Navigation',
      action: 'NavbarDateFilter opened'
    });
  };

  handleClose = () => {
    const {currentDate} = this.state;
    this.props.handleChange('currentDate')(currentDate);
    this.setState({anchorEl: null});
    ReactGA.event({
      category: 'Navigation',
      action: 'NavbarDateFilter menu closed'
    });
  };

  changeYear = year => {
    let {currentDate} = this.state;
    currentDate = getUnixTime(setYear(fromUnixTime(currentDate), year));
    this.setState({currentDate});
  };

  changeMonth = month => {
    let {currentDate} = this.state;
    // No select, Janeiro é mês 1, mas no javascript é mês 0
    currentDate = getUnixTime(setMonth(fromUnixTime(currentDate), month - 1));
    this.setState({currentDate});
  };

  render() {
    const {anchorEl} = this.state;
    const {currentDate} = this.state;
    const {classes, disableFilter} = this.props;
    return (
      <div>
        <Button disabled={disableFilter} aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" style={{padding: '0px 15px 0px 0px', margin: '0px 0px 0px 10px'}} onClick={this.handleClick}>
          {this.props.children}
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose} className={classes.DateFilterSubmenu}>
          <YearPicker show onChange={this.changeYear} value={formatDateFns(currentDate, 'yyyy')} />
          <MonthPicker show onChange={this.changeMonth} value={formatDateFns(currentDate, 'MM')} />
        </Menu>
      </div>
    );
  }
}

PanelDateFilter.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  applyFilters: PropTypes.func.isRequired,
  currentDate: PropTypes.number.isRequired,
  disableFilter: PropTypes.bool
};

export default withStyles(styles)(PanelDateFilter);
