const styles = theme => ({
  headerButtonWrapper: {
    maxWidth: 213.3,
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(2)
    }
  }
});

export default styles;
