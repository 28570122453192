import {result, isEqual, debounce} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {setNotifications, insertNotification, clearNotifications} from '../actions/NotificationsActions';
import Socket from './socket';

class NotificationSocket extends React.Component {
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.user, this.props.user)) {
      this.connect();
      this.props.clearNotifications();
    }
    if (!isEqual(prevProps.checkStatus, this.props.checkStatus)) {
      this.checkNotificationStatus();
    }
  }

  connect = debounce(() => {
    const {user} = this.props;
    const connected = result(this, 'socket.isConnected', false);

    if (user.logged && !connected) {
      this.socket = new Socket(this.getSocketParams());
      this.socket.listen();
    }
    if (!user.logged) {
      result(this, 'socket.disconnect');
      this.props.clearNotifications();
    }
  }, 500);

  checkNotificationStatus = () => {
    const {type} = this.props.checkStatus;
    this.socket.checkNotificationStatus(type);
  };

  getSocketParams = () => {
    const {setNotifications, insertNotification, clearNotifications} = this.props;
    return {
      onLoadedData: setNotifications,
      onNewNotification: insertNotification,
      onClear: clearNotifications
    };
  };

  render() {
    return null;
  }
}

NotificationSocket.propTypes = {
  user: PropTypes.object,
  setNotifications: PropTypes.func.isRequired,
  insertNotification: PropTypes.func.isRequired,
  clearNotifications: PropTypes.func.isRequired,
  checkStatus: PropTypes.object.isRequired
};

export default connect(state => ({user: {...state.user}, checkStatus: {...state.notification.checkStatus}}), {setNotifications, insertNotification, clearNotifications})(NotificationSocket);
