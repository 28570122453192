import {t} from '@lingui/macro';
import PeopleIcon from '@material-ui/icons/People';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import AbstractFilter from './AbstractFilter';

class RegionalManagersFilter extends React.Component {
  static propTypes = {
    handleChange: PropTypes.func,
    options: PropTypes.array.isRequired,
    selected: PropTypes.array.isRequired,
    visible: PropTypes.bool.isRequired
  };

  render() {
    if (!this.props.visible) return null;

    return <AbstractFilter handleChange={this.props.handleChange} icon={<PeopleIcon />} options={this.props.options} selected={this.props.selected} showSelectAll title={t`common.regionalManagers`} />;
  }
}

const mapStateToProps = state => {
  const {filter} = state;
  return {options: filter.regionais.entries};
};

export default connect(mapStateToProps)(RegionalManagersFilter);
