// TODO: remover este theme antigo do material-ui quando as divergências forem sanadas https://app.clickup.com/t/705rw7
import {createMuiTheme} from '@material-ui/core/styles';

import {colors} from './colors';
import {fonts} from './fonts';

const theme = createMuiTheme({
  colors,
  fonts,
  spacing: 8,
  palette: {
    primary: colors.primary,
    secondary: colors.secondary,
    success: colors.success,
    error: colors.error
  },
  typography: {
    fontFamily: ['Roboto', 'Montserrat'],
    useNextVariants: true,
    button: {
      fontFamily: fonts.fontSecondary,
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '24px',
      textTransform: 'capitalize'
    }
  },
  overrides: {
    MuiTouchRipple: {
      root: {
        display: 'none'
      }
    },
    MuiAppBar: {
      positionStatic: {
        transform: 'none'
      }
    },
    MuiTableCell: {
      root: {
        paddingLeft: 24,
        paddingRight: 24
      },
      head: {
        fontSize: '0.75rem',
        color: 'rgba(0, 0, 0, 0.54)',
        lineHeight: 'unset',
        paddingTop: 0,
        paddingBottom: 0
      }
    },
    MuiTableRow: {
      root: {
        height: 48
      }
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: colors.primary.main
      }
    },
    MuiPickersDay: {
      fontSize: '0.75rem',
      daySelected: {
        backgroundColor: colors.primary.main,
        color: '#fff'
      }
    },
    MuiPickersCalendarHeader: {
      daysHeader: {
        textTransform: 'capitalize'
      },
      switchHeader: {
        textTransform: 'capitalize'
      }
    },
    MuiMenuItem: {
      root: {
        paddingTop: 12,
        paddingBottom: 12
      }
    },
    MuiSkeleton: {
      circle: {
        marginRight: 16
      }
    }
  }
});

export default theme;
