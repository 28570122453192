import PropTypes from 'prop-types';
import React from 'react';

import {PENDING_HOLIDAY_STORES} from '../../../notifications/util/notificationTypes.js';
import {loadUndefinedStores} from './actions/HolidayAlertActions';
import StoresActivityModal from './component/StoresActivityModal';

class HolidayRegisterComponent extends React.Component {
  state = {
    modalData: {},
    loading: true
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    loadUndefinedStores()
      .then(payload => {
        this.setState({modalData: payload, loading: false});
        this.props.checkNotificationStatus(PENDING_HOLIDAY_STORES);
      })
      .catch(() => {
        this.setState({loading: false, status: 'error', message: 'Erro ao carregar as lojas.'});
      });
  };

  render() {
    const {clear} = this.props;
    const {modalData, loading, status, message} = this.state;
    return <StoresActivityModal data={modalData} openModal closeModal={clear} loading={loading} status={status} message={message} />;
  }
}

HolidayRegisterComponent.propTypes = {
  clear: PropTypes.func.isRequired,
  checkNotificationStatus: PropTypes.func.isRequired
};

export default HolidayRegisterComponent;
