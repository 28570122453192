import {sideMenuColor, sideBorderColor} from '../menu/SideMenuStyles';

const styles = theme => ({
  root: {
    maxWidth: 300,
    height: 'calc(100% - 64px)',
    display: 'inline-block',
    float: 'left',
    position: 'relative'
  },
  typography: {
    padding: theme.spacing(2)
  },
  filterTitle: {
    padding: 12,
    margin: 0
  },
  filterPopper: {
    zIndex: 2,
    display: 'block'
  },
  buttonCircle: {
    color: '#646464',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '10px',
    padding: '8px',
    fontWeight: '500',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: sideMenuColor
    },
    '& svg': {
      color: '#AAA',
      fontSize: '20px !important',
      padding: '0 0 5px 0'
    },
    '&[disabled]': {
      border: 'none',
      backgroundColor: 'rgb(230,230,230)'
    },
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  popover: {
    margin: theme.spacing(4)
  },
  dateText: {
    display: 'none'
  },
  chip: {
    marginTop: 0,
    cursor: 'pointer',
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'rgb(200,200,200)'
    },
    '&>span': {
      padding: 0
    }
  },
  chipWeb: {
    '&>span': {
      padding: '0px 15px'
    },
    '& .dateText': {
      display: 'block'
    }
  },
  wrapper: {
    maxWidth: 300,
    display: 'inline-block',
    float: 'left',
    position: 'relative'
  },
  nav: {
    background: '#F1F1F1',
    zIndex: 7,
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 70
  },
  breadCrumbWrapper: {
    float: 'left'
  },
  actionsWrapper: {
    float: 'right',
    height: '100%',
    display: 'flex',
    top: 0,
    '&>div': {
      minWidth: '52px',
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: '1px solid #c7c6c6',
      borderLeftColor: sideBorderColor
    },
    '& button': {
      width: '100%',
      minWidth: 'auto',
      height: '100%',
      margin: 0,
      border: 'none',
      borderRadius: 0
    }
  },
  mobileActionsWrapper: {
    float: 'right',
    height: '100%',
    display: 'flex',
    '&>div': {
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: '1px solid #c7c6c6',
      borderLeftColor: sideBorderColor
    },
    '& button': {
      width: '100%',
      height: '100%',
      margin: 0,
      border: 'none',
      borderRadius: 0
    }
  },
  buttonActive: {
    backgroundColor: '#FDCD32'
  },
  extraActionsSubMenu: {
    '& > div:nth-child(2)': {
      width: '220px'
    }
  },
  subMenu: {
    maxHeight: 'calc(100% - 70px)'
  },
  menuItem: {
    justifyContent: 'space-between'
  },
  menuItemText: {
    minWidth: '150px',
    paddingRight: '16px'
  },
  menuItemIcon: {
    fill: '#646464',
    fontSize: 20
  },
  badge: {
    border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
    marginRight: '6px',
    top: '0px'
  },
  alertBadge: {
    border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
    marginRight: '6px',
    top: '0px',
    backgroundColor: '#D32F2F',
    color: 'white'
  }
});

export default styles;
