import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../component/DatePicker';

const MonthlyDateFilter = props => {
  const {
    dateFilter: {date, format},
    disabled,
    onChange,
    sideFilter
  } = props;

  return <DatePicker monthly variant="button" disabled={disabled} format={format} onChange={onChange('currentDate')} sideFilter={sideFilter} value={date} />;
};

MonthlyDateFilter.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  form: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  sideFilter: PropTypes.bool,
  title: PropTypes.string
};

export default MonthlyDateFilter;
