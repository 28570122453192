import {map} from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';

import {hasPermission} from '../../login/permissions';
import MenuItem from './MenuItem';

class Menu extends Component {
  static propTypes = {
    menuMap: PropTypes.array.isRequired
  };

  renderComponent = item => {
    if (item.permission && !hasPermission(item.permission)) {
      return null;
    }
    return <MenuItem data={item} key={item.key + item.path} />;
  };

  render() {
    return <div>{map(this.props.menuMap, this.renderComponent)}</div>;
  }
}

export default Menu;
