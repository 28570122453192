import {sideBorderColor} from '../menu/SideMenuStyles';

const styles = () => ({
  actionsWrapper: {
    float: 'right',
    height: '100%',
    display: 'flex',
    '&>div': {
      minWidth: '52px',
      height: '100%',
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderLeft: '1px solid #c7c6c6',
      borderLeftColor: sideBorderColor
    },
    '& button': {
      width: '100%',
      minWidth: 'auto',
      height: '100%',
      margin: 0,
      padding: 20,
      border: 'none',
      borderRadius: 0
    }
  },
  breadCrumbWrapper: {
    float: 'left'
  },
  nav: {
    background: '#F1F1F1',
    zIndex: 7,
    position: 'relative',
    display: 'inline-block',
    width: '100%',
    height: 70
  }
});

export default styles;
