import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import List from '@material-ui/core/List';
import {withStyles} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';
import {getUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import styles from '../../styles/FilterStyles';
import ButtonDateFilter from './ButtonDateFilter';
import ComponentDateType from './ComponentDateType';
import {getDateType, WITHOUT_DATE_FILTER} from './dateUtil';
import PresetFilter from './PresetFilter';

class DateFilter extends React.Component {
  updateState = field => newState => {
    if (typeof newState !== 'boolean' && field !== 'hour') {
      newState = getUnixTime(newState);
    }
    this.props.handleChange(field)(newState);
    this.props.handleChange('preset')('custom');
  };

  renderDate = dateType => {
    const {classes, isMobile} = this.props;
    if (dateType.id === WITHOUT_DATE_FILTER) return null;

    if (!dateType.auxDate && !dateType.hour) {
      return <ComponentDateType dateFilter={dateType} updateState={this.updateState} sideFilter />;
    }
    return (
      <Accordion classes={{root: classes.expandedPanel}}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} classes={{root: classes.panelSummary}}>
          <ButtonDateFilter dateFilter={dateType} isMobile={isMobile} />
        </AccordionSummary>
        <AccordionDetails>
          <List className={classnames([classes.panelDateFilter, isMobile ? classes.listViewCentered : ''])}>
            <ComponentDateType dateFilter={dateType} updateState={this.updateState} />
          </List>
        </AccordionDetails>
      </Accordion>
    );
  };

  render() {
    if (!this.props.visible) return null;
    const {dateFilter, enablePresetFilter, filters, handleClose, isMobile, setFilters} = this.props;
    const dateType = getDateType(dateFilter, filters);
    return (
      <React.Fragment>
        <PresetFilter dateFilter={dateFilter} dateType={dateType} handleClose={handleClose} isMobile={isMobile} setFilters={setFilters} visible={enablePresetFilter} />
        {this.renderDate(dateType)}
      </React.Fragment>
    );
  }
}

DateFilter.propTypes = {
  classes: PropTypes.object,
  dateFilter: PropTypes.string.isRequired,
  enablePresetFilter: PropTypes.bool.isRequired,
  filters: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  setFilters: PropTypes.func.isRequired,
  visible: PropTypes.string
};

export default withStyles(styles)(DateFilter);
