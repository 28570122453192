import BaseService from '../../../../service/BaseService';

class GuidedVisitService extends BaseService {
  prefix = 'guidedVisit';

  getPending() {
    return this.get('pending');
  }

  saveFinished(visitId) {
    return this.post(visitId, `${this.prefix}/done`);
  }
}

export default new GuidedVisitService();
