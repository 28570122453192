import IconButton from '@bit/grupo_avenida.components.icon-button';
import Typography from '@bit/grupo_avenida.components.typography';
import {t, Plural} from '@lingui/macro';
import {Trans} from '@lingui/react';
import {withStyles} from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import {map, reverse, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import NotificationCard from './NotificationCard';

const styles = theme => ({
  header: {
    display: 'flex',
    flexDiretion: 'row',
    justifyContent: 'space-between',
    height: 80,
    border: `1px solid ${theme.colors.neutral.level10}`,
    backgroundColor: theme.colors.neutral.level11
  },
  title: {
    padding: '20px 20px 0 20px'
  },
  subTitle: {
    padding: '0 20px 10px 20px'
  },
  closeIcon: {
    padding: '20px'
  },
  card: {
    border: `1px solid ${theme.colors.neutral.level10}`
  }
});

class NotificationDrawer extends React.Component {
  renderNotificationCards = () => {
    const {
      classes,
      notifications: {items}
    } = this.props;
    return map(reverse([...items]), it => {
      return (
        <div key={it.dateTime} className={classes.card}>
          <NotificationCard {...it} />
        </div>
      );
    });
  };

  render() {
    const {classes, notifications, handleClose, enableClose = false} = this.props;
    const amount = size(notifications.items);

    return (
      <div className={classes.root}>
        <div className={classes.header}>
          <div>
            <div className={classes.title}>
              <Typography color="neutral.level2" family="fontPrimary" lineHeight={22} size={14} weight="medium">
                <Trans id="topbar.name.alerts">Alertas</Trans>
              </Typography>
            </div>
            <div className={classes.subTitle}>
              <Typography color="neutral.level3" lineHeight={20} family="fontSecondary">
                <Plural value={amount} one={`# ${t`topbar.alerts.pendingNotification`}`} other={`# ${t`topbar.alerts.pedingNotifications`}`} />
              </Typography>
            </div>
          </div>
          <div className={classes.closeIcon}>
            {enableClose && (
              <IconButton color="secondary" onClick={handleClose} title="Fechar">
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        <div className={classes.body}>{this.renderNotificationCards()}</div>
      </div>
    );
  }
}

NotificationDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
  notifications: PropTypes.object.isRequired,
  handleClose: PropTypes.func,
  enableClose: PropTypes.bool
};

export default withStyles(styles)(NotificationDrawer);
