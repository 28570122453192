import {Trans} from '@lingui/react';
import {Typography} from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import SettingsIcon from '@material-ui/icons/Settings';
import classname from 'classnames';
import {push} from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';

import {isAdmin} from '../../login/permissions';
import MenuButton from './MenuButton';
import Menu from './menuMap';
import styles from './SideMenuStyles.js';

class SideMenu extends Component {
  static propTypes = {
    push: PropTypes.func.isRequired,
    toggleOpen: PropTypes.func.isRequired,
    classes: PropTypes.object.isRequired,
    open: PropTypes.bool.isRequired
  };

  goTo = path => {
    this.props.push(path);
  };

  adminButton() {
    if (!isAdmin()) {
      return null;
    }

    const {classes} = this.props;

    return (
      <React.Fragment>
        <Divider variant="middle" />
        <Typography className={classes.identificationText}>
          <Trans id="sideMenu.administrativeDashboard">Painel Administrativo</Trans>
        </Typography>
        <div className={classes.adminSide}>
          <MenuButton path="/admin" className={classes.adminSideButton}>
            <SettingsIcon />
            <Typography className={classes.customAdminButton}>
              <Trans id="sideMenu.administrativeDashboard">Painel Administrativo</Trans>
            </Typography>
          </MenuButton>
        </div>
      </React.Fragment>
    );
  }

  render() {
    const {classes} = this.props;
    return (
      <SwipeableDrawer open={this.props.open} onClose={this.props.toggleOpen} onOpen={this.props.toggleOpen} classes={{paper: classes.SideBar}}>
        <div className={classname(classes.SideBar, 'sidebar')}>
          <div className={classes.groupItems}>
            <div className={classes.drawerHeader} onClick={this.props.toggleOpen}>
              <div className={classes.groupFooter}>
                <img src={`${process.env.PUBLIC_URL}/imgs/grupo_avenida_logo.svg`} className={classes.companyLogo} alt="Avenida Logo" />
              </div>
              <IconButton onClick={this.props.toggleOpen} className={classes.iconButton}>
                <KeyboardArrowLeftIcon />
              </IconButton>
            </div>
            <Divider variant="middle" />
            <Typography className={classes.identificationText}>Siga</Typography>
            <div className={classes.wrapperSideMenu}>
              <Menu />
              {this.adminButton()}
            </div>
            <div className={classes.sideFooter}>
              <Divider variant="middle" />
              <div className={classes.version}>
                <Trans id="sideMenu.version" /> {process.env.REACT_APP_VERSION}
                <Link to="/release-notes">
                  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M6.99967 0.573395C3.31967 0.573395 0.333008 3.56006 0.333008 7.24006C0.333008 10.9201 3.31967 13.9067 6.99967 13.9067C10.6797 13.9067 13.6663 10.9201 13.6663 7.24006C13.6663 3.56006 10.6797 0.573395 6.99967 0.573395ZM7.66634 10.5734H6.33301V6.57339H7.66634V10.5734ZM7.66634 5.24006H6.33301V3.90673H7.66634V5.24006Z"
                      fill="black"
                    />
                  </svg>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </SwipeableDrawer>
    );
  }
}

export default connect(null, {push})(withStyles(styles)(SideMenu));
