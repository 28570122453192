import * as actions from './PrintActionsType';

const initialState = {
  printContent: null,
  loading: false
};

export default (state = initialState, action ={}) => {
  const {type} = action;
  switch(type) {
    case actions.CLEAR:
      return {...state, ...initialState};
    case actions.PRINT:
      return {...state, printContent: action.payload, loading: false};
    case actions.LOADING:
      return {...state, loading: true};
    case actions.FAILURE:
      return {...state, loading: false};
    default:
      return state;
  }
};