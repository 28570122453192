const styles = () => ({
  extraTopbar: {
    backgroundColor: 'rgb(240,240,240)',
    height: 50,
    borderTop: '1px solid #c7c6c6',
    paddingTop: '1px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dateWrapper: {
    marginLeft: '16px'
  },
  dateContent: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  'date': {
    paddingLeft: '5px',
    fontSize: '12px',
  },
  mobileDate: {
    paddingLeft: '5px'
  },
  icon: {
    '& svg': {
      color: '#646464',
      fontSize: '20px',
      paddingTop: '2px'
    }
  },
  hourIcon: {
    paddingLeft: '12px',
  },
  desktopHourIcon: {
    paddingTop: '2px',
  },
  mobileIcon: {
    '& svg': {
      color: '#646464',
      fontSize: '25px',
      paddingTop: '5px'
    }
  },
  autoUpdate: {
    marginRight: '15px',
    fontSize: '15px',
  },
  mobileAutoUpdate: {
    fontSize: '12px',
  },
  innerDate: {
    padding: '2px 0 2px 0',
    fontSize: '12px'
  },
  hourWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

export default styles;