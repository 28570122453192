import {get} from 'lodash';

import {APPLY_SETTINGS, CLEAR} from './NavBarActionType';

const initialState = {
  filters: [],
  actions: [],
  settings: {},
  dateFilter: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case APPLY_SETTINGS:
      return {
        ...state,
        filters: get(action.payload, 'filters', []),
        actions: get(action.payload, 'actions', []),
        settings: get(action.payload, 'settings', {}),
        dateFilter: get(action.payload, 'dateFilter', {})
      };
    case CLEAR:
      return {...state, ...initialState};
    default:
      return state;
  }
}
