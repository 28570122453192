import {t} from '@lingui/macro';

import {showError} from '../../toast/toastAction';
import * as actions from './PrintActionsType';

export const clean = () => dispatcher => {
  dispatcher({type: actions.CLEAR});
};

export const print = payload => dispatcher => {
  dispatcher({type: actions.PRINT, payload});
};

export const doPrint = service => async dispatcher => {
  try {
    dispatcher({type: actions.LOADING});
    const payload = await service();
    dispatcher({type: actions.PRINT, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.print`)(dispatcher);
    dispatcher({type: actions.FAILURE});
  }
};
