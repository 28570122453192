import HomeIcon from '@material-ui/icons/Home';
import classnames from 'classnames';
import {compact, forEach, find} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Arrow from './Arrow';
import BreadCrumbItem from './BreadCrumbItem';
import {resolveFlagLabel} from './NavBarUtil';

const defaultPath = '/ranking/comparativo';

class AbstractBreadCrumb extends React.Component {
  constructor(props) {
    super(props);
    let {pathname = defaultPath} = props;
    if (pathname === '/') {
      pathname = defaultPath;
    }
    this.state = {
      stack: [...this.buildStack(pathname)]
    };
  }

  static propTypes = {
    siteMap: PropTypes.object.isRequired,
    classes: PropTypes.object,
    push: PropTypes.func.isRequired,
    pathname: PropTypes.string
  };

  buildStack(path) {
    const splitedPath = compact(path.split('/'));
    const splitedPathLength = splitedPath.length;
    let stack = [];
    let lastChild;
    let lastGroup;

    if (!splitedPath) {
      return stack;
    }

    const findDeepestLevel = (item, index) => {
      if (!item.selectable && (item.children || []).length) {
        let nextItem = item.children[0];
        stack[index] = nextItem;
        findDeepestLevel(nextItem, index + 1);
      }

      if (item.selectable && (item.groups || []).length) {
        stack[index] = item.groups[0];
      }
    };
    forEach(splitedPath, (pathItem, index) => {
      const isLastLoop = splitedPathLength === index + 1;
      let parent = index <= 0 ? this.props.siteMap : stack[index - 1];
      if (!parent) {
        return;
      }

      if (lastGroup && lastGroup.drillDown) {
        stack[index] = this.buildDrillDownItem(pathItem, lastGroup);
        return;
      }

      if (parent.addItemToBreadCrumb) {
        stack[index] = {
          label: pathItem
        };
      }

      const childrenProp = !lastChild ? 'children' : 'groups';

      const item = find(parent[childrenProp], item => item.path === pathItem);
      if (!item) {
        return;
      }

      stack[index] = item;

      if (lastChild) {
        lastGroup = item;
      }

      if (item.selectable) {
        lastChild = item;
      }

      if (!isLastLoop) {
        return;
      }

      findDeepestLevel(item, index + 1);
    });

    return stack;
  }

  buildDrillDownItem(path, group) {
    let label = path;
    const {path: groupType} = group;

    if (groupType === 'bandeira') {
      label = resolveFlagLabel(path);
    }

    return {
      label,
      path
    };
  }

  getArrow() {
    const {classes} = this.props;
    return <Arrow className={classnames(classes.arrow, classes.svg)} />;
  }

  renderBase() {
    return this.state.stack.map((baseItem, index) => {
      return (
        <React.Fragment key={`breadCrumb-base-${index}`}>
          {this.getArrow()}
          <BreadCrumbItem item={baseItem} stack={this.state.stack} index={index} siteMap={this.props.siteMap} />
        </React.Fragment>
      );
    });
  }

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <HomeIcon onClick={() => this.props.push('/')} className={classnames(classes.home, classes.svg)} />
        {this.renderBase()}
      </div>
    );
  }
}

export default AbstractBreadCrumb;
