import {addHours, getUnixTime} from 'date-fns';
import {mapKeys} from 'lodash';
import ReactGA from 'react-ga4';

import {getDateFns} from '../../app/dateFns';
import * as loginTypes from '../actions/LoginActionTypes';

const setUserOnce = userName => {
  const currentUserName = localStorage.getItem('name');
  if (!currentUserName || currentUserName !== userName) {
    ReactGA.set({user: userName});
  }
};

const clearLogin = () => {
  localStorage.removeItem('name');
  localStorage.removeItem('auth-token');
};

const initUserState = {
  logged: false,
  logging: false,
  roles: [],
  writableStores: {},
  tokenExpireDate: null,
  updatingToken: false,
  userStore: null
};

const userReducer = function(state = initUserState, action) {
  switch (action.type) {
    case loginTypes.LOGIN_REQUEST:
      return {...state, logging: true};
    case loginTypes.LOGIN_SUCCESS:
      ReactGA.event({
        category: 'Auth',
        action: 'User login',
        label: action.payload.name
      });
      localStorage.setItem('auth-token', action.payload.token);
    /* eslint-disable no-fallthrough */
    case loginTypes.TOKEN_SUCCESS:
      if (action.payload.token) {
        localStorage.setItem('auth-token', action.payload.token);
        localStorage.setItem('name', action.payload.name);
      }
      setUserOnce(action.payload.name);

      return {
        ...state,
        ...action.payload,
        logged: true,
        logging: false,
        updatingToken: false,
        tokenExpireDate: getUnixTime(addHours(getDateFns(), 6)),
        writableStores: mapKeys(action.payload.writableStores, it => it)
      };
    case loginTypes.LOGIN_FAILURE:
      clearLogin();
      return {...state, ...action.payload, logged: false, logging: false};
    case loginTypes.LOGOUT:
      clearLogin();
      return {...state, ...initUserState};
    case loginTypes.TOKEN_FAILURE:
      clearLogin();
      return {...state, logged: false, logging: false};
    case loginTypes.TOKEN_REQUEST:
      return {...state, logging: true};
    case loginTypes.TOKEN_UPDATE:
      return {...state, updatingToken: true};
    default:
      return state;
  }
};

export default userReducer;
