import {AppBar, IconButton} from '@material-ui/core';
import {withStyles} from '@material-ui/core/styles';
import MenuIcon from '@material-ui/icons/Menu';
import PropTypes from 'prop-types';
import React from 'react';

import TopBarGuidedVisit from '../guidedvisit/topbar';
import ExtraTopbar from '../navbar/mobilebar/ExtraTopbar';
import NavBar from '../navbar/NavBar';
import styles from './TopBarStyles.js';

const TopBar = props => {
  const {classes, toggleOpen} = props;

  return (
    <AppBar position="static" className={classes.topbar}>
      <TopBarGuidedVisit />
      <div className={classes.grid} id="topbar">
        <div onClick={toggleOpen} className={classes.group} id="topbar-menu">
          <IconButton color="inherit" aria-label="Menu">
            <MenuIcon />
          </IconButton>
        </div>
        <NavBar variant="topbar" />
      </div>
      <ExtraTopbar />
    </AppBar>
  );
};

TopBar.propTypes = {
  classes: PropTypes.object.isRequired,
  toggleOpen: PropTypes.func.isRequired
};

export default withStyles(styles)(TopBar);
