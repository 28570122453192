import {Trans} from '@lingui/react';
import IconButton from '@material-ui/core/IconButton';
import {withStyles} from '@material-ui/core/styles';
import HomeIcon from '@material-ui/icons/Home';
import classnames from 'classnames';
import {push} from 'connected-react-router';
import React from 'react';
import {connect} from 'react-redux';

import AbstractBreadCrumb from '../../app/navbar/AbstractBreadCrumb';

const baseColor = '#646464';

const breadcrumbStyles = () => ({
  root: {
    padding: 19
  },
  svg: {
    position: 'relative',
    top: '4px'
  },
  arrow: {
    color: '#E2E2E2',
    fill: 'currentColor'
  },
  home: {
    color: baseColor,
    marginRight: 8,
    cursor: 'pointer'
  },
  adminButton: {
    textTransform: 'uppercase',
    background: baseColor,
    border: '1px solid #707070',
    borderRadius: '9px',
    color: '#F6E850',
    padding: '9px 7px',
    display: 'inline-block',
    fontSize: 13,
    fontWeight: 'bold',
    marginLeft: 6,
    '&:hover': {
      backgroundColor: '#949494'
    }
  }
});

class AdminBreadCrumb extends AbstractBreadCrumb {
  goTo = to => () => {
    this.props.push(to);
  };

  renderAdminButton() {
    const {classes} = this.props;

    return (
      <IconButton onClick={() => this.props.push('/admin')} className={classes.adminButton}>
        <Trans id="admin.breadcrumb.home">Painel Administrativo</Trans>
      </IconButton>
    );
  }

  goTo = path => {
    this.props.push(path);
  };

  render() {
    const {classes} = this.props;

    return (
      <div className={classes.root}>
        <HomeIcon onClick={() => this.goTo('/')} className={classnames(classes.home, classes.svg)} />
        {this.getArrow()}
        {/* {this.renderBase()} */}
        {this.renderAdminButton()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pathname: state.router.location.pathname
});

const mapActions = {
  push
};

export default connect(mapStateToProps, mapActions)(withStyles(breadcrumbStyles)(AdminBreadCrumb));
