const styles = theme => ({
  topBar: {
    backgroundColor: '#FFCC32',
    marginBottom: theme.spacing(2.5),
    textAlign: 'center',
    width: '400px'
  },
  centralizedColumn: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(2)
  },
  childrenWrapper: {
    width: '100%'
  },
  line: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    paddingBottom: theme.spacing(1),
    justifyContent: 'space-evenly'
  },
  lineCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 'auto',
    paddingBottom: theme.spacing(1),
    justifyContent: 'center'
  },
  fieldTime: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(1),
    '& input': {
      textAlign: 'center',
      cursor: 'pointer'
    }
  },
  note: {
    width: 280,
    height: '40px',
    border: '1px solid #707070',
    borderRadius: 2,
    padding: theme.spacing(0, 1),
    fontSize: 14,
    textAlign: 'left',
    fontFamily: theme.fonts.fontPrimary
  },
  timePicker: {
    width: 125,
    height: 40,
    fontSize: 14,
    fontWeight: 'bold',
    fontFamily: theme.fonts.fontPrimary,
    alignItems: 'center',
    cursor: 'pointer'
  },
  buttons: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    '& button': {
      padding: 0,
      height: 40,
      margin: theme.spacing(0, 2),
      border: '1px solid #9E9D97',
      borderRadius: 4,
      '& span': {
        width: 135,
        textTransform: 'none'
      }
    }
  },
  actionButton: {
    backgroundColor: '#fff'
  }
});

export default styles;
