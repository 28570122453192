export const FILTER_CLEAR = 'FILTER/CLEAR';
export const FILTER_REMOVE = 'FILTER/REMOVE';
export const RANKING_FILTER_DATE = 'FILTER/DATE';
export const RANKING_FILTER_SET = 'FILTER/SET';
export const RANKING_FILTER_STORES = 'FILTER/STORES';
export const RANKING_FILTER_CLEAR = 'FILTER/CLEAR';
export const RANKING_FILTER_REQUEST = 'FILTER/REQUEST';
export const RANKING_FILTER_SUCCESS = 'FILTER/SUCCESS';
export const RANKING_FILTER_FAILURE = 'FILTER/FAILURE';
export const AUTOUPDATE_FILTER_SET = 'FILTER/AUTOUPDATE';
export const LOAD_REQUEST_FILIAL = 'FILTER/LOAD_REQUEST_FILIAL';
export const LOAD_SUCCESS_FILIAL = 'FILTER/LOAD_SUCCESS_FILIAL';
export const LOAD_FAILURE_FILIAL = 'FILTER/LOAD_FAILURE_FILIAL';
export const LOAD_REQUEST_NEW_FILIAL = 'FILTER/LOAD_REQUEST_NEW_FILIAL';
export const LOAD_SUCCESS_NEW_FILIAL = 'FILTER/LOAD_SUCCESS_NEW_FILIAL';
export const LOAD_FAILURE_NEW_FILIAL = 'FILTER/LOAD_FAILURE_NEW_FILIAL';
export const LOAD_REQUEST_STORE_TYPE = 'FILTER/LOAD_REQUEST_STORE_TYPE';
export const LOAD_SUCCESS_STORE_TYPE = 'FILTER/LOAD_SUCCESS_STORE_TYPE';
export const LOAD_FAILURE_STORE_TYPE = 'FILTER/LOAD_FAILURE_STORE_TYPE';
export const LOAD_REQUEST_REGIONAL = 'FILTER/LOAD_REQUEST_REGIONAL';
export const LOAD_SUCCESS_REGIONAL = 'FILTER/LOAD_SUCCESS_REGIONAL';
export const LOAD_FAILURE_REGIONAL = 'FILTER/LOAD_FAILURE_REGIONAL';
export const LOAD_REQUEST_BANDEIRA = 'FILTER/LOAD_REQUEST_BANDEIRA';
export const LOAD_SUCCESS_BANDEIRA = 'FILTER/LOAD_SUCCESS_BANDEIRA';
export const LOAD_FAILURE_BANDEIRA = 'FILTER/LOAD_FAILURE_BANDEIRA';
export const LOAD_REQUEST_FAMILIES = 'FILTER/LOAD_REQUEST_FAMILIES';
export const LOAD_SUCCESS_FAMILIES = 'FILTER/LOAD_SUCCESS_FAMILIES';
export const LOAD_FAILURE_FAMILIES = 'FILTER/LOAD_FAILURE_FAMILIES';
export const LOAD_REQUEST_DEPARTMENTS = 'FILTER/LOAD_REQUEST_DEPARTMENTS';
export const LOAD_SUCCESS_DEPARTMENTS = 'FILTER/LOAD_SUCCESS_DEPARTMENTS';
export const LOAD_FAILURE_DEPARTMENTS = 'FILTER/LOAD_FAILURE_DEPARTMENTS';
export const RANKING_REALTIME_COMPARATIVE_REQUEST = 'FILTER/RANKING_REALTIME_COMPARATIVE_REQUEST';
export const RANKING_REALTIME_COMPARATIVE_SUCCESS = 'FILTER/RANKING_REALTIME_COMPARATIVE_SUCCESS';
export const RANKING_REALTIME_COMPARATIVE_FAILURE = 'FILTER/RANKING_REALTIME_COMPARATIVE_FAILURE';
export const LOAD_REQUEST_CITIES = 'FILTER/LOAD_REQUEST_CITIES';
export const LOAD_SUCCESS_CITIES = 'FILTER/LOAD_SUCCESS_CITIES';
export const LOAD_FAILURE_CITIES = 'FILTER/LOAD_FAILURE_CITIES';
export const LOAD_REQUEST_STATES = 'FILTER/LOAD_REQUEST_STATES';
export const LOAD_SUCCESS_STATES = 'FILTER/LOAD_SUCCESS_STATES';
export const LOAD_FAILURE_STATES = 'FILTER/LOAD_FAILURE_STATES';
export const FILTER_SHOW_DATE_ONLY = 'FILTER/SHOW_DATE_ONLY';
export const LOAD_REQUEST_DREDATE = 'FILTER/LOAD_REQUEST_DREDATE';
export const LOAD_SUCCESS_DREDATE = 'FILTER/LOAD_SUCCESS_DREDATE';
export const LOAD_FINISH_DREDATE = 'FILTER/LOAD_FINISH_DREDATE';
