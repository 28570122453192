import {configureStore} from '@reduxjs/toolkit';
import {routerMiddleware} from 'connected-react-router';

import history from './history';
import createRootReducer from './reducers';

// cria um redutor com o history
const reducer = createRootReducer(history);
const middleware = getDefaultMiddleware => [...getDefaultMiddleware(), routerMiddleware(history)];

const store = configureStore({
  reducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production'
});

if (module.hot) {
  module.hot.accept('./reducers', () => {
    store.replaceReducer(createRootReducer(history));
  });
}

export default store;
