import {get} from 'lodash';
import GoogleAnalytics from 'react-ga4';

import {trackView} from '../datadog';

const production = process.env.NODE_ENV !== 'production';

const gaOptions = {
  gaOptions: {
    test: production
  }
};

GoogleAnalytics.initialize(process.env.REACT_APP_GA_KEY, gaOptions);

const trackPage = page => {
  GoogleAnalytics.send({hitType: 'pageview', page});
  trackView(page);
};

export default function(history) {
  const getPath = location => get(location, 'pathname', '/');

  trackPage(getPath(history.location));

  history.listen(location => trackPage(getPath(location)));
}
