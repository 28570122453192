import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

import SideMenu from './menu/SideMenu';
import Topbar from './menu/TopBar';
import NavBar from './navbar/NavBar';

const styles = theme => ({
  menu: {
    width: 250
  },
  mainContainer: {
    width: `calc(100% - ${theme.spacing(6)}px)`,
    padding: `${theme.spacing(3)}px`,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(4)}px)`,
      padding: `${theme.spacing(2)}px`
    }
  }
});

class BaseStructure extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    classes: PropTypes.object.isRequired
  };

  state = {
    open: false
  };

  _sendGaEvent() {
    ReactGA.event({
      category: 'Navigation',
      action: 'Side menu ' + (!this.state.open ? 'opened' : 'closed')
    });
  }

  toggleOpen = () => {
    this.setState({open: !this.state.open});
    this._sendGaEvent();
  };

  render() {
    const {classes} = this.props;

    return (
      <div className="mainRoot">
        <Topbar toggleOpen={this.toggleOpen} open={this.state.open} />
        <SideMenu className={classes.menu} toggleOpen={this.toggleOpen} open={this.state.open} />
        <NavBar variant="base" />
        <div className={classes.mainContainer}>{this.props.children}</div>
      </div>
    );
  }
}

export default withStyles(styles)(BaseStructure);
