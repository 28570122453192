import {withStyles} from '@material-ui/core/styles';
import DateRangeIcon from '@material-ui/icons/DateRange';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../../../app/config';
import {formatDateFns} from '../../../app/dateFns';
import {setFilters, removeFilters} from '../../filters/actions/AdminFilterActions';
import styles from './ExtraTopbarStyle';
import PanelDateFilter from './PanelDateFilter';

class ExtraTopbar extends React.Component {
  constructor(props) {
    super(props);
    const {currentDate} = props;
    this.state = {
      currentDate
    };
  }

  handleChange = type => entries => {
    this.setState({[type]: entries}, this.applyFilters);
  };

  applyFilters = filters => {
    if (!filters) {
      const {currentDate} = this.state;
      filters = {currentDate};
    }

    this.props.setFilters({...filters});
  };

  renderDate = () => {
    const {classes, disableFilter, enableDateFilter, isMobile} = this.props;
    const {currentDate} = this.state;
    if (!currentDate || !enableDateFilter) return null;

    if (isMobile) {
      return (
        <PanelDateFilter disableFilter={disableFilter} filters={this.props.dateFilter} handleChange={this.handleChange} applyFilters={this.applyFilters} currentDate={currentDate}>
          <div className={classes.dateWrapper}>
            <div className={classes.dateContent}>
              <div className={classes.mobileIcon}>
                <DateRangeIcon />
              </div>
              <div className={classes.mobileDate}>
                <div className={classes.innerDate}>{`${formatDateFns(currentDate, 'MM/yyyy')}`}</div>
              </div>
            </div>
          </div>
        </PanelDateFilter>
      );
    }
    return (
      <PanelDateFilter disableFilter={disableFilter} filters={this.props.dateFilter} handleChange={this.handleChange} applyFilters={this.applyFilters} currentDate={currentDate}>
        <div className={classes.dateWrapper}>
          <div className={classes.dateContent}>
            <div className={classes.icon}>
              <DateRangeIcon />
            </div>
            <div className={classes.date}>{`${formatDateFns(currentDate, 'MM/yyyy')}`}</div>
          </div>
        </div>
      </PanelDateFilter>
    );
  };
  render() {
    const {classes} = this.props;
    return <div className={classes.extraTopbar}>{this.renderDate()}</div>;
  }
}

const mapStateToProps = state => {
  const {adminFilter, adminNavbar} = state;
  const {currentDate} = adminFilter;
  const {enableDateFilter} = adminNavbar.settings;
  return {
    currentDate,
    dateFilter: {...adminNavbar.dateFilter},
    disableFilter: adminNavbar.filters.disabled,
    isMobile: isMobile(state),
    enableDateFilter
  };
};

const mapDispatchToProps = {
  setFilters,
  removeFilters
};

ExtraTopbar.propTypes = {
  classes: PropTypes.object.isRequired,
  currentDate: PropTypes.number.isRequired,
  dateFilter: PropTypes.object.isRequired,
  disableFilter: PropTypes.bool,
  enableDateFilter: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  removeFilters: PropTypes.func.isRequired,
  setFilters: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExtraTopbar));
