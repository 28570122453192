import {t} from '@lingui/macro';
import Fullscreen from '@material-ui/icons/ZoomOutMap';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Link} from 'react-router-dom';

const FullScreenButton = props => {
  const {className, isMobile, pathGenerator} = props;

  if (isMobile || !pathGenerator) return null;

  const path = useMemo(() => pathGenerator(), [pathGenerator]);

  return (
    <Link className={className} to={path}>
      {t`topbar.fullScreen`} <Fullscreen />{' '}
    </Link>
  );
};

FullScreenButton.propTypes = {
  className: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  pathGenerator: PropTypes.func
};

export default FullScreenButton;
