export const SET_FILTER = 'ADMIN/FILTERS/SET';
export const CLEAR_FILTER = 'ADMIN/FILTERS/CLEAR';
export const LOAD_BANDEIRA_REQUEST = 'ADMIN_FILTER/LOAD_REQUEST_BANDEIRA';
export const LOAD_BANDEIRA_SUCCESS = 'ADMIN_FILTER/LOAD_SUCCESS_BANDEIRA';
export const LOAD_BANDEIRA_FAILURE = 'ADMIN_FILTER/LOAD_FAILURE_BANDEIRA';
export const LOAD_FILIAL_REQUEST = 'ADMIN_FILTER/LOAD_REQUEST_FILIAL';
export const LOAD_FILIAL_SUCCESS = 'ADMIN_FILTER/LOAD_SUCCESS_FILIAL';
export const LOAD_FILIAL_FAILURE = 'ADMIN_FILTER/LOAD_FAILURE_FILIAL';
export const LOAD_REGIONAL_REQUEST = 'ADMIN_FILTER/LOAD_REQUEST_REGIONAL';
export const LOAD_REGIONAL_SUCCESS = 'ADMIN_FILTER/LOAD_SUCCESS_REGIONAL';
export const LOAD_REGIONAL_FAILURE = 'ADMIN_FILTER/LOAD_FAILURE_REGIONAL';
export const LOAD_DREDATE_REQUEST = 'ADMIN_FILTER/LOAD_REQUEST_DREDATE';
export const LOAD_DREDATE_SUCCESS = 'ADMIN_FILTER/LOAD_SUCCESS_DREDATE';
export const LOAD_DREDATE_FAILURE = 'ADMIN_FILTER/LOAD_FAILURE_DREDATE';
