import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import PropTypes from 'prop-types';
import React from 'react';

export default class ChartErrorHandler extends React.Component {
  state = {hasError: false};

  static getDerivedStateFromError() {
    return {hasError: true};
  }

  render() {
    // TODO: rever layout da mensagem de erro ao carregar gráfico
    if (this.state.hasError) {
      return (
        <Typography family="fontSecondary" size={16}>
          <Trans id="salesDashboard.chartLoadingError">Erro ao carregar o gráfico</Trans>
        </Typography>
      );
    }
    return this.props.children;
  }
}

ChartErrorHandler.propTypes = {
  children: PropTypes.object.isRequired
};
