import {t} from '@lingui/macro';
import {push} from 'connected-react-router';
import Cookies from 'js-cookie';
import {get} from 'lodash';

import {trackUser} from '../../app/datadog';
import {loadFilters} from '../../app/filters/FilterActions';
import {FILTER_CLEAR} from '../../app/filters/FilterActionsTypes';
import {dispatchPending as dispatchPendingGuidedVisit, clear as clearPendingGuidedVisit} from '../../app/guidedvisit/loader/action';
import GuidedVisitService from '../../app/guidedvisit/loader/service';
import {clearPreferences, dispatchPreferences} from '../../app/userPreferences/action/UserPreferencesAction';
import UserPreferencesService from '../../app/userPreferences/service/UserPreferencesService';
import {dispatchPending as dispatchPendingReleaseNotes, clear as clearPendingReleaseAlert} from '../../releaseNotes/releaseNotesAlert/action';
import ReleaseNotesAlertService from '../../releaseNotes/services/ReleaseNotesServices';
import {showError} from '../../toast/toastAction';
import {ADMIN_ROLE} from '../permissions';
import LoginService from '../service/LoginService';
import * as loginTypes from './LoginActionTypes';

const loadPreferences = async dispatch => {
  const preferences = await UserPreferencesService.getPreferences().catch(e => console.error(e));
  if (preferences) {
    dispatchPreferences(preferences)(dispatch);
  }
};

const loadGuidedVisit = async dispatch => {
  const pendingGuidedVisit = await GuidedVisitService.getPending().catch(e => console.error(e));
  if (pendingGuidedVisit) {
    dispatchPendingGuidedVisit(pendingGuidedVisit)(dispatch);
  }
};

const loadReleaseNotes = async dispatch => {
  const pendingReleaseNotes = await ReleaseNotesAlertService.getPending().catch(e => console.error(e));
  if (pendingReleaseNotes) {
    dispatchPendingReleaseNotes(pendingReleaseNotes)(dispatch);
  }
};

export const verifyToken = (path, isAdminRequired) => async dispatch => {
  const authStatus = Cookies.get('auth-status');
  if (authStatus === 'logged') {
    dispatch({type: loginTypes.TOKEN_REQUEST});
    try {
      const payload = await LoginService.verifyToken();
      trackUser(payload);
      await Promise.all([loadPreferences(dispatch), loadGuidedVisit(dispatch), loadReleaseNotes(dispatch), loadFilters(dispatch)]);

      dispatch({type: loginTypes.TOKEN_SUCCESS, payload});
      if (isAdminRequired) {
        const userRoles = get(payload, 'roles', []);
        if (!~userRoles.indexOf(ADMIN_ROLE)) {
          path = '/';
        }
      }

      dispatch(push(path ? path : '/'));
      return true;
    } catch (e) {
      dispatch({type: loginTypes.TOKEN_FAILURE});
    }
  }
  return false;
};

export const updateToken = () => async dispatch => {
  dispatch({type: loginTypes.TOKEN_UPDATE});
  try {
    const payload = await LoginService.updateToken();
    dispatch({type: loginTypes.TOKEN_SUCCESS, payload});
  } catch (e) {
    showError(t`login.actions.sessionUpdate.error`)(dispatch);
    dispatch(push('/login'));
    dispatch({type: loginTypes.TOKEN_FAILURE});
  }
};

export const logOutUser = () => async dispatch => {
  trackUser();
  Cookies.remove('auth-status');
  Cookies.remove('auth-status', {domain: '.avenida.com.br'});
  dispatch({type: loginTypes.LOGOUT});
  clearPendingGuidedVisit()(dispatch);
  clearPendingReleaseAlert()(dispatch);
  clearPreferences()(dispatch);
  LoginService.logoutUser().finally(() => dispatch({type: FILTER_CLEAR}));
};
