import {SEVERITY} from './toastAction';
import {CLEAR, SHOW} from './toastActionType';

const initialState = {
  message: '',
  show: false,
  severity: SEVERITY.INFO
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SHOW:
      return {...state, message: action.payload, show: true, severity: action.severity};
    case CLEAR:
      return {...state, ...initialState};
    default:
      return state;
  }
};
