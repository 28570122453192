import {t} from '@lingui/macro';

import {showError} from '../../toast/toastAction';
import * as actions from './ExportActionTypes';

export const clean = () => dispatcher => {
  dispatcher({type: actions.CLEAR});
};

export const setLoading = (value = true) => dispatcher => {
  dispatcher({type: actions.LOADING, payload: value});
};

export const download = payload => dispatcher => {
  dispatcher({type: actions.DOWNLOAD, payload});
};

export const exportExcel = (params = {}) => async dispatcher => {
  try {
    dispatcher({type: actions.LOADING, payload: params.filename});
    let payload;
    if (params && params.service) {
      payload = await params.service();
    } else {
      payload = await params();
    }
    dispatcher({type: actions.DOWNLOAD, payload});
  } catch (e) {
    console.error(e);
    showError(t`common.error.export`)(dispatcher);
    dispatcher({type: actions.FAILURE});
  }
};

export const exportFrontEndExcel = async (action, dispatcher) => {
  setLoading(true)(dispatcher);
  try {
    await action();
  } catch (e) {
    console.error(e);
    showError(t`common.error.export`)(dispatcher);
  } finally {
    setLoading(false)(dispatcher);
  }
};
