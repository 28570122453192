class CacheStorage {
  constructor(key) {
    this.key = key;
    this.data = JSON.parse(localStorage.getItem(key) || '{}');
  }

  update(data) {
    localStorage.setItem(this.key, JSON.stringify(data));

    this.data = {
      ...data
    };
  }
}

export default CacheStorage;
