import {filter} from 'lodash';

import {SET_PENDING, SET_AS_FINISHED, SET_AS_SEE_LATER, CLEAR} from '../action/types';

const initialState = {
  pending: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_PENDING:
      return {...state, pending: action.payload};
    case SET_AS_FINISHED:
    case SET_AS_SEE_LATER:
      return {...state, pending: filter(state.pending, it => it !== action.payload)};
    case CLEAR:
      return {...state, ...initialState};
    default:
      return state;
  }
};
