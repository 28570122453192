import * as actions from './ImportActionTypes';

const initialState = {
  loading: false
};

export default (state = initialState, action = {}) => {
  const {type} = action;
  switch (type) {
    case actions.SUCCESS:
      return {...state, loading: false};
    case actions.LOADING:
      return {...state, loading: true};
    case actions.FAILURE:
      return {...state, loading: false};
    default:
      return state;
  }
};
