export default {
  value: {
    float: 'right',
    width: 'calc(100% - 6px)',
    paddingLeft: 3,
    paddingRight: 3,
    marginTop: 3
  },
  subvalue: {
    float: 'right',
    color: '#AAA',
    width: 'calc(100% - 6px)',
    paddingLeft: 3,
    paddingRight: 3,
    marginTop: 3
  }
};
