import {withStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {getUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {WITHOUT_DATE_FILTER} from '../../../app/filters/component/dateFilter/dateUtil';
import Autoupdate from '../../../component/AutoUpdate';
import {isMobile} from '../../config';
import NavbarContext from '../../context/NavbarContext';
import ButtonDateFilter from '../../filters/component/dateFilter/ButtonDateFilter';
import ComponentDateType from '../../filters/component/dateFilter/ComponentDateType';
import {getDateType} from '../../filters/component/dateFilter/dateUtil';
import {setFilters} from '../../filters/FilterActions';
import FullScreen from '../FullscreenButton';
import styles from './ExtraTopbarStyle';
import PanelDateFilter from './PanelDateFilter';
import PanelDateFilterSkeleton from './PanelDateFilterSkeleton';

class ExtraTopbar extends React.Component {
  static contextType = NavbarContext;

  handleChange = type => entries => {
    const {hour, currentDate, comparativeDate, dreDate, weeklyDate, realtimeCurrentDate, realtimeComparativeDate, customComparativeDate} = this.props.filter;
    const {dateFilter} = this.context.filters;
    this.props.setFilters({
      hour,
      currentDate,
      comparativeDate,
      dreDate,
      weeklyDate,
      realtimeCurrentDate,
      realtimeComparativeDate,
      customComparativeDate,
      dateFilter,
      [type]: entries
    });
  };

  updateState = field => newState => {
    if (typeof newState !== 'boolean' && field !== 'hour') {
      newState = getUnixTime(newState);
    }
    this.props.setFilters({preset: 'custom'});
    this.handleChange(field)(newState);
  };

  renderDate = dateType => {
    const {classes, filter, isMobile} = this.props;
    const {comparativeDateLoading, loading, loadingDREDate, realtimeComparativeDateLoading} = filter;
    const {disabled} = this.context.filters;
    if (dateType.id === WITHOUT_DATE_FILTER) {
      return <div />;
    }
    if (comparativeDateLoading || loading || loadingDREDate || realtimeComparativeDateLoading) {
      return <PanelDateFilterSkeleton dateType={dateType} />;
    }
    if (!dateType.auxDate && !dateType.hour) {
      return <ComponentDateType dateFilter={dateType} updateState={this.updateState} />;
    }
    return (
      <PanelDateFilter classes={classes} disableFilter={disabled} componentDate={<ComponentDateType dateFilter={dateType} updateState={this.updateState} />}>
        <div className={classes.dateContent}>
          <ButtonDateFilter dateFilter={dateType} isMobile={isMobile} disabledTitle />
        </div>
      </PanelDateFilter>
    );
  };

  render() {
    const {
      disableExtraTopbar,
      pathToFullScreen,
      filters: {dateFilter}
    } = this.context;
    const {classes, filter, isMobile} = this.props;
    const dateType = getDateType(dateFilter, filter);
    if (disableExtraTopbar) return <div className={classes.extraTopbar} />;
    return (
      <div className={classes.extraTopbar}>
        {this.renderDate(dateType)}
        <div className={classnames(classes.autoUpdate, isMobile ? classes.mobileAutoUpdate : null)}>
          <FullScreen className={classes.fullScreenButton} isMobile={isMobile} pathGenerator={pathToFullScreen} />
          <Autoupdate enabled={dateType.enableAutoUpdate} isMobile={isMobile} key={dateFilter} disableExtraTopbar={disableExtraTopbar} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    filter: {...state.filter},
    isMobile: isMobile(state)
  };
};

const mapDispatchToProps = {
  setFilters
};

ExtraTopbar.propTypes = {
  classes: PropTypes.object.isRequired,
  filter: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  loading: PropTypes.bool,
  setFilters: PropTypes.func.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ExtraTopbar));
