import {merge} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import Hotkeys from '../../app/hotkeys';
import NavbarContext from './NavbarContext';

class NavbarContextProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      excel: null,
      print: null,
      filters: {
        dateFilter: null,
        disabled: false,
        enableAreas: false,
        enableCities: false,
        enableCategories: false,
        enableComparable: false,
        enableDepartments: false,
        enableEcommerce: false,
        enableFamilies: false,
        enableFlags: false,
        enablePhoneSales: false,
        enablePresetFilter: false,
        enableRegional: false,
        enableStates: false,
        enableStatus: false,
        enableStores: false,
        enableStoreType: false,
        enableShowClosedStores: false
      },
      configure: this.configure,
      disableExtraTopbar: false,
      pathToFullScreen: null
    };
  }

  configure = config => {
    this.setState({...merge(this.state, config)});
  };

  render() {
    return (
      <NavbarContext.Provider value={this.state}>
        <Hotkeys print={this.state.print} export={this.state.excel} />
        {this.props.children}
      </NavbarContext.Provider>
    );
  }
}
NavbarContextProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default NavbarContextProvider;
