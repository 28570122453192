import classname from 'classnames';
import {get} from 'lodash';
import React from 'react';

import float from './float';
import styles from './formatterStyles';
import percentage from './percentage';
import {numberFormat} from './util';

const value = value => {
  return numberFormat(value, {minimumFractionDigits: 0, maximumFractionDigits: 0});
};

const valueFormatter = (multiple, currentValue, column, line) => {
  const valueInt = parseInt(currentValue, 10);

  if (!column.subvalue) {
    return value(currentValue);
  }

  const subvalue = get(line, column.subvalue, 0);
  let className = valueInt >= subvalue ? 'up-cell' : 'down-cell';

  if (valueInt === 0 && subvalue === 0) {
    className = '';
  }

  return (
    <div>
      <span className={classname(className, 'comparative-goal')} style={styles.value}>
        {value(currentValue)}
      </span>
      {!!multiple && <span style={styles.subvalue}>{value(subvalue)}</span>}
    </div>
  );
};

const comparativeGoalValueFormatter = multiple => (currentValue, column, line) => {
  return valueFormatter(multiple, currentValue, column, line);
};

export const nullValue = currentValue => {
  if (currentValue === null) return '-';
  return value(currentValue);
};

export const comparativeGoalMultipleValue = comparativeGoalValueFormatter(true);
export const comparativeGoalValue = comparativeGoalValueFormatter(false);

export const multipleValue = (currentValue, column, line) => {
  if (!column.subvalue) {
    return value(currentValue);
  }

  const subvalue = get(line, column.subvalue, 0);
  return (
    <div>
      <span style={styles.value}>{value(currentValue)}</span>
      <span style={styles.subvalue}>{value(subvalue)}</span>
    </div>
  );
};

export const multipleFloatValue = (currentValue, column, line) => {
  if (!column.subvalue) {
    return float(currentValue);
  }

  const subvalue = get(line, column.subvalue, 0);
  return (
    <div>
      <span style={styles.value}>{float(currentValue)}</span>
      <span style={styles.subvalue}>{float(subvalue)}</span>
    </div>
  );
};

export const valueAndShare = (currentValue, column, line) => {
  if (!column.subvalue) {
    return value(currentValue);
  }

  const subvalue = get(line, column.subvalue, 0);
  return (
    <div>
      <div style={styles.value}>{value(currentValue)}</div>
      <div style={styles.subvalue}>{percentage(subvalue)}</div>
    </div>
  );
};

export default value;
