import {Trans} from '@lingui/react';
import MenuItem from '@material-ui/core/MenuItem';
import PrintIcon from '@material-ui/icons/Print';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import ReactGA from 'react-ga4';

import NavbarContext from '../../context/NavbarContext';

class PrintButton extends Component {
  static contextType = NavbarContext;

  doPrint = () => {
    this.context.print && this.context.print();
    this.props.onClick && this.props.onClick();
    ReactGA.event({
      category: 'Print',
      action: 'Click to print'
    });
  };

  render() {
    const {classes} = this.props;
    return (
      <MenuItem onClick={this.doPrint}>
        <div className={classes.menuItemText}>
          <Trans id="topbar.actions.print">Imprimir</Trans>
        </div>
        <PrintIcon className={classes.menuItemIcon} />
      </MenuItem>
    );
  }
}

PrintButton.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func
};

export default PrintButton;
