import Dialog from '@bit/grupo_avenida.components.dialog';
import Typography from '@bit/grupo_avenida.components.typography';
import {Trans} from '@lingui/react';
import {get, map, size} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {useHistory} from 'react-router-dom';

import {formatDateFns, parseDate} from '../../app/dateFns';
import {dispatchFinished} from './action';
import {TitlesList, TopContainer} from './styles';

const ReleaseNotesAlert = props => {
  const {releaseNotes, dispatchFinished} = props;

  const pendingReleaseNote = get(releaseNotes, 'pending');
  const releaseNoteId = get(pendingReleaseNote, 'id', false);

  const [dialog, setDialog] = React.useState(!!releaseNoteId);
  const history = useHistory();
  const openReleaseNotes = () => {
    history.push('/release-notes');
    handleCloseDialog();
  };
  const handleCloseDialog = () => {
    dispatchFinished(releaseNoteId);
    setDialog(false);
  };
  if (!dialog) return null;

  const renderTitles = () => {
    if (!size(pendingReleaseNote.titles)) return null;
    return (
      <TitlesList>
        {map(pendingReleaseNote.titles, (item, key) => {
          return (
            <li key={key}>
              <Typography color="neutral.level2" family="fontSecondary" size={14} tag="span" weight="medium">
                {item}
              </Typography>
            </li>
          );
        })}
      </TitlesList>
    );
  };

  return (
    <Dialog
      open={dialog}
      onClose={handleCloseDialog}
      primaryButtonAction={openReleaseNotes}
      secondaryButtonAction={handleCloseDialog}
      title={
        <Typography size={18} weight="medium" lineHeight={22}>
          <Trans id="releaseNotes.alertTitle" />
        </Typography>
      }
      primaryButtonText={<Trans id="common.action.view" />}
      secondaryButtonText={<Trans id="common.action.close" />}
    >
      <TopContainer>
        <Typography size={16} weight="medium" lineHeight={20}>
          <Trans id="releaseNotes.versionsHistory" /> {pendingReleaseNote.version}
        </Typography>
        <Typography color="neutral.level2" size={12} family="fontSecondary" lineHeight={20}>
          <Trans id="releaseNotes.publishedDate" /> {formatDateFns(parseDate(pendingReleaseNote.date))}
        </Typography>
      </TopContainer>
      <Typography color="neutral.level2" size={14} family="fontSecondary" lineHeight={20}>
        <Trans id="releaseNotes.alertDescription" />
      </Typography>
      {renderTitles()}
      <Typography color="neutral.level2" size={14} family="fontSecondary" lineHeight={20}>
        <Trans id="releaseNotes.alertDescriptionViewButton" />
      </Typography>
    </Dialog>
  );
};

export default connect(state => ({releaseNotes: state.releaseNotes}), {dispatchFinished})(ReleaseNotesAlert);

ReleaseNotesAlert.propTypes = {
  releaseNotes: PropTypes.object,
  dispatchFinished: PropTypes.func.isRequired
};
