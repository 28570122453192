import {withStyles} from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {isMobile} from '../../app/config';
import NavbarContext from '../../app/context/NavbarContext';
import LanguageSelector from '../../app/navbar/LanguageSelector';
import UserMenu from '../../app/navbar/UserMenu';
import {logOutUser} from '../../login/actions/LoginActions';
import adminSiteMap from '../siteMap.js';
import AdminBreadcrumb from './AdminBreadCrumb';
import styles from './NavBarStyles';

class NavBar extends React.Component {
  static contextType = NavbarContext;

  static propTypes = {
    admin: PropTypes.bool,
    classes: PropTypes.object,
    isMobile: PropTypes.bool,
    logOutUser: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
    variant: PropTypes.string
  };

  logout = () => {
    this.props.logOutUser();
  };

  renderAdmin = () => {
    const {isMobile} = this.props;
    if (!isMobile) {
      return null;
    }

    return <AdminBreadcrumb siteMap={adminSiteMap} />;
  };

  renderAdminTopbar = () => {
    const {classes, isMobile, user} = this.props;

    if (isMobile === true) {
      return <div className={classes.nav}></div>;
    }

    return (
      <div className={classes.nav}>
        <div className={classes.breadCrumbWrapper}>
          <AdminBreadcrumb siteMap={adminSiteMap} />
        </div>
        <div className={classes.actionsWrapper}>
          <LanguageSelector />
          <UserMenu isMobile={isMobile} username={user.name || localStorage.getItem('name') || 'Usuário Avenida'} logout={this.logout} />
        </div>
      </div>
    );
  };

  render() {
    const {variant = 'topbar'} = this.props;

    if (variant === 'topbar') {
      return this.renderAdminTopbar();
    }
    return this.renderAdmin();
  }
}

const mapStateToProps = state => {
  return {
    user: state.user,
    isMobile: isMobile(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: user => dispatch(logOutUser(user))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NavBar));
