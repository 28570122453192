import {ListItem} from '@material-ui/core';
import styled from 'styled-components';

export const SubMenuLink = styled.div`
  align-items: center;
  background: #fff4d9;
  cursor: pointer;
  display: flex;
  font-family: Roboto, 'sans-serif';
  font-weight: ${props => (props.isSelected ? 500 : 400)};
  height: 36px;
  width: 100%;
  :hover {
    background: #f7edd2;
  }
`;

export const SubMenuText = styled.p`
  margin-left: 64px;
`;

export const CustomCategory = styled(ListItem)`
  background: ${({open, theme: {colors}}) => (open ? colors.primary.dark : colors.primary.main)};
`;

export const CustomSelectedSubMenu = styled(ListItem)`
  span {
    font-weight: ${props => (props.open ? 500 : 400)};
  }
  background: ${props => props.open && '#F7DD8D'};
`;
