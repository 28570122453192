import {isBefore, fromUnixTime} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {Route, Redirect} from 'react-router';

import {updateToken} from '../login/actions/LoginActions';
import {hasPermission} from '../login/permissions';
import BaseComponents from './BaseComponents';
import BaseStructure from './BaseStructure';
import {getDateFns} from './dateFns';

class ProtectedRoute extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    hideNavigation: PropTypes.bool,
    render: PropTypes.node,
    permission: PropTypes.array,
    updateToken: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  render() {
    const {user, component: Component, render, children, permission, hideNavigation, ...props} = this.props;
    if (user.tokenExpireDate && !user.updatingToken && isBefore(fromUnixTime(user.tokenExpireDate), getDateFns())) {
      this.props.updateToken();
    }

    if (!user.logged) {
      return <Redirect to={{pathname: '/login', state: {from: props.location}}} />;
    } else if (permission && !hasPermission(permission, false)) {
      return <Redirect to={{pathname: '/', state: {from: props.location}}} />;
    }

    if (children) {
      return <Route {...props}>{children}</Route>;
    } else if (render) {
      return <Route {...props} render={render} />;
    }

    const Base = hideNavigation ? React.Fragment : BaseStructure;

    return (
      <Route
        {...props}
        render={p => (
          <Base>
            <Component {...p} />
            <BaseComponents />
          </Base>
        )}
      />
    );
  }
}

const mapStateToProps = state => ({
  user: {...state.user}
});

export default connect(mapStateToProps, {updateToken})(ProtectedRoute);
