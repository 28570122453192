import {t} from '@lingui/macro';
import PropTypes from 'prop-types';
import React from 'react';

import DateComponent from './components/DateComponent';

const BSCFilterByPeriod = props => {
  const {
    dateFilter: {date, format, auxDate},
    onChange
  } = props;
  return (
    <React.Fragment>
      <DateComponent monthly format={format} label={t`topbar.dateFilter.initialDate`} value={date} maxDate={auxDate} onChange={onChange('bscDateBegin')} />
      <DateComponent monthly format={format} label={t`topbar.dateFilter.finalDate`} value={auxDate} minDate={date} onChange={onChange('bscDateEnd')} />
    </React.Fragment>
  );
};

BSCFilterByPeriod.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
};

export default BSCFilterByPeriod;
