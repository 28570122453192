import Day from '@material-ui/pickers/views/Calendar/Day';
import {getUnixTime, isAfter, isSameDay, subDays} from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import DatePicker from '../../../../../component/DatePicker';
import {formatDateFns, getDateFns} from '../../../../dateFns';
import ComparativeDate from './ComparativeDate';
import DateComponent from './components/DateComponent';

const CurrentDate = props => {
  const {
    dateFilter: {auxDate, customComparativeDate, date},
    onChange,
    sideFilter
  } = props;
  const maxDate = subDays(getDateFns(), 1);

  const renderDay = (dateValue, selectedDate, currentMonth) => {
    const disabled = isAfter(dateValue, maxDate);
    return (
      <Day selected={isSameDay(dateValue, selectedDate)} disabled={disabled} hidden={!currentMonth}>
        {formatDateFns(dateValue, 'd')}
      </Day>
    );
  };

  if (!auxDate) {
    return <DatePicker variant="button" disableFuture maxDate={getUnixTime(maxDate)} onChange={onChange('currentDate')} renderDay={renderDay} sideFilter={sideFilter} value={date} />;
  }
  return (
    <React.Fragment>
      <DateComponent disableFuture maxDate={getUnixTime(maxDate)} onChange={onChange('currentDate')} renderDay={renderDay} value={date} />
      <ComparativeDate customComparativeDate={customComparativeDate} onChange={onChange('comparativeDate')} onChangeCustomComparativeDate={onChange('customComparativeDate')} value={auxDate} />
    </React.Fragment>
  );
};

CurrentDate.propTypes = {
  dateFilter: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  maxDate: PropTypes.object,
  maxDateFromProps: PropTypes.number,
  minDate: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  sideFilter: PropTypes.bool,
  subCurrentDate: PropTypes.any,
  value: PropTypes.number
};

export default CurrentDate;
