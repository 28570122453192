import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';

import {clear} from './toastAction';

class Toast extends React.Component {
  handleClose = () => {
    if (this.props.show) {
      this.props.clear();
    }
  };

  render() {
    if (!this.props.show) return null;
    return (
      <Snackbar open={this.props.show} onClose={this.handleClose} autoHideDuration={6000} TransitionComponent={props => <Slide direction="up" {...props} />}>
        <Alert elevation={6} variant="filled" onClose={this.handleClose} severity={this.props.severity}>
          {this.props.message}
        </Alert>
      </Snackbar>
    );
  }
}

Toast.propTypes = {
  clear: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  message: PropTypes.string.isRequired,
  severity: PropTypes.string.isRequired
};

export default connect(state => ({...state.toast}), {clear})(Toast);
