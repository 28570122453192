const urlSettings = {
  url: 'operacional/compras-dia-a-dia',
  routes: {
    shares: 'shares',
    stockPieces: 'estoque-em-pecas',
    stockValues: 'estoque-em-valor',
    stockByStores: 'estoque-por-filial-valor',
    stockPiecesByStores: 'estoque-por-filial-pecas'
  }
};

export default urlSettings;
