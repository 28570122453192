import {sideMenuColor} from '../../menu/SideMenuStyles';

const dayProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 35,
  height: 35,
  padding: 0,
  marginLeft: 2.5,
  marginRight: 2.5
};

export default theme => ({
  root: {
    maxWidth: 300,
    height: 'calc(100% - 64px)',
    display: 'inline-block',
    float: 'left',
    position: 'relative'
  },
  badge: {
    border: `2px solid ${theme.palette.type === 'light' ? theme.palette.grey[200] : theme.palette.grey[900]}`,
    marginRight: '6px',
    top: '0px'
  },
  buttonCircle: {
    color: '#646464',
    cursor: 'pointer',
    fontFamily: 'Roboto',
    fontSize: '10px',
    fontWeight: '500',
    padding: '8px',
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: sideMenuColor
    },
    '& svg': {
      color: '#AAA',
      fontSize: '20px !important',
      padding: '0 0 5px 0'
    },
    '&[disabled]': {
      border: 'none',
      backgroundColor: 'rgb(230,230,230)'
    },
    '& span': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    }
  },
  drawerPaper: {
    backgroundColor: '#FFCC32',
    height: '100%',
    width: '480px'
  },
  drawerPaperMobile: {
    backgroundColor: '#FFCC32',
    height: '100%',
    width: '100%'
  },
  checkboxes: {
    padding: 0,
    height: 32,
    width: 32
  },
  checkbox: {
    padding: theme.spacing(0, 0, 0, 1),
    borderBottom: '1px solid #DADADA'
  },
  checkboxesList: {
    padding: 0
  },
  checkboxAll: {
    borderBottom: '1px solid #DADADA',
    padding: theme.spacing(1, 0)
  },
  panelSummary: {
    '& > div:first-child': {
      margin: '20px 0px',
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        height: 25,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }
    }
  },
  expandedPanel: {
    borderRadius: 4,
    marginBottom: theme.spacing(2),
    '&::before': {
      backgroundColor: 'transparent'
    }
  },
  cardPanel: {
    margin: '0 0 20px 0',
    overflow: 'visible'
  },
  expanded: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    padding: 0,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxHeight: 300,
    borderTop: '1px solid #DADADA',
    '&::-webkit-scrollbar': {
      width: 10,
      height: 15
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0,0,0,0.2)',
      borderRadius: 10
    }
  },
  expandedFullHeight: {
    [theme.breakpoints.up('sm')]: {
      height: '100%',
      maxHeight: 'none'
    },
    [theme.breakpoints.down('sm')]: {
      maxHeight: 300
    }
  },
  listView: {
    width: '100%',
    cursor: 'pointer'
  },
  listViewCentered: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  date: {
    fontSize: 12,
    marginLeft: 10
  },
  filterContent: {
    color: '#333',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px 24px !important'
  },
  topButton: {
    marginBottom: 20
  },
  titles: {
    margin: 0
  },
  //ButtonDateFilter

  icon: {
    marginRight: theme.spacing(2),
    color: theme.colors.neutral.level7
  },
  abstractFilterIcon: {
    marginRight: '10px',
    '& svg': {
      '& path': {
        fill: '#AAA'
      }
    }
  },
  title: {
    marginRight: '10px'
  },
  button: {
    color: theme.colors.neutral.level2,
    fontFamily: theme.fonts.fontSecondary,
    display: 'flex',
    alignItems: 'center',
    marginRight: '10px'
  },
  mobileButton: {
    height: '100%',
    color: theme.colors.neutral.level2,
    fontFamily: theme.fonts.fontSecondary,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: '10px'
  },
  dates: {
    display: 'flex',
    alignItems: 'center'
  },
  mobileDates: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    lineHeight: '120%'
  },
  hourIcon: {
    margin: '0px 8px 0px 16px',
    color: theme.colors.neutral.level7,
    '& path': {
      fontSize: '25px',
      paddingTop: '5px'
    }
  },

  mobileHour: {
    marginLeft: '10px',
    display: 'flex'
  },
  iconCloseFilter: {
    width: 24,
    height: 24,
    float: 'right',
    cursor: 'pointer',
    color: '#000'
  },
  panelDateFilter: {
    alignItems: 'baseline',
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    '& p': {
      cursor: 'default',
      textAlign: 'center'
    },
    '& input': {
      textAlign: 'center',
      cursor: 'pointer'
    }
  },
  mobilePanelDateFilter: {
    display: 'flex',
    alignItems: 'center'
  },
  panelContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  defaultMargin: {
    margin: '10px 0px',
    width: '100%'
  },
  presetContent: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '20px',
    '& > button:nth-child(1)': {
      flex: 1,
      marginRight: '10px'
    },
    '& > button:nth-child(2)': {
      flex: 1
    },
    '& > button:nth-child(3)': {
      flex: 1,
      marginLeft: '10px'
    }
  },

  filterButton: {
    margin: 10,
    width: 'auto'
  },
  styleButton: {
    background: '#d5d5d5',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    borderBottom: '1px solid gray',
    '&:hover': {
      background: '#eeeeee',
      borderBottom: '1px solid #FFCC32'
    }
  },
  checkboxComparativeDate: {
    margin: 0,
    '& span': {
      fontSize: '12px'
    }
  },
  hourFieldSelect: {
    height: '34px',
    textAlign: 'center',
    width: '80px'
  },
  titleWrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: '8px',
    color: '#000000'
  },
  fixedWrapperTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#FFCC32',
    boxShadow: theme.shadows[5],
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& div': {
      display: 'flex'
    }
  },
  fixedWrapperBottom: {
    backgroundColor: '#FFCC32',
    boxShadow: theme.shadows[5],
    bottom: 0,
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    position: 'sticky',
    zIndex: 1
  },

  filterWrapper: {
    paddingRight: theme.spacing(4),
    paddingLeft: theme.spacing(4),
    '& > button:nth-child(1)': {
      flex: 1,
      marginRight: '10px'
    },
    '& > button:nth-child(2)': {
      flex: 1
    }
  },
  filterCenterWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingTop: '20px',
    overflow: 'overlay',
    height: '100%'
  },

  primaryButton: {
    backgroundColor: '#2E0D1E',
    borderRadius: '5px',
    color: '#FFF',
    fontFamily: 'Roboto',
    fontSize: '16px',
    height: '48px',
    width: '195px',
    '&:hover': {
      backgroundColor: '#2E0D1E',
      color: '#FFCC32'
    }
  },
  mobilePrimaryButton: {
    fontSize: '14px',
    width: '45%'
  },
  secondaryButton: {
    backgroundColor: '#FFCC32',
    border: '1px solid #2E0D1E',
    borderRadius: '5px',
    boxShadow: 'none',
    color: '#2E0D1E',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    height: '40px',
    lineHeight: 'inherit',
    padding: '0 10px',
    '&:hover': {
      backgroundColor: '#2E0D1E',
      color: '#FFCC32'
    }
  },
  mobileSecondaryButton: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: 'normal'
  },
  disabledButton: {
    backgroundColor: '#DEB32D !important',
    borderRadius: '5px',
    boxShadow: 'none  !important',
    color: '#AB8A24 !important',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 500,
    height: '40px',
    lineHeight: 'inherit',
    padding: '0 10px'
  },
  mobileDisabledButton: {
    fontSize: '10px',
    fontWeight: 400,
    lineHeight: 'normal'
  },

  //weeklyFilter

  dayIconButton: {
    ...dayProperties,
    fontSize: '0.75rem',
    color: 'black'
  },
  emptyDay: {
    height: '35px',
    margin: '0px 2.5px',
    width: '35px'
  },
  selectedDay: {
    ...dayProperties,
    fontSize: '0.75rem',
    fontWeight: 'bold',
    color: 'black'
  },
  disabledDay: {
    ...dayProperties,
    fontSize: '0.75rem',
    color: 'rgba(0, 0, 0, 0.38)'
  },
  highlight: {
    background: theme.palette.primary.main,
    color: theme.palette.common.white
  },
  firstHighlight: {
    extend: 'highlight',
    borderTopLeftRadius: '50%',
    borderBottomLeftRadius: '50%'
  },
  endHighlight: {
    extend: 'highlight',
    borderTopRightRadius: '50%',
    borderBottomRightRadius: '50%'
  },
  filtersIcon: {
    marginRight: theme.spacing(4)
  }
});
