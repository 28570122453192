const productivityUrlSettings = {
  url: 'operacional/produtividade',
  routes: {
    ranking: 'ranking',
    employees: 'funcionarios',
    lotacionograma: 'lotacionograma'
  }
};

export default productivityUrlSettings;
