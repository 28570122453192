import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import ReactGA from 'react-ga4';

class PanelDateFilter extends React.Component {
  state = {
    anchorEl: null
  };
  handleClick = event => {
    this.setState({anchorEl: event.currentTarget});
    ReactGA.event({
      category: 'Navigation',
      action: 'NavbarDateFilter opened'
    });
  };
  handleClose = () => {
    this.setState({anchorEl: null});
    ReactGA.event({
      category: 'Navigation',
      action: 'NavbarDateFilter menu closed'
    });
  };

  render() {
    const {anchorEl} = this.state;
    const {classes, componentDate, disableFilter} = this.props;
    return (
      <div className={classes.dateButton}>
        <Button disabled={disableFilter} aria-owns={anchorEl ? 'simple-menu' : null} aria-haspopup="true" onClick={this.handleClick}>
          {this.props.children}
        </Button>
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleClose} className={classes.panelDateFilter}>
          {componentDate}
        </Menu>
      </div>
    );
  }
}

PanelDateFilter.propTypes = {
  children: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  componentDate: PropTypes.object,
  disableFilter: PropTypes.bool
};

export default PanelDateFilter;
