import withStyles from '@material-ui/core/styles/withStyles';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

const animationcss = {
  backgroundImage: 'linear-gradient(90deg, #D7D7D7 0px, #EAEAEA 80px, #D7D7D7 180px)',
  backgroundSize: '600px',
  animationName: 'wave',
  animationDuration: '2.5s',
  animationTimingFunction: 'ease-in-out',
  animationIterationCount: 'infinite'
};

const styles = theme => ({
  skeletonWrap: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 20,
    [theme.breakpoints.up('md')]: {
      paddingLeft: 15
    }
  },
  icon: {
    ...animationcss,
    marginRight: 10,
    [theme.breakpoints.up('md')]: {
      marginLeft: 5
    }
  },
  datepickerLeft: {
    ...animationcss,
    animationDelay: '0.5s',
    marginRight: 10,
    width: 65,
    [theme.breakpoints.up('md')]: {
      width: 155
    }
  },
  datepickerRight: {
    ...animationcss,
    animationDelay: '0.5s',
    marginRight: 10,
    width: 35,
    [theme.breakpoints.up('md')]: {
      width: 45
    }
  },
  '@keyframes wave': {
    '0%': {
      backgroundPosition: '50%'
    },
    '100%': {
      backgroundPosition: '-50%'
    }
  }
});

const PanelDateFilterSkeleton = props => {
  return (
    <div className={props.classes.skeletonWrap}>
      <Skeleton variant="circle" animation="wave" width={24} height={24} className={props.classes.icon} />
      <Skeleton animation="wave" height={24} className={props.classes.datepickerLeft} />
      {props.dateType.hour && (
        <React.Fragment>
          <Skeleton variant="circle" animation="wave" width={24} height={24} className={props.classes.icon} />
          <Skeleton animation="wave" height={24} className={props.classes.datepickerRight} />
        </React.Fragment>
      )}
    </div>
  );
};

PanelDateFilterSkeleton.propTypes = {
  classes: PropTypes.object,
  dateType: PropTypes.object
};

export default withStyles(styles)(PanelDateFilterSkeleton);
