import {t} from '@lingui/macro';
import {endOfMonth, fromUnixTime, getUnixTime, isSameDay, subDays, subMonths} from 'date-fns';

import {showError} from '../../toast/toastAction';
import {formatRequestDate, getDateFns, parseDate} from '../dateFns';
import store from '../stores';
import * as actions from './FilterActionsTypes';
import service from './FilterService';

const setFilter = filter => dispatcher => dispatcher(filter);

const newDateFns = getDateFns();

const updateComparativeDate = (newFilters, filters = store.getState().filter) => dispatcher => {
  const {customComparativeDate, currentDate, realtimeCurrentDate} = newFilters;
  if (!currentDate && !realtimeCurrentDate) {
    return;
  }
  const {currentDate: oldCurrentDate, realtimeCurrentDate: oldReatimeCurrentDate} = filters;

  // User desabilitou o "custom comparative", atualiza as datas comparativas
  if (newFilters.customComparativeDate === false && filters.customComparativeDate === true) {
    loadComparativeDate(currentDate)(dispatcher);
    loadRealtimeComparativeDate(realtimeCurrentDate)(dispatcher);
    return;
  }

  if (currentDate && newFilters.comparativeDate !== filters.comparativeDate && !customComparativeDate) {
    loadComparativeDate(currentDate)(dispatcher);
    return;
  }

  if (realtimeCurrentDate && newFilters.realtimeComparativeDate !== filters.realtimeComparativeDate && !customComparativeDate) {
    loadRealtimeComparativeDate(realtimeCurrentDate)(dispatcher);
    return;
  }

  // Se a data comparativa não tiver sido customizada pelo usuário, atualiza ela
  if (customComparativeDate === false) {
    if (currentDate && (oldCurrentDate === null || !oldCurrentDate || !isSameDay(fromUnixTime(currentDate), fromUnixTime(oldCurrentDate)))) {
      loadComparativeDate(currentDate)(dispatcher);
    } else if (realtimeCurrentDate && (oldReatimeCurrentDate === null || !oldReatimeCurrentDate || !isSameDay(fromUnixTime(realtimeCurrentDate), fromUnixTime(oldReatimeCurrentDate)))) {
      loadRealtimeComparativeDate(realtimeCurrentDate)(dispatcher);
    }
  }
};

export const loadFilters = async dispatcher => {
  const stores = loadFiliais()(dispatcher);
  const newStores = loadNewFiliais()(dispatcher);
  const states = loadStates(dispatcher);
  const storeType = loadStoreType()(dispatcher);
  const regional = loadRegionais()(dispatcher);
  const flags = loadBandeiras()(dispatcher);
  const families = loadFamilies()(dispatcher);
  const departamentos = loadDepartments()(dispatcher);
  const cities = loadCities()(dispatcher);
  const currentFilters = store.getState().filter;
  const comparativeDate = loadComparativeDate(currentFilters.currentDate)(dispatcher);
  const realtimeComparativeDate = loadRealtimeComparativeDate(currentFilters.realtimeCurrentDate)(dispatcher);
  const dreDate = loadDREDate()(dispatcher);
  await Promise.all([stores, states, newStores, storeType, regional, flags, families, departamentos, cities, comparativeDate, realtimeComparativeDate, dreDate]);
};

export const setAutoupdate = activated => dispatcher => {
  setFilter({type: actions.AUTOUPDATE_FILTER_SET, payload: activated})(dispatcher);
};

export const setFilters = filters => async dispatcher => {
  updateComparativeDate(filters)(dispatcher);
  setFilter({type: actions.RANKING_FILTER_SET, payload: filters})(dispatcher);
};

export const setStores = stores => dispatcher => {
  setFilter({type: actions.RANKING_FILTER_STORES, payload: stores})(dispatcher);
};

export const clearFilter = () => dispatcher => dispatcher({type: actions.RANKING_FILTER_CLEAR});
export const removeFilters = () => dispatcher => dispatcher({type: actions.FILTER_REMOVE});

const loadComparativeDateAction = (currentDate, request, success, failure) => async dispatcher => {
  dispatcher({type: request});
  try {
    const response = await service.getComparativeDate(formatRequestDate(currentDate));
    const payload = getUnixTime(parseDate(response));
    dispatcher({type: success, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error('Unable to load comparative date', e);
      dispatcher({
        type: success,
        payload: getUnixTime(subDays(newDateFns, 364))
      });
      dispatcher({type: failure});
    }
  }
};

export const loadComparativeDate = currentDate => async dispatcher => {
  loadComparativeDateAction(currentDate, actions.RANKING_FILTER_REQUEST, actions.RANKING_FILTER_SUCCESS, actions.RANKING_FILTER_FAILURE)(dispatcher);
};

export const loadRealtimeComparativeDate = currentDate => async dispatcher => {
  loadComparativeDateAction(currentDate, actions.RANKING_REALTIME_COMPARATIVE_REQUEST, actions.RANKING_REALTIME_COMPARATIVE_SUCCESS, actions.RANKING_REALTIME_COMPARATIVE_FAILURE)(dispatcher);
};

export const loadDREDate = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_DREDATE});
  try {
    const response = await service.getDREDate();
    let payload = getUnixTime(getDateFns());
    if (response) {
      payload = getUnixTime(parseDate(response));
    }
    dispatcher({type: actions.LOAD_SUCCESS_DREDATE, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error('Unable to load dreDate', e);
      showError(t`common.error.filter.DREDate`)(dispatcher);
      dispatcher({
        type: actions.LOAD_FINISH_DREDATE,
        payload: getUnixTime(endOfMonth(subMonths(newDateFns, 1)))
      });
    }
  }
};

export const loadFiliais = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_FILIAL});
  try {
    const payload = await service.loadStores();
    dispatcher({type: actions.LOAD_SUCCESS_FILIAL, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.stores`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_FILIAL});
    }
  }
};

export const loadNewFiliais = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_NEW_FILIAL});
  try {
    const payload = await service.loadNewStores();
    dispatcher({type: actions.LOAD_SUCCESS_NEW_FILIAL, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.stores`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_NEW_FILIAL});
    }
  }
};

export const loadStates = async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_STATES});
  try {
    const payload = await service.loadStates();
    dispatcher({type: actions.LOAD_SUCCESS_STATES, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.states`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_STATES});
    }
  }
};

export const loadStoreType = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_STORE_TYPE});
  try {
    const payload = await service.loadStoreType;
    dispatcher({type: actions.LOAD_SUCCESS_STORE_TYPE, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.storeTypes`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_STORE_TYPE});
    }
  }
};

export const loadRegionais = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_REGIONAL});
  try {
    const payload = await service.loadRegionais();
    dispatcher({type: actions.LOAD_SUCCESS_REGIONAL, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.regionalManagers`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_REGIONAL});
    }
  }
};

export const loadBandeiras = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_BANDEIRA});
  try {
    const payload = await service.loadFlags();
    dispatcher({type: actions.LOAD_SUCCESS_BANDEIRA, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.flags`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_BANDEIRA});
    }
  }
};

export const loadFamilies = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_FAMILIES});
  try {
    const payload = await service.loadFamilies();
    dispatcher({type: actions.LOAD_SUCCESS_FAMILIES, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.families`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_FAMILIES});
    }
  }
};

export const loadDepartments = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_DEPARTMENTS});
  try {
    const payload = await service.loadDepartments();
    dispatcher({type: actions.LOAD_SUCCESS_DEPARTMENTS, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.departments`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_DEPARTMENTS});
    }
  }
};

export const loadCities = () => async dispatcher => {
  dispatcher({type: actions.LOAD_REQUEST_CITIES});
  try {
    const payload = await service.loadCities();
    dispatcher({type: actions.LOAD_SUCCESS_CITIES, payload});
  } catch (e) {
    if (e.message !== 'cancel') {
      console.error(e);
      showError(t`common.error.filter.cities`)(dispatcher);
      dispatcher({type: actions.LOAD_FAILURE_CITIES});
    }
  }
};

export const filterShowDateOnly = payload => ({
  type: actions.FILTER_SHOW_DATE_ONLY,
  payload
});
